import { Alert, AutoComplete, Button, Form, Input, Select } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { ArrowLeft } from 'react-feather';
import { useDebounce } from 'use-debounce/lib';
import useOrder from '../hooks/useOrder';

interface Props {
  handleIsCovered: (value: boolean) => void;
  isCovered: boolean;
  isContent?: boolean;
  onClickBtnContent?: () => void;
}

const ContentCheckDeliveryCoverage: React.FC<Props> = (props) => {
  const {
    checkDeliveryCoverage,
    setDeliveryAddress,
    setDeliveryPostcode,
    setUnavailableSchedule,
    currentPostcodes,
    deliveryPostcode,
    scheduleDeliveries,
    deliveryAddress,
    unavailableSchedule,
  } = useOrder();
  const [isPostcodeFilled, setIsPostcodeFilled] = useState<boolean>(false);

  const [searchValue] = useDebounce(deliveryPostcode, 500);

  const handleChangePostcode = (e: string) => {
    let unavailableSchedule = [];
    for (const iterator of scheduleDeliveries) {
      const found = iterator.deliveryCoverage?.postalCode[e];
      if (!found) {
        unavailableSchedule.push(
          moment(iterator.scheduleAt).format('MMM DD YYYY')
        );
      }
    }
    if (unavailableSchedule.length > 0) {
      console.log('partial delivery');
      localStorage.setItem('partialDelivery', JSON.stringify(true));
      localStorage.setItem(
        'dataUnavailableSchedule',
        JSON.stringify(unavailableSchedule)
      );
      setUnavailableSchedule(unavailableSchedule);
    } else {
      console.log('not partial delivery');
      localStorage.removeItem('partialDelivery');
      localStorage.removeItem('dataUnavailableSchedule');
      setUnavailableSchedule([]);
    }
    setDeliveryPostcode(e);
    localStorage.setItem('deliveryPostcode', JSON.stringify(e));
  };

  const availableSchedules = scheduleDeliveries
    .filter((item) => {
      const found = unavailableSchedule.find(
        (a) => a === moment(item.scheduleAt).format('MMM DD YYYY')
      );
      if (!found) {
        return item;
      }
    })
    .map((item) => moment(item.scheduleAt).format('MMM DD YYYY'));

  useEffect(() => {
    if (searchValue) {
      setIsPostcodeFilled(true);
    }

    const isCovered = checkDeliveryCoverage(searchValue);
    props.handleIsCovered(isCovered);
  }, [searchValue]);

  useEffect(() => {
    setIsPostcodeFilled(false);
  }, []);

  return (
    <>
      {props.isContent && (
        <Button onClick={props.onClickBtnContent} icon={<ArrowLeft />} />
      )}

      <div style={{ marginBottom: 40 }}>
        <div className='modal-title'>Check deliverable area</div>
        <div className='modal-subtitle'>
          Enter your postcode to check if we deliver to your area
        </div>
      </div>

      <Form layout='vertical'>
        {/* <Form.Item label='My Address'>
          <Input
            value={deliveryAddress}
            onChange={(e) => {
              localStorage.setItem(
                "deliveryAddress",
                JSON.stringify(e.target.value)
              );
              setDeliveryAddress(e.target.value);
            }}
          />
        </Form.Item> */}
        <Form.Item label='Postcode'>
          <Select
            showSearch
            // value={deliveryPostcode}
            size='large'
            options={currentPostcodes}
            filterOption={(input, option) =>
              (option?.value ?? ('' as any))
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            style={{ width: '100%' }}
            onChange={handleChangePostcode}
            placeholder='Select or search postcode'
          />
        </Form.Item>
      </Form>

      {isPostcodeFilled && (
        <Alert
          message={
            props.isCovered && unavailableSchedule.length === 0
              ? 'Yay, we can delivery to your area'
              : props.isCovered && unavailableSchedule.length > 0
              ? ` We are delivering to this postcode on ${availableSchedules.join(
                  ', '
                )}`
              : 'Sorry, we can’t deliver to your area, please enter another postcode or try pickup order'
          }
          type={
            props.isCovered && unavailableSchedule.length === 0
              ? 'success'
              : props.isCovered && unavailableSchedule.length > 0
              ? 'warning'
              : 'error'
          }
          showIcon
        />
      )}
    </>
  );
};

export default ContentCheckDeliveryCoverage;
