export interface IAvailability {
  availabilitypickupId: string;
  locationId?: string;
  startAt: string;
  endAt: string;
  updatedAt?: string;
  createdAt?: string;
}

export interface ILocation {
  locationId: string;
  locationCode: string;
  locationGeoloc?: Geoloc;
  locationName: string;
  locationAddress: string;
  locationContact: string;
  isPickup: boolean;
  locationType: ELocationType;
  isPublished: boolean;
  availabilityPickups: IAvailability[];
  updatedAt?: string;
  createdAt?: string;
  suburb: string;
  city: string;
  state: string;
  postalCode: string;
  timeframe: string;

  statusLoading?: boolean;
}

export type Geoloc = {
  lat: string | number;
  lng: string | number;
};

export type ELocationType = "main_kitchen" | "outlet";

export interface FetchAllLocationResponse {
  payload: {
    results: ILocation[];
  };
}

export const initialAvailability: IAvailability = {
  startAt: "00:00",
  endAt: "00:00",
  availabilitypickupId: "",
};

export const initialLocation: ILocation = {
  locationId: "",
  locationCode: "",
  locationGeoloc: {
    lat: "",
    lng: "",
  },
  locationName: "",
  locationAddress: "",
  locationContact: "",
  isPickup: true,
  isPublished: true,
  locationType: "outlet",
  availabilityPickups: [initialAvailability],
  updatedAt: "",
  createdAt: "",
  state: "",
  suburb: "",
  city: "",
  postalCode: "",
  timeframe: "",
};
