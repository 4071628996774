import React from "react";
import RootNavigator from "./navigation/RootNavigator";
import "./assets/app.less";
import "./assets/responsive.less";
import { theme } from "./assets/theme";
import { ThemeProvider } from "styled-components";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <RootNavigator />
    </ThemeProvider>
  );
}

export default App;
