import React from 'react';
import { Link } from 'react-router-dom';
import { IHow } from '.';
import HeaderSection from '../components/HeaderSection';
import HowItWorkItem from '../components/HowItWorkItem';
import { httpRequest } from '../helpers/api';
import { IHttpResponse, IPayloadPagination } from '../helpers/pagination';
import AppLayout from '../layout/AppLayout';

const AboutUs: React.FC = () => {
  const textStyle: React.CSSProperties = {
    fontSize: '1rem',
    textAlign: 'justify',
  };
  const textContainerStyle: React.CSSProperties = {};

  const [dataHows, setDataHows] = React.useState<IHow[]>([]);

  React.useEffect(() => {
    const fetchHow = async () => {
      const res = await httpRequest.get<
        IHttpResponse<IPayloadPagination<IHow>>
      >('howitworks');
      setDataHows(res.data.payload.results);
    };

    fetchHow();
  }, []);

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <AppLayout isHomepage>
      <div className='intro-about-us'>
        <div>
          <h1>We make home delivered meals Melbourne loves to eat</h1>
          <p>
            “Full of flavour, fresh ingredients, and variety. And I don’t have
            to cook – it’s a win.” – Lisa
          </p>
        </div>
      </div>

      <div className='homepage-section-full'>
        <div className='homepage-section'>
          <h1 className='title-about-us'>
            The truth is, we love to feed people
          </h1>
          {/* <p style={{ textAlign: "center" }}>
            The truth is, we love to feed people
          </p> */}
        </div>
      </div>

      {/* <div
        id='how-it-works'
        className='homepage-section-full'
        style={{ background: "#FFFFFF" }}
      >
        <div className='homepage-section'>
          <HeaderSection title='How it Works' subtitle='' />
          <div className='how-container'>
            {dataHows.length > 0 &&
              dataHows.map((item, idx) => (
                <HowItWorkItem
                  key={idx}
                  title={item.headline}
                  description={item.subtitle}
                  index={idx + 1}
                  image={item.howitworksImg}
                />
              ))}
          </div>
        </div>
      </div> */}

      <div className='homepage-section'>
        <div className='about-us-container'>
          <div className='about-us-item-even'>
            <div className='about-us-item-image'>
              <img alt='about us image' src='./images/about-us-3.png' />
            </div>
            <div className='about-us-story'>
              <h3>The founder</h3>
              <p style={{ fontSize: 16 }}>
                Spoonful Kitchen is a packaged meals company started by our food
                obsessed, fearless founder, Ivon. A mum of two and dinner party
                hostess, Ivon has always brought people together over food.
                Inspiration often struck when Ivon was eating out at
                restaurants: “I can make that at home”, she thought. And so she
                did.
              </p>
            </div>
          </div>

          <div className='about-us-item-odd'>
            <div className='about-us-story'>
              <h3>The story</h3>
              <p style={{ fontSize: 16 }}>
                Ivon started preparing vending machine hot meals for student
                accommodation back in 2016 When the meals began to sell like
                hotcakes, she knew she was onto something. Since then, our meals
                have been stocked by some of Melbourne’s leading corporate and
                government players – conveniently feeding hungry employees and
                students.
              </p>
            </div>
            <div className='about-us-item-image'>
              <img alt='about us image' src='./images/about-us-2.png' />
            </div>
          </div>

          <div className='about-us-item-even'>
            <div className='about-us-item-image'>
              <img alt='about us image' src='./images/about-us-1.png' />
            </div>
            <div className='about-us-story'>
              <h3>The Mission</h3>
              <p style={{ fontSize: 16 }}>
                Our mission is to enrich people’s lives with delicious food and
                easy mealtimes. We set to work and built our kitchen amidst
                Melbourne’s longest COVID lockdown, calling on some of
                Melbourne’s top chefs to help us craft incredible meals from
                their recipes.
              </p>
              <p style={{ fontStyle: 'italic', fontSize: 16 }}>
                “I love that when you’re after seriously good, real food –
                Spoonful Kitchen makes it easy. From your fridge to your plate,
                simply heat and enjoy.” - Ivon Tio.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='section-cta'>
        <div className='bottom-banner-wrapper'>
          <h1>Ready to try our meals?</h1>
          <Link to='menu' className='bottom-banner-link'>
            <img alt='menu-icon' src='./images/tudung.svg' />
            <span>Yes Please!</span>
          </Link>
        </div>
      </div>
    </AppLayout>
  );
};

export default AboutUs;
