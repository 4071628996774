import { CheckCircleFilled, PlusOutlined } from '@ant-design/icons';
import { Alert, Divider, Image, Row, Typography } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import { Minus, Plus } from 'react-feather';
import { theme } from '../assets/theme';
import { convertHtmlToText } from '../helpers/text';
import useCart from '../hooks/useCart';
import useOrder, { EOrderType } from '../hooks/useOrder';
import useWindowSize from '../hooks/useWindowSize';
import { IMenu } from '../types/menu.type';
import AppButton from './AppButton';

interface Props {
  handleOnClickMenu: (menu: IMenu) => void;
  menu: IMenu;
  imageHeight?: number;
  isMealPack?: boolean;
  currMealPackQty?: number;
  addMealPackQty?: (menu: IMenu) => void;
  removeMealPackQty?: (menu: IMenu) => void;
  showModalImage?: (image: string) => void;
  isDisabled?: boolean;
  selectedScheduleAt?: string;
}

const MenuCardItem: React.FC<Props> = ({
  handleOnClickMenu,
  menu,
  isMealPack,
  currMealPackQty,
  addMealPackQty,
  removeMealPackQty,
  showModalImage,
  isDisabled,
  imageHeight,
  selectedScheduleAt,
}) => {
  const { isMobile } = useWindowSize();
  const { carts, localCarts } = useCart();
  const { orderType, scheduleDeliveries, deliveryPostcode } = useOrder();

  const isProductExistInCart = (productId: string) => {
    if (localCarts) {
      if (orderType === EOrderType.delivery) {
        return localCarts.find(
          (item) =>
            item.productId === productId &&
            item.meta.deliveryScheduleAt === selectedScheduleAt
        );
      } else if (orderType === EOrderType.pickup) {
        return localCarts.find(
          (item) =>
            item.productId === productId &&
            item.meta.pickupScheduledAt === selectedScheduleAt
        );
      }
    }
    return false;
  };

  const isAvailable =
    orderType === EOrderType.delivery
      ? scheduleDeliveries.find(
          (item) => item.scheduleAt === selectedScheduleAt
        )?.deliveryCoverage?.postalCode[deliveryPostcode]
      : true;

  const renderDefaultVariant = () => {
    const found = menu.variants.find((item) => item.isDefaultPrice);
    if (found) {
      return '$' + found.price.toFixed(2);
    } else {
      return '$' + menu.variants[0].price.toFixed(2);
    }
  };

  return (
    <div
      onClick={() => {
        !isMealPack && handleOnClickMenu(menu)}}
      key={menu.menuId}
      className='menu-item'
      style={{
        display: 'flex',
        flex: 1,
        height: isMobile ? undefined : '100%',
        flexDirection: 'column',
        cursor: isAvailable ? 'pointer' : 'pointer',
        position: 'relative',
        
      }}
      // title={
      //   !isAvailable
      //     ? "Sorry we can't deliver this menu to your area"
      //     : undefined
      // }
    >
      <Image
        onClick={() =>
          showModalImage &&
          menu.images &&
          menu.images.length > 0 &&
          menu.images[0].imageUrl &&
          showModalImage(menu.images[0].imageUrl)
        }
        preview={false}
        height={imageHeight || 270}
        width='100%'
        src={
          menu.images && menu.images.length > 0
            ? menu.images[0].imageUrl || '/images/select-image.jpg'
            : '/images/select-image.jpg'
        }
        fallback={'/images/blur-image.jpeg'}
        style={{
          borderRadius: 10,
          objectFit:
            !menu.images || menu.images.length === 0 ? 'contain' : 'cover',
          border:
            !menu.images || menu.images.length === 0
              ? '1px solid #ddd'
              : 'none',
        }}
      />
      {isProductExistInCart(menu.menuId) && (
        <Row justify='center' align='middle' className='menu-added-indicator'>
          <CheckCircleFilled
            style={{
              color: '#52C41A',
              fontSize: 16,
            }}
          />
          <Typography.Text
            style={{ marginLeft: 3, fontSize: 14, fontWeight: 600 }}
          >
            Added
          </Typography.Text>
        </Row>
      )}
      <div className='menu-info-group'>
        <div className='menu-info'>
          <div
            style={{
              fontSize: 18,
              fontWeight: 'bold',
              marginBottom: 10,
              marginTop: 10,
            }}
          >
            <Typography.Text
              style={{
                fontFamily: '"Poppins", sans-serif',
                fontSize: '18px',
                fontWeight: 600,
                letterSpacing: '-0.02em',
              }}
            >
              {menu.name}
            </Typography.Text>
          </div>
          <Paragraph
            style={{
              fontSize: 13,
              color: '#1D2B36',
              fontWeight: 400,
            }}
            ellipsis={{
              rows: 3,
            }}
          >
            {convertHtmlToText(menu.description)}
          </Paragraph>
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 10,
            flexWrap: 'wrap',
          }}
        >
          {!isMealPack && (
            <>
              {!menu.itemIds ? (
                <div>
                  <Typography.Text style={{ fontSize: isMobile ? 14 : 16 }}>
                    {renderDefaultVariant()}
                  </Typography.Text>
                  {menu.variants.length > 1 && (
                    <>
                      <Divider type='vertical' />
                      <Typography.Text
                        type='secondary'
                        style={{ fontSize: '12px' }}
                      >
                        {menu.variants.length} Variants
                      </Typography.Text>
                    </>
                  )}
                </div>
              ) : (
                <Typography.Text style={{ fontSize: 16 }}>
                  ${menu.price ? menu.price.toFixed(2) : '-'}
                </Typography.Text>
              )}
            </>
          )}

          {!isMealPack ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {isAvailable && (
                <AppButton
                  size={isMobile ? 'small' : 'middle'}
                  style={{
                    borderRadius: 6,
                    backgroundColor: 'white',
                    border: '1px',
                    borderStyle: 'solid',
                    borderColor: '#ff4a50',
                    color: '#ff4a50',
                    height: '38px',
                    fontSize: '12px',
                    fontWeight: 600,
                  }}
                  icon={<PlusOutlined color='#000000' />}
                >
                  {isMobile
                    ? 'Add'
                    : menu.itemIds && menu.itemIds.length > 0
                    ? 'Choose Menu'
                    : 'Add Menu'}
                </AppButton>
              )}
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 10,
                borderRadius: 10,
                border: '1px solid #D5DCE1',
              }}
            >
              <div
                onClick={() => {
                  if (currMealPackQty && currMealPackQty > 0) {
                    removeMealPackQty && removeMealPackQty(menu);
                  }
                }}
                style={{
                  width: 42,
                  height: 42,
                  cursor:
                    currMealPackQty && currMealPackQty > 0
                      ? 'pointer'
                      : 'not-allowed',
                  opacity: currMealPackQty && currMealPackQty > 0 ? 1 : 0.4,
                  textAlign: 'center',
                  borderRight: '1px solid #D5DCE1',
                  fontSize: 24,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 600,
                }}
              >
                <Minus size={16} />
              </div>
              <div
                style={{
                  width: 50,
                  textAlign: 'center',
                  fontSize: 16,
                  fontWeight: 600,
                }}
              >
                {currMealPackQty || 0}
              </div>
              <div
                onClick={() => {
                  !isDisabled && addMealPackQty && addMealPackQty(menu);
                }}
                style={{
                  width: 42,
                  height: 42,
                  cursor: isDisabled ? 'not-allowed' : 'pointer',
                  textAlign: 'center',
                  borderLeft: '1px solid #D5DCE1',
                  fontSize: 24,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 600,
                }}
              >
                <Plus style={{ opacity: isDisabled ? 0.4 : 1 }} size={16} />
              </div>
            </div>
          )}

          {/* {!isAvailable && (
          <div className='menu-indicator'>
            Cannot deliver menu to this postcode
          </div>
        )} */}
        </div>
      </div>
    </div>
  );
};

export default MenuCardItem;
