import Icon from '@ant-design/icons'
import React from 'react'

const TudungSvg = () => (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.2852 12.8097C17.2852 10.8773 16.5176 9.02405 15.1511 7.65763C13.7847 6.29121 11.9315 5.52356 9.99905 5.52356C8.06664 5.52356 6.21338 6.29121 4.84696 7.65763C3.48054 9.02405 2.71289 10.8773 2.71289 12.8097"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <ellipse cx="10" cy="3.09487" rx="1.61915" ry="1.61915" fill="currentColor" />
      <path
        d="M18.0958 14.4289H1.9043C2.22813 16.3719 3.65837 16.8576 4.33302 16.8576H15.667C17.61 16.8576 18.0958 15.2385 18.0958 14.4289Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );


type Props = {
  size?: number;
  color?: string;
  iconProps?: any;
  containerStyle?: any;
}

export default function IconTudung(props: Props) {
  return (
    <div style={{ width: props.size, height: props.size, ...props.containerStyle }}>
      <Icon
        component={TudungSvg}
        style={{ color: props.color || '#FFFFFF' }}
        {...props.iconProps}
      />
    </div>
  );
}