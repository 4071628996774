import { Modal } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import useCart, { CartItem } from '../hooks/useCart';
import useWindowSize from '../hooks/useWindowSize';
import AppButton from './AppButton';
import Cart from './Cart';

const ModalCart2 = () => {
  const { isMobile } = useWindowSize();
  const {
    isShowModalCart,
    setIsShowModalCart,
    localCarts,
    transactionSubTotal,
    isAllowToCheckout,
    loadingConstructCart,
  } = useCart();
  const history = useHistory();
  const location = useLocation();

  const isCartValid = () => {
    let allMealPackQty = 0;
    let allMealPackMenuItemQty = 0;

    if (localCarts) {
      for (const data of localCarts) {
        if (
          data.meta &&
          data.meta.menuItems &&
          data.meta.menuItems.length > 0
        ) {
          allMealPackQty = allMealPackQty + data.quantity;
          for (const menuChild of data.meta.menuItems) {
            allMealPackMenuItemQty = allMealPackMenuItemQty + menuChild.qty;
          }
        }
      }
      return allMealPackMenuItemQty === allMealPackQty ? true : false;
    } else {
      return false;
    }
  };

  const handleToCheckout = () => {
    setIsShowModalCart(false);
    history.push('checkout');
  };

  const isCheckoutPage = location.pathname.includes('checkout');

  return (
    <Modal
      visible={isShowModalCart}
      closable={true}
      onCancel={() => setIsShowModalCart(false)}
      footer={
        !isCheckoutPage ? (
          <>
            <AppButton
              disabled={!isCartValid() || !isAllowToCheckout.allow}
              type='primary'
              loading={loadingConstructCart}
              onClick={handleToCheckout}
              style={{ width: '100%' }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <span style={{ fontWeight: 600, fontSize: 16 }}>Checkout</span>
                <span style={{ fontWeight: 600, fontSize: 16 }}>
                  ${transactionSubTotal.toFixed(2)}
                </span>
              </div>
            </AppButton>
          </>
        ) : (
          false
        )
      }
      bodyStyle={{
        padding: isMobile ? 12 : 20,
      }}
    >
      <Cart hideDetail modal />
    </Modal>
  );
};

export default ModalCart2;
