import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  CheckCircleFilled,
  EnvironmentOutlined,
  FilterOutlined,
  LoadingOutlined,
  PlusOutlined,
  SearchOutlined,
  SlidersOutlined,
} from '@ant-design/icons';
import {
  Typography,
  Input,
  Image,
  Row,
  Col,
  Button,
  Divider,
  Modal,
  Form,
  Tabs,
  message,
} from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import { IMenu, initialMenu } from '../../types/menu.type';
import AppLayout from '../../layout/AppLayout';
import { IAvailability } from '../../types/location.type';
import Slider, { Settings } from 'react-slick';
import { ChevronDown } from 'react-feather';
import AppButton from '../../components/AppButton';
import _, { isEmpty } from 'lodash';
import useCart, { CartItem } from '../../hooks/useCart';
import useAuth from '../../hooks/useAuth';
import useFetchList from '../../hooks/useFetchList';
import useGlobalSearch from '../../hooks/useGlobalSearch';
import ModalMenuDetail, {
  AddToCartItem,
} from '../../components/ModalMenuDetail';
import useModalAuth from '../../hooks/useModalAuth';
import ModalSelectLocation from '../../components/ModalSelectLocation';
import useLocationQuery from '../../hooks/useLocationQuery';
import useWindowSize from '../../hooks/useWindowSize';
import EmptyData from '../../components/EmptyData';
import { theme } from '../../assets/theme';
import FilterMenu, { IFilterMenu } from '../../components/FilterMenu';
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet';
import useGlobalFilter from '../../hooks/useGlobalFilter';
import ModalSelectPickupTime from '../../components/ModalSelectPickupTime';
import useGlobalConfig from '../../hooks/useGlobalConfig';
import Paragraph from 'antd/lib/typography/Paragraph';
import { convertHtmlToText } from '../../helpers/text';
import { useHistory } from 'react-router-dom';
import ComingSoon from '../../components/ComingSoon';
import MenuTabs, { MenuTabItem } from '../../components/MenuTabs';
import ModalSelectOrderType from '../../components/ModalSelectOrderType';
import MenuCardItem from '../../components/MenuCardItem';
import { httpRequest } from '../../helpers/api';
import {
  BaseResponsePaginationProps,
  BaseResponseProps,
} from '../../types/config.type';
import { IScheduleDelivery } from '../../types/schedules.type';
import { generateQueryString } from '../../helpers/generateQueryString';
import useOrder, { EOrderType } from '../../hooks/useOrder';
import ModalCheckDeliveryCoverage from '../../components/ModalCheckDeliveryCovarage';
import useCountDown from '../../hooks/useCountDownTimer';
import ModalMenuDetail2 from '../../components/ModalMenuDetail2';
import ModalMealPacks from '../../components/ModalMealPack';
import { useDebounce } from 'use-debounce/lib';
import { CheckCustomerDiscount } from '../../types/customer.type';
import { calculateDiscount } from '../../helpers/order';
import { FetchAllAppConfigResponse } from '../../types/appConfig.type';

const isComingSoon = false;
const { Title, Text } = Typography;

type CarouselDate = {
  identifierIndex?: number;
  date: Date;
  isSelected: boolean;
  availabilityDate?: IAvailability;
};
const currentDate = moment().startOf('day');
const now = new Date();

interface CheckCustomerDiscountProps extends BaseResponseProps {
  payload: CheckCustomerDiscount;
}

interface IMenuGroup {
  categoryId: string;
  categoryName: string;
  menus: IMenu[];
}

const Menus = () => {
  const initialDate = new Date();
  const history = useHistory();
  const sheetRef: any = React.useRef<BottomSheetRef>();
  const { width, isMobile } = useWindowSize();

  const { setGlobalKeyword, globalKeyword } = useGlobalSearch();
  const {
    setIsShowModalCart,
    addToCart,
    cartInit,
    carts,
    localCarts,
    cartGroup,
    setIsShowModalCartWarning,
    addTmpCartItem,
    removeAllCartItems,
    checkRemoveAllCartItems,
    subTotal,
  } = useCart();
  const { user, isLoggedIn, membershipDiscount } = useAuth();
  const { setIsShowModalLogin } = useModalAuth();

  const [searchValue] = useDebounce(globalKeyword, 1000);

  const appConfigFetchRef: any = useRef(false);

  const {
    currMenuGroup,
    orderType,
    currSelectedLocation,
    deliveryAddress,
    deliveryPostcode,
    scheduleDeliveries,
    partialDelivery,
    unavailableSchedule,
    setCurrMenuGroup,
    setTmpOrderType,
    fetchScheduleDeliveries,
    fetchSchedules,
    setMealPackMenu,
    setIsShowModalMealPacks,
    setIsShowModalOrderType,
    dataPromo,
  } = useOrder();

  const [isShowBottomSheetFilter, setIsShowBottomSheetFilter] =
    React.useState(false);

  const [activeMenuTab, setActiveMenuTab] = React.useState<number>(0);

  if (now.getHours() > 12) {
    initialDate.setDate(initialDate.getDate() + 2);
  } else {
    initialDate.setDate(initialDate.getDate() + 1);
  }

  const [selectedMenu, setSelectedMenu] = React.useState<IMenu>(initialMenu);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [tempMenuGroup, setTempMenuGroup] = React.useState<any>(currMenuGroup);

  const [isModalMenuVisible, setisModalMenuVisible] =
    React.useState<boolean>(false);
  const [isModalWarningVisible, setisModalWarningVisible] =
    React.useState<boolean>(false);

  const handleOpenModalOrderType = async (type: EOrderType) => {
    if (localCarts && localCarts.length > 0) {
      const res = checkRemoveAllCartItems();
      if (res === 0) {
        setIsShowModalOrderType(true);
      } else {
        setIsShowModalCartWarning(true);
      }
    } else {
      setIsShowModalOrderType(true);
    }
    if (type === EOrderType.delivery) {
      setTmpOrderType(type);
    } else if (type === EOrderType.pickup) {
      // setCurrSelectedLocation(undefined);
      setTmpOrderType(type);
    }
  };

  const handleOnClickMenu = (menu: IMenu) => {
    if (orderType === EOrderType.pickup && !currSelectedLocation) {
      setTmpOrderType(EOrderType.pickup);
      handleOpenModalOrderType(EOrderType.pickup);
    } else if (orderType === EOrderType.delivery && !deliveryPostcode) {
      setTmpOrderType(EOrderType.delivery);
      handleOpenModalOrderType(EOrderType.delivery);
    } else {
      if (menu.itemIds && menu.itemIds.length > 0) {
        setMealPackMenu({
          ...menu,
          items: menu.items
            ? menu.items.map((item) => ({
                ...item,
                currQty: 0,
              }))
            : [],
        });
        setIsShowModalMealPacks(true);
      } else {
        setSelectedMenu(menu);
        setisModalMenuVisible(true);
      }
    }
  };

  const handleClickAddToCart = (newData: CartItem) => {
    addTmpCartItem(newData);
    setisModalMenuVisible(false);
    message.success(
      `Success add ${newData.quantity} ${newData.productDetail?.name} to your cart`
    );
  };

  const scrollTo = (id: string | undefined) => {
    if (id && currMenuGroup && currMenuGroup.length > 0) {
      const groupExist = currMenuGroup[activeMenuTab].category.find(
        (item) => item.categoryId === id
      );
      let violation: any;

      if (groupExist) {
        violation = document.getElementById(id);
        window.scrollTo({
          top: violation.offsetTop + -130,
          behavior: 'smooth',
        });
      }
    }
  };

  const handleChooseMenuTab = (date: string) => {
    const activeTab = currMenuGroup.findIndex((tab) => tab.scheduleAt === date);
    setActiveMenuTab(activeTab);
  };

  const reMapDataCurrGroup = (
    newFilter?: IFilterMenu,
    searchValue?: string
  ) => {
    if ((newFilter && newFilter.allergens.length > 0) || searchValue) {
      const newMenuGroup = currMenuGroup.map((item) => {
        return {
          ...item,
          category: item.category.map((c) => {
            return {
              ...c,
              menus: c.menus.map((m) => {
                let found;
                if (newFilter || searchValue) {
                  found =
                    m.tags.find((t: any) =>
                      newFilter?.allergens.includes(t.tagId)
                    ) ||
                    m.name.toLowerCase().includes(searchValue?.toLowerCase());
                  if (found) {
                    return {
                      ...m,
                      isHide: false,
                    };
                  } else {
                    return {
                      ...m,
                      isHide: true,
                    };
                  }
                } else {
                  return {
                    ...m,
                    isHide: false,
                  };
                }
              }),
            };
          }),
        };
      });
      console.log('counter.setTempMenuGroup 1', newMenuGroup);
      // setCurrMenuGroup(newMenuGroup);
      setTempMenuGroup(newMenuGroup);
    } else {
      const newMenuGroup = currMenuGroup.map((item) => {
        return {
          ...item,
          category: item.category.map((c) => {
            return {
              ...c,
              menus: c.menus.map((m) => {
                return {
                  ...m,
                  isHide: false,
                };
              }),
            };
          }),
        };
      });
      console.log('counter.setTempMenuGroup 2 currMenuGroup', currMenuGroup);
      console.log('counter.setTempMenuGroup 2 newMenuGroup', newMenuGroup);
      // setCurrMenuGroup(newMenuGroup);
      setTempMenuGroup(newMenuGroup);
    }
  };

  const isAllCurrMenuGroupEmpty = () => {
    let res = 0;
    let totalMenu = 0;
    for (const a of tempMenuGroup) {
      for (const b of a.category) {
        for (const c of b.menus) {
          totalMenu = totalMenu + 1;
          if (c.isHide) {
            res = res + 1;
          }
        }
      }
    }
    return res === totalMenu;
  };

  const [customerDiscount, setCustomerDiscount] =
    useState<CheckCustomerDiscount>();

  const [isNewCustomer, setIsNewCustomer] = useState<boolean>(false);

  // async function checkFirstTimeDiscount() {
  //   const res = await httpRequest.post<CheckCustomerDiscountProps>(
  //     "/transactions/check/new-customer/discount",
  //     {
  //       totalPrice: totalPay(),
  //     }
  //   );
  //   if (res && res.data.payload) {
  //     console.log("checkFirstTimeDiscount", totalPay(), res.data.payload);
  //     if (res.data.payload.isNewCustomer) {
  //       setIsNewCustomer(true);
  //     }
  //     setCustomerDiscount(res.data.payload);
  //   }
  // }

  // const newCustomerDiscountValue = () => {
  //   let res = 0;
  //   if (dataPromo && isNewCustomer) {
  //     res = calculateDiscount(subTotal(), dataPromo.value);
  //   }
  //   return res;
  // };

  // const membershipDiscountValue = () => {
  //   let res = 0;
  //   if (membershipDiscount) {
  //     res = calculateDiscount(subTotal(), membershipDiscount.amount + "%");
  //   }
  //   return res;
  // };

  // const totalPay = () => {
  //   let totalDeliveryFee = 0;
  //   let tmpTotal = 0;
  //   if (cartGroup.length > 0 && orderType === EOrderType.delivery) {
  //     for (const curr of cartGroup) {
  //       const found = scheduleDeliveries.find(
  //         (item) => item.scheduleAt === curr.scheduleAt
  //       );
  //       if (found) {
  //         totalDeliveryFee =
  //           totalDeliveryFee +
  //           (found.deliveryCoverage
  //             ? Number(found.deliveryCoverage.postalCode[deliveryPostcode])
  //             : 0);
  //       }
  //     }
  //   }

  //   tmpTotal =
  //     dataPromo && isNewCustomer
  //       ? subTotal() - newCustomerDiscountValue() + totalDeliveryFee
  //       : membershipDiscount
  //       ? subTotal() - membershipDiscountValue() + totalDeliveryFee
  //       : subTotal() + totalDeliveryFee;
  //   return tmpTotal;
  // };

  const checkLeftoverCart = () => {
    const curr = localStorage.getItem('dateAddToCart');
    if (curr) {
      let date = JSON.parse(curr);
      if (moment(date).isBefore(moment(), 'day')) {
        removeAllCartItems();
      }
    } else {
      localStorage.setItem(
        'dateAddToCart',
        JSON.stringify(moment().format('YYYY-MM-DD'))
      );
    }
  };

  React.useEffect(() => {
    if (isLoggedIn) {
      cartInit();
    }
  }, [isLoggedIn]);

  const localCurrPickupLocation = localStorage.getItem('location');

  React.useEffect(() => {
    console.log('fetch schedules 2');
    fetchSchedules(
      localCurrPickupLocation
        ? JSON.parse(localCurrPickupLocation).locationId
        : null
    );
  }, [localCurrPickupLocation]);

  React.useEffect(() => {
    fetchScheduleDeliveries();
    checkLeftoverCart();
    window.scrollTo(0, 0);
  }, []);

  const availableCategories = useMemo(() => {
    if (currMenuGroup && currMenuGroup[activeMenuTab]) {
      return currMenuGroup[activeMenuTab].category.map(
        (item) => item.categoryId
      );
    }
  }, [currMenuGroup]);

  React.useEffect(() => {
    reMapDataCurrGroup(undefined, searchValue);
  }, [searchValue]);

  React.useEffect(() => {
    console.log('counter.setTempMenuGroup([])', currMenuGroup.length);
    if (currMenuGroup.length === 0) {
      setTempMenuGroup([]);
    } else {
      reMapDataCurrGroup(undefined, undefined);
    }
  }, [currMenuGroup]);

  React.useEffect(() => {
    if (currMenuGroup.length > 0 && isLoading) {
      setIsLoading(false);
      reMapDataCurrGroup(undefined, undefined);
    }
  }, [currMenuGroup]);
  React.useEffect(() => {
    if (currMenuGroup.length > 1 && isLoading) {
      setIsLoading(false);
      reMapDataCurrGroup(undefined, undefined);
    }
  }, []);

  React.useEffect(() => {
    const localOrderType = localStorage.getItem('orderType');
    if (!orderType && !localOrderType) {
      setIsShowModalOrderType(true);
    }
  }, [orderType]);

  // Counter fetch new schedule
  const time = moment().hours(10).minutes(1).diff(moment(), 'seconds');
  const [counter, setCounter] = React.useState(time < 0 ? time * -1 : time);

  const refreshInterval = 60 * 1;

  React.useEffect(() => {
    let timer: any;
    if (counter > 0) {
      console.log('counter.not refresh', counter);
      timer = setInterval(
        () => setCounter(counter - refreshInterval),
        1000 * refreshInterval
      );
    } else {
      console.log('counter.refresh', counter);
      fetchScheduleDeliveries();
      fetchSchedules();
      removeAllCartItems();
      localStorage.removeItem('location');
      localStorage.removeItem('orderType');
      localStorage.removeItem('partialDelivery');
      localStorage.removeItem('dateAddToCart');
      localStorage.removeItem('dataUnavailableSchedule');
      localStorage.removeItem('deliveryPostcode');
    }
    return () => clearInterval(timer);
  }, [counter]);

  const _renderLocationAddress = () => {
    let locAddress = '';
    if (currSelectedLocation) {
      const currLocAddress = [
        currSelectedLocation.locationAddress,
        currSelectedLocation.suburb || undefined,
        currSelectedLocation.city || undefined,
        currSelectedLocation.postalCode || undefined,
        currSelectedLocation.state || undefined,
      ];
      locAddress = currLocAddress.filter((item) => item).join(', ');
    }
    return locAddress;
  };

  return (
    <AppLayout isMenu>
      {orderType === EOrderType.pickup && currSelectedLocation ? (
        <div
          className='info-order'
          style={isMobile && isModalMenuVisible ? { position: 'initial' } : {}}
        >
          <div>
            Pickup your order at <b>{_renderLocationAddress()}</b>
          </div>
        </div>
      ) : orderType === EOrderType.delivery && deliveryPostcode ? (
        <div
          className='info-order'
          style={isMobile && isModalMenuVisible ? { position: 'initial' } : {}}
        >
          {partialDelivery ? (
            <div>
              {/* We can deliver to "{deliveryPostcode}" area except menu in this
              schedule: {unavailableSchedule.join(", ")} */}
              We can deliver to <b>{deliveryPostcode}</b>. Please check schedule
              for more info below.
            </div>
          ) : (
            <div>
              Yes!, We can deliver your meals to <b>{deliveryPostcode}</b>
            </div>
          )}
        </div>
      ) : (
        false
      )}
      <div
        id='menu-container'
        className='homepage-section'
        style={{ padding: '0', marginBottom: 100 }}
      >
        <Row
          wrap
          gutter={16}
          style={{ marginRight: 0, marginLeft: '0 !important' }}
        >
          {/** FILTER */}
          {!isMobile && (
            <Col
              xs={24}
              lg={5}
              style={{
                position: 'sticky',
                top: !orderType ? 5 : 145,
                alignSelf: 'start',
              }}
            >
              <FilterMenu
                availableCategories={availableCategories}
                needConfirm={isMobile}
                onChangeFilter={(newFilter) => {
                  reMapDataCurrGroup(newFilter);
                  scrollTo(newFilter.selectedCategory?.categoryId);
                }}
              />
            </Col>
          )}
          {/** END FILTER */}

          {/** CHOOSE SCHEDULE */}
          <Col offset={isMobile ? 0 : 1} xs={24} lg={18}>
            {/* {!isMobile && (
              <>
                <Row>
                  <Title level={3}>Pick Your Schedule</Title>
                </Row>
                <Row style={{ marginBottom: 20 }}>
                  <Row
                    style={{
                      borderRadius: "100px",
                      border: "1px lightgrey solid",
                      width: "100%",
                      cursor: "pointer",
                      padding: "0px 10px 0px 10px",
                    }}
                    align='middle'
                    justify='space-between'
                    onClick={() => setIsShowModalSelectLocation(true)}
                  >
                    <Col>
                      <Row align='middle'>
                        <EnvironmentOutlined style={{ color: "#556575" }} />
                        <Text
                          style={{
                            padding: "10px 0px 10px 0px",
                            marginLeft: 10,
                            marginRight: 10,
                            paddingRight: 10,
                            color: "#556575",
                            borderRight: "1px solid lightgrey",
                          }}
                        >
                          Pickup from
                        </Text>

                        {getLocationValueInput()}
                      </Row>
                    </Col>

                    <ChevronDown />
                  </Row>
                </Row>
              </>
            )} */}

            {isMobile && (
              <div
                className='search-mobile'
                style={
                  (isMobile && isModalMenuVisible) || !orderType
                    ? { position: 'initial' }
                    : {}
                }
              >
                <Form.Item name='search' rules={[]} style={{ marginBottom: 0 }}>
                  <Input
                    style={{ borderRadius: 100 }}
                    size='large'
                    placeholder='What are you eating?'
                    prefix={<SearchOutlined style={{ color: 'darkgrey' }} />}
                    suffix={
                      <div
                        onClick={() => {
                          setIsShowBottomSheetFilter(true);
                        }}
                      >
                        <FilterOutlined style={{ fontSize: 16 }} />
                      </div>
                    }
                    onChange={(e) => setGlobalKeyword(e.target.value)}
                    value={globalKeyword}
                  />
                </Form.Item>
              </div>
            )}

            {currMenuGroup.length > 0 && (
              <MenuTabs
                activeTabIdx={activeMenuTab}
                dates={currMenuGroup || []}
                onCLick={(date: string) => handleChooseMenuTab(date)}
                showModalMenu={isModalMenuVisible}
              />
            )}
            {tempMenuGroup.length > 0 &&
              tempMenuGroup[activeMenuTab].category.map((group: any) =>
                group.menus.filter((m: any) => m.isHide).length ===
                group.menus.length ? (
                  false
                ) : (
                  <div key={group.categoryId}>
                    <div id={group.categoryId} className='separator-menu'>
                      <div className='separator-content'>
                        <div className='separator-content-title'>
                          {group.categoryName}
                        </div>
                      </div>
                    </div>
                    <div className='menu-group'>
                      {group.menus.map((menu: any) =>
                        menu.isHide ? (
                          false
                        ) : (
                          <MenuCardItem
                            selectedScheduleAt={
                              tempMenuGroup.length > 0
                                ? tempMenuGroup[activeMenuTab].scheduleAt
                                : ''
                            }
                            key={menu.menuId}
                            menu={menu}
                            handleOnClickMenu={(data) =>
                              handleOnClickMenu(data)
                            }
                          />
                        )
                      )}
                    </div>
                  </div>
                )
              )}

            <div>
              {/* {isLoading && (
                <Row justify='center'>
                  <LoadingOutlined />
                </Row>
              )} */}

              {(currMenuGroup.length === 0 ||
                isAllCurrMenuGroupEmpty() ||
                tempMenuGroup.length === 0) && (
                <Row justify='center'>
                  <EmptyData
                    title='Menu Empty'
                    subtitle='There is nothing to show here...'
                  />
                </Row>
              )}

              {/* {menus.length > 0 &&
                menuGrouped.map((group) => (
                  <div key={group.categoryId}>
                    <div id={group.categoryId} className='separator-menu'>
                      <img src='./images/banner-menu.svg' />
                      <div className='separator-content'>
                        <div className='separator-content-title'>
                          {group.categoryName}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "grid",
                        flexWrap: "wrap",
                        gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr 1fr",
                        alignItems: "flex-start",
                        gap: 15,
                        width: 700
                      }}
                    >
                      {group.menus.map((menu) => (
                        <MenuCardItem
                          key={menu.menuId}
                          menu={menu}
                          handleOnClickMenu={(data) => handleOnClickMenu(data)}
                        />
                      ))}
                    </div>
                  </div>
                ))} */}
            </div>

            {/* {pagination.next ? (
              <Row justify='center' align='middle' style={{ marginTop: 20 }}>
                <Button
                  onClick={handleClickPagination}
                  style={{
                    backgroundColor: "#FF4A50",
                    color: "white",
                    borderRadius: "100px",
                  }}
                  type='text'
                >
                  Load More
                </Button>
              </Row>
            ) : (
              false
            )} */}
          </Col>
          {/** END CHOOSE SCHEDULE */}
        </Row>
      </div>

      {/** Modal Select Order Type */}
      <ModalSelectOrderType />

      {/** Modal Check Delivery Coverage */}
      <ModalCheckDeliveryCoverage />

      {/** Modal Location */}
      <ModalSelectLocation onChange={() => history.push('/menu')} />

      {/** Modal Meal Packs */}
      <ModalMealPacks
        scheduleAt={
          currMenuGroup && currMenuGroup.length > 0
            ? currMenuGroup[activeMenuTab].scheduleAt
            : ''
        }
      />

      {/** Modal Availability */}
      {/* <ModalSelectPickupTime
        onChange={(newItem: IAvailability) =>
          handleChangePickupTime(newItem, selectedDate)
        }
      /> */}

      {isModalMenuVisible && (
        <ModalMenuDetail2
          selectedScheduleAt={currMenuGroup[activeMenuTab].scheduleAt || ''}
          data={selectedMenu}
          onCancel={() => {
            setisModalMenuVisible(false);
          }}
          onAddToCart={(newData) => {
            handleClickAddToCart(newData);
          }}
        />
      )}

      {/** Modal Warning */}
      <Modal
        title={`Warning`}
        visible={isModalWarningVisible}
        footer={true}
        onCancel={() => {
          setisModalWarningVisible(false);
        }}
        style={{
          borderRadius: '100px',
        }}
        width={isMobile ? '90%' : 500}
      >
        <Text>
          {/* Please Choose {selectedLocation ? "Date to pickup" : "Location"}{" "} */}
          Before Continue
        </Text>
      </Modal>

      {/** FILTER BOTTOM SHEET */}
      <BottomSheet
        open={isShowBottomSheetFilter}
        ref={sheetRef}
        onDismiss={() => setIsShowBottomSheetFilter(false)}
      >
        <div style={{ padding: '10px 20px' }}>
          <FilterMenu
            availableCategories={availableCategories}
            needConfirm={isMobile}
            onChangeFilter={(newFilter) => {
              reMapDataCurrGroup(newFilter);
              scrollTo(newFilter.selectedCategory?.categoryId);
            }}
          />
        </div>
      </BottomSheet>
      {/** END FILTER BOTTOM SHEET */}

      {isMobile && carts.length > 0 && (
        <Row
          align='middle'
          style={{
            width: '100%',
            position: 'fixed',
            bottom: 20,
            padding: 20,
          }}
        >
          <AppButton
            block
            type='primary'
            onClick={() => {
              setIsShowModalCart(true);
            }}
          >
            <Row
              justify='space-between'
              align='middle'
              style={{ width: '100%' }}
            >
              <Text style={{ color: theme.colors.white }}>View Orders</Text>
              <div
                style={{
                  width: 18,
                  height: 18,
                  borderRadius: 18,
                  background: theme.colors.white,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Text style={{ color: theme.colors.primary, fontSize: 10 }}>
                  {carts.length}
                </Text>
              </div>
            </Row>
          </AppButton>
        </Row>
      )}
    </AppLayout>
  );
};

export const settings: Settings = {
  slidesToShow: 1,
  infinite: true,
  speed: 500,
  rows: 1,
  slidesPerRow: 1,
  arrows: false,
};

export const ContainerFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
`;

export default Menus;
