import React, { useEffect, useState } from 'react';
import { httpRequest } from '../helpers/api';
import { IHttpResponse } from '../helpers/pagination';
import AppLayout from '../layout/AppLayout';
import { IPrivacyPolicy } from '../types/privacyPolicy.type';
import { Spin, message } from 'antd';
import { getErrorMessage } from '../helpers/errorHandler';

const PrivacyPolicy: React.FC = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState<IPrivacyPolicy>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    setLoading(true);
    try {
      const res = await httpRequest.get<IHttpResponse<IPrivacyPolicy>>(
        `privacy-policy`
      );

      setPrivacyPolicy(res.data.payload);
    } catch (err) {
      message.error(getErrorMessage(err));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <AppLayout showPageHeader={true} pageHeaderTitle='Privacy Policy'>
      {loading ? (
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            margin: '100px 0',
          }}
        >
          <Spin size='large' />
        </div>
      ) : privacyPolicy ? (
        <div
          className='container-legal'
          dangerouslySetInnerHTML={{ __html: privacyPolicy.content }}
        />
      ) : (
        false
      )}
    </AppLayout>
  );
};

export default PrivacyPolicy;
