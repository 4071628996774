import {
  Row,
  Radio,
  Typography,
  RadioChangeEvent,
  Input,
  Col,
  Badge,
} from 'antd';
import CheckableTag from 'antd/lib/tag/CheckableTag';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import useGlobalConfig from '../hooks/useGlobalConfig';
import useWindowSize from '../hooks/useWindowSize';
import AppButton from './AppButton';
import { useDebounce } from 'use-debounce';
import { CategoryProps } from '../types/category.type';

const { Title, Text } = Typography;

enum ESortBy {
  newest = 'newest',
  most_popular = 'most-popular',
}

export type IFilterMenu = {
  sortBy: ESortBy;
  minPrice: number;
  maxPrice: number;
  tags: string[];
  allergens: string[];
  selectedCategory?: CategoryProps;
};

type Props = {
  needConfirm?: boolean;
  availableCategories?: string[];
  onChangeFilter: (newFilter: IFilterMenu) => void;
};

export default function FilterMenu(props: Props) {
  let refFormInputMinPrice: any = React.useRef();
  let refFormInputMaxPrice: any = React.useRef();
  const { tags, allergens, categories } = useGlobalConfig();

  const [sortBy, setSortBy] = React.useState<ESortBy>(ESortBy.most_popular);
  const [minPrice, setMinPrice] = React.useState<undefined | number | string>();
  const [maxPrice, setMaxPrice] = React.useState<undefined | number | string>();
  const [selectedTags, setSelectedTags] = React.useState<string[]>([]);
  const [selectedAllergens, setSelectedAllergens] = React.useState<string[]>(
    []
  );
  const [selectedCategory, setSelectedCategory] =
    React.useState<CategoryProps>();

  const [valueMinPrice] = useDebounce(minPrice, 500);
  const [valueMaxPrice] = useDebounce(maxPrice, 500);

  function isExist(categoryId: string) {
    if (!props.availableCategories) {
      return false;
    }
    return props.availableCategories.find((id) => id === categoryId);
  }

  React.useEffect(() => {
    if (!props.needConfirm) {
      props.onChangeFilter({
        sortBy,
        minPrice: Number(valueMinPrice) || 0,
        maxPrice: Number(valueMaxPrice) || 0,
        tags: selectedTags,
        allergens: selectedAllergens,
        selectedCategory,
      });
    }
  }, [selectedTags, selectedAllergens, selectedCategory]);

  const handlePrice = (newPrice: any, field: 'min' | 'max') => {
    if (!newPrice) {
      if (field === 'min') {
        setMinPrice(0);
        setTimeout(() => {
          setMinPrice('');
        }, 1);
      } else if (field === 'max') {
        setMaxPrice(0);
        setTimeout(() => {
          setMaxPrice('');
        }, 1);
      }
      return;
    } else if (
      newPrice &&
      (newPrice.includes('-') || newPrice.startsWith('-') || newPrice === '-')
    ) {
      if (field === 'min') {
        setMinPrice('');
      } else if (field === 'max') {
        setMaxPrice('');
      }
      return;
    } else {
      if (newPrice < 0) {
        return;
      } else {
        if (field === 'min') {
          if (newPrice === 0) {
            setMinPrice('');
          } else {
            setMinPrice(newPrice);
          }
        } else if (field === 'max') {
          if (newPrice === 0) {
            setMaxPrice('');
          } else {
            setMaxPrice(newPrice);
          }
        }
      }
    }
  };

  return (
    <>
      {/* <Row>
        <Text
          style={{
            fontSize: '16px',
            fontWeight: 600,
            letterSpacing: '-0.02em',
            textAlign: 'left',
          }}
        >
          Sort
        </Text>
      </Row>
      <Row>
        <Radio.Group onChange={(e) => setSortBy(e.target.value)} value={sortBy}>
          <Row style={{ marginBottom: 5 }}>
            <Radio value={ESortBy.most_popular}>
              <Text
                style={{
                  fontSize: '14px',
                  fontWeight: 400,
                  letterSpacing: '-0,02em',
                  textAlign: 'left',
                }}
              >
                Most Popular
              </Text>
            </Radio>
          </Row>
          <Row>
            <Radio value={ESortBy.newest}>
              <Text
                style={{
                  fontSize: '14px',
                  fontWeight: 400,
                  letterSpacing: '-0,02em',
                  textAlign: 'left',
                }}
              >
                Newest
              </Text>
            </Radio>
          </Row>
        </Radio.Group>
      </Row> */}

      {/**  price range filter */}
      {/* <Row style={{ marginTop: 20 }}>
        <Text
          style={{
            fontSize: '16px',
            fontWeight: 600,
            letterSpacing: '-0.02em',
            textAlign: 'left',
          }}
        >
          Price Range
        </Text>
      </Row>

      <Row wrap gutter={16}>
        <Col xs={12} lg={24}>
          <Text
            style={{
              fontSize: '12px',
            }}
          >
            Min Price
          </Text>

          <InputPrice
            ref={refFormInputMinPrice}
            placeholder="Enter Price"
            type="number"
            prefix={'$'}
            // onChange={(event) => {
            //   console.info('event', event);
            //   handlePrice(event.target.value, 'min')
            // }}
            onInput={(event: any) => {
              console.info('input event', event);
              handlePrice(event.target.value, 'min');
            }}
            style={{ marginBottom: 10 }}
            min={0}
            value={minPrice}
          />
        </Col>
        <Col xs={12} lg={24}>
          <Text
            style={{
              fontSize: '12px',
            }}
          >
            Max Price
          </Text>

          <InputPrice
            ref={refFormInputMaxPrice}
            placeholder="Enter Price"
            type="number"
            prefix={'$'}
            // onChange={(event) => {
            //   console.info('event', event);
            //   handlePrice(event.target.value, 'min')
            // }}
            onInput={(event: any) => {
              console.info('input event', event);
              handlePrice(event.target.value, 'max');
            }}
            style={{ marginBottom: 10 }}
            min={0}
            value={maxPrice}
          />
        </Col>
      </Row> */}

      {/**Tags Filter */}
      <Row style={{ marginTop: 15 }}>
        <Text
          style={{
            fontSize: '20px',
            marginBottom: 10,
            fontWeight: 600,
            letterSpacing: '-0.02em',
            textAlign: 'left',
            fontFamily: '"Poppins", sans-serif',
          }}
        >
          Categories
        </Text>
      </Row>
      <div style={{ marginBottom: 40 }}>
        {categories.map((category, idx) => {
          return (
            <div
              key={idx}
              className='category-item'
              style={{
                opacity: isExist(category.categoryId) ? 1 : 0.4,
              }}
              onClick={() => {
                if (isExist(category.categoryId)) {
                  setSelectedCategory(category);
                }
              }}
            >
              <span>{category.categoryName}</span>
            </div>
          );
        })}
      </div>

      {/**Allergens Filter */}
      <Row>
        <Text
          style={{
            fontSize: '20px',
            marginBottom: 10,
            fontWeight: 700,
            letterSpacing: '-0.02em',
            textAlign: 'left',
            fontFamily: '"Poppins", sans-serif',
          }}
        >
          Dietaries
        </Text>
      </Row>
      <Row wrap={true} gutter={[8, 5]}>
        {allergens && allergens.length > 0 ? (
          allergens.map((allergen) => {
            return (
              <CheckableTag
                style={{
                  padding: '5px 13px 5px 13px',
                  marginBottom: 5,

                  borderWidth: 1,
                  borderStyle: 'solid',
                  backgroundColor: selectedAllergens.includes(
                    allergen.tagId as string
                  )
                    ? '#FFFFFF'
                    : '#F1F3F5',
                  borderColor: selectedAllergens.includes(
                    allergen.tagId as string
                  )
                    ? '#FF4A50'
                    : '#F1F3F5',
                  color: selectedAllergens.includes(allergen.tagId as string)
                    ? '#FF4A50'
                    : '#000000',
                }}
                key={allergen.tagId}
                onChange={() => {
                  if (selectedAllergens.includes(allergen.tagId as string)) {
                    setSelectedAllergens([
                      ...selectedAllergens.filter(
                        (item) => item !== allergen.tagId
                      ),
                    ]);
                  } else {
                    setSelectedAllergens([
                      ...selectedAllergens,
                      allergen.tagId as string,
                    ]);
                  }
                }}
                checked={selectedAllergens.includes(allergen.tagId as string)}
              >
                {allergen.name}
              </CheckableTag>
            );
          })
        ) : (
          <div style={{ marginLeft: 5, opacity: 0.5 }}>Data Empty</div>
        )}
      </Row>

      {props.needConfirm && (
        <AppButton block type='primary' style={{ marginTop: 20 }}>
          Confirm Filter
        </AppButton>
      )}
    </>
  );
}

const InputPrice = styled(Input)`
  background-color: #f1f3f5;
  border-radius: 100px;
  color: #b7c2cc;
  .ant-input-prefix {
    padding: 0px 3px 0px 0px;
    color: #b7c2cc;
  }
  .ant-input {
    background-color: #f1f3f5;
  }
  // :not([value=""]) {
  //   background-color: #ffffff !important;
  //   border-radius: 100px;import useGlobalConfig from '../hooks/useGlobalConfig';
  //   color: #b7c2cc;

  //   .ant-input-prefix {
  //     padding: 0px 3px 0px 0px;
  //     color: #b7c2cc;
  //   }
  //   .ant-input {
  //     background-color: #ffffff;
  //   }
  // }
`;
