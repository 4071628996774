import React from 'react';

type Props = {
  title: string;
  subtitle?: string;
  rightAction?: any;
};

const HeaderSection: React.FC<Props> = ({ title, subtitle, rightAction }) => {
  return (
    <div className='section-header'>
      <div className='section-left'>
        <h3>{title}</h3>
        {subtitle ? (
          <p style={{ marginLeft: 20, marginRight: 20, marginBottom: 30 }}>
            {subtitle}
          </p>
        ) : (
          false
        )}
      </div>
      <div className='section-right'>{rightAction}</div>
    </div>
  );
};

export default HeaderSection;
