import { Alert, Progress, Spin, Tooltip } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import moment from 'moment';
import React from 'react';
import { Minus, Plus, ShoppingBag, X } from 'react-feather';
import useCart, { CartItem } from '../hooks/useCart';
import useOrder, { EOrderType } from '../hooks/useOrder';
import { BaseResponseProps } from '../types/config.type';
import { CheckCustomerDiscount } from '../types/customer.type';
import { useHistory } from 'react-router-dom';

type CartGroup = {
  scheduleAt: string;
  orderType: EOrderType;
  cartItems: CartItem[];
};

type CartProps = {
  hideDetail?: boolean;
  modal?: boolean;
};

export interface CheckCustomerDiscountProps extends BaseResponseProps {
  payload: CheckCustomerDiscount;
}

const Cart: React.FC<CartProps> = (props) => {
  const {
    localCarts,
    cartGroup,
    removeTmpCartItem,
    removeTmpCartQtyItem,
    addTmpCartQtyItem,
    minimumOrderRule,
    transactionSubTotal,
    metaDiscountCartGroup,
    isAllowToCheckout,
    totalPay,
    loadingConstructCart,
  } = useCart();
  const { isLoadingScheduleDelivery } = useOrder();

  const history = useHistory();

  function handleRemoveItem(data: CartItem) {
    if (localCarts?.length === 1) {
      // setDeliveryFees([]);
    }
    removeTmpCartItem(data);
  }

  function handleRemoveQty(data: CartItem) {
    removeTmpCartQtyItem(data);
  }

  function handleAddQty(data: CartItem) {
    addTmpCartQtyItem(data);
  }

  console.log('Cart ::: metaDiscountCartGroup', metaDiscountCartGroup);

  return (
    <div>
      <div>
        <div style={{ fontWeight: 600, fontSize: 20, marginBottom: 10 }}>
          Your Order
        </div>

        {isLoadingScheduleDelivery ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              height: 300,
              border: '1px solid rgba(0,0,0,0.07)',
              borderRadius: 8,
            }}
          >
            <Spin />
            <div style={{ opacity: 0.3, fontSize: 16 }}>Loading data...</div>
          </div>
        ) : cartGroup.length > 0 ? (
          cartGroup.map((item, idx) => (
            <div key={idx}>
              <SingleCartItem
                hideDetail={props.hideDetail}
                key={idx}
                data={item}
                removeCartItem={(data) => handleRemoveItem(data)}
                removeQtyItem={(data) => handleRemoveQty(data)}
                addQty={(data) => handleAddQty(data)}
              />
              <CartSummaryItem
                label='Sub Total'
                value={item.subTotalCartGroup.toFixed(2)}
              />
              {item.subTotalCartGroup < minimumOrderRule && (
                <div
                  style={{
                    transform: 'translateY(-3px)',
                    fontSize: 12,
                    padding: '4px 10px',
                    background: '#fff2f0',
                    borderRadius: 4,
                    color: '#ff2323',
                  }}
                >
                  Add{' '}
                  <b style={{ fontWeight: 'bold' }}>{`$${(
                    minimumOrderRule - item.subTotalCartGroup
                  ).toFixed(2)} `}</b>
                  or more to proceed this order
                </div>
              )}
            </div>
          ))
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              height: 300,
              border: '1px solid rgba(0,0,0,0.07)',
              borderRadius: 8,
            }}
          >
            <ShoppingBag
              size={36}
              color='#000'
              style={{ marginBottom: 15, opacity: 0.2 }}
            />
            <div style={{ opacity: 0.3, fontSize: 16 }}>Cart Empty</div>
          </div>
        )}

        {!props.hideDetail && (
          <div>
            <div className='cart-summary'>
              {/* <CartSummaryItem
                label='Sub Total'
                value={transactionSubTotal.toFixed(2)}
              /> */}
              {cartGroup.length > 0 &&
                cartGroup.map((item) => (
                  <>
                    {item.isFreeDelivery ? (
                      <CartSummaryItem
                        key={item.scheduleAt}
                        label={`Delivery fee (${moment(item.scheduleAt).format(
                          'MMM DD YYYY'
                        )})`}
                        value={0}
                      />
                    ) : (
                      <CartSummaryItem
                        key={item.scheduleAt}
                        label={`Delivery fee (${moment(item.scheduleAt).format(
                          'MMM DD YYYY'
                        )})`}
                        value={Number(item.deliveryFeeCartGroup).toFixed(2)}
                      />
                    )}
                    {item.isFreeDelivery && (
                      <div
                        style={{
                          marginTop: '0px',
                          fontSize: 12,
                          padding: '4px 10px',
                          background: '#e6f7ff',
                          borderRadius: 4,
                          color: '#000',
                        }}
                      >
                        {`Congratulations, you get free delivery!`}
                      </div>
                    )}
                  </>
                ))}

              {metaDiscountCartGroup &&
              metaDiscountCartGroup.totalDiscount &&
              metaDiscountCartGroup.totalDiscount > 0 &&
              metaDiscountCartGroup.currentDiscounts &&
              metaDiscountCartGroup.currentDiscounts.length ? (
                <CartSummaryItem
                  label={
                    metaDiscountCartGroup.currentDiscounts[0].discount
                      .discountName
                  }
                  value={metaDiscountCartGroup.totalDiscount.toFixed(2)}
                  negativeValue
                />
              ) : (
                false
              )}
            </div>
          </div>
        )}
      </div>

      {!props.modal && (
        <div className='cart-total'>
          <span>Total</span>
          {loadingConstructCart ? (
            <div
              style={{
                display: 'flex',
                gap: 10,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Spin size='small' />
              <span style={{ opacity: 0.4 }}>Calculating...</span>
            </div>
          ) : (
            <span>
              $
              {history.location.pathname.includes('checkout')
                ? totalPay.toFixed(2)
                : transactionSubTotal.toFixed(2)}
            </span>
          )}
        </div>
      )}

      {!isAllowToCheckout.allow && !props.modal && (
        <div className='cart-item-container' style={{ marginTop: '15px' }}>
          <Alert
            description={`You cannot proceed this order because the order for ${isAllowToCheckout.dataGroup
              ?.map((item) => moment(item.scheduleAt).format('MMM DD YYYY'))
              .join(',')} is below $${minimumOrderRule}`}
            type='error'
            showIcon
          />
        </div>
      )}
    </div>
  );
};

type CartItemProps = {
  hideDetail?: boolean;
  data: CartGroup;
  removeCartItem: (data: CartItem) => void;
  removeQtyItem: (data: CartItem) => void;
  addQty: (data: CartItem) => void;
};

const SingleCartItem: React.FC<CartItemProps> = (props) => {
  const { localCarts, setLocalCarts, deliveryFee } = useCart();
  const { orderType } = useOrder();

  function removeMealPackItem(cartItem: CartItem) {
    const newLocalCarts = localCarts?.filter(
      (item) => item.productId !== cartItem.productId
    );
    localStorage.setItem('carts', JSON.stringify(newLocalCarts));
    setLocalCarts(newLocalCarts);
  }

  function removeCartMealPackItem(menuId: string) {
    const newLocalCarts = localCarts?.map((item) => {
      return {
        ...item,
        meta: {
          ...item.meta,
          menuItems: item.meta.menuItems
            ? item.meta.menuItems.filter(
                (menuItem) => menuItem.menuId !== menuId && menuItem
              )
            : undefined,
        },
      };
    });
    localStorage.setItem('carts', JSON.stringify(newLocalCarts));
    setLocalCarts(newLocalCarts);
  }

  function removeQtyMealPackItem(menuId: string) {
    const newLocalCarts = localCarts?.map((item) => {
      return {
        ...item,
        meta: {
          ...item.meta,
          menuItems: item.meta.menuItems
            ? item.meta.menuItems.map((menuItem) =>
                menuItem.menuId === menuId
                  ? {
                      ...menuItem,
                      qty: menuItem.qty - 1,
                    }
                  : { ...menuItem }
              )
            : undefined,
        },
      };
    });
    localStorage.setItem('carts', JSON.stringify(newLocalCarts));
    setLocalCarts(newLocalCarts);
  }

  function addQtyMealPackItem(menuId: string) {
    const newLocalCarts = localCarts?.map((item) => {
      return {
        ...item,
        meta: {
          ...item.meta,
          menuItems: item.meta.menuItems
            ? item.meta.menuItems.map((menuItem) =>
                menuItem.menuId === menuId
                  ? {
                      ...menuItem,
                      qty: menuItem.qty + 1,
                    }
                  : { ...menuItem }
              )
            : undefined,
        },
      };
    });
    localStorage.setItem('carts', JSON.stringify(newLocalCarts));
    setLocalCarts(newLocalCarts);
  }

  const isMealpackValid = (data: CartItem) => {
    let currQty = 0;
    if (data.meta && data.meta.menuItems && data.meta.menuItems.length > 0) {
      for (const menuChild of data.meta.menuItems) {
        currQty = currQty + menuChild.qty;
      }
    }

    return {
      isValid: currQty === data.quantity ? true : false,
    };
  };

  const totalCurrMenuItemQty = (menuId: string) => {
    let totalQty = 0;
    if (localCarts) {
      for (const localCart of localCarts) {
        if (
          localCart.productId === menuId &&
          localCart.meta &&
          localCart.meta.menuItems &&
          localCart.meta.menuItems.length > 0
        ) {
          totalQty = localCart.meta.menuItems.reduce(
            (acc, curr) => (acc = acc + curr.qty),
            0
          );
        }
      }
    }

    return totalQty;
  };

  return (
    <div className='cart-item-container'>
      <div className='cart-item-date' style={{ marginTop: 20 }}>
        {moment(props.data.scheduleAt).format('dddd, MMM DD YYYY')}
      </div>
      {props.data.cartItems.map((item, idx) => {
        return (
          <div key={idx}>
            <div
              className='cart-item'
              style={{
                borderBottom:
                  item.meta &&
                  item.meta.menuItems &&
                  item.meta.menuItems.length > 0
                    ? 'none'
                    : '1px solid #f1f3f5',
                padding:
                  item.meta &&
                  item.meta.menuItems &&
                  item.meta.menuItems.length > 0
                    ? '16px 0 0 0'
                    : '16px 0',
                gridTemplateColumns:
                  item.meta && !item.meta.menuItems
                    ? '50px 1fr 100px'
                    : '50px 1fr 30px',
              }}
            >
              <img
                style={{ borderRadius: 8 }}
                src={
                  item.productDetail?.images &&
                  item.productDetail?.images.length > 0
                    ? item.productDetail?.images[0]!.imageUrl!
                    : '/images/select-image.jpg'
                }
              />

              <div className='menu-info'>
                <Paragraph
                  ellipsis={{
                    rows: 2,
                  }}
                  style={{ marginBottom: 5, fontSize: 14 }}
                >
                  {item.productDetail?.name}
                </Paragraph>

                {item.meta &&
                  item.meta.menuItems &&
                  item.meta.menuItems.length > 0 && (
                    <>
                      <span style={{ fontSize: 12 }}>
                        Max qty. ({item.quantity})
                      </span>
                      <span
                        style={{ fontSize: 12, margin: '0 5px', opacity: 0.3 }}
                      >
                        •
                      </span>
                    </>
                  )}

                {item.productDetail?.variant.label && (
                  <>
                    <span style={{ fontSize: 12 }}>
                      {item.productDetail?.variant.label}
                    </span>
                    <span
                      style={{ fontSize: 12, margin: '0 5px', opacity: 0.3 }}
                    >
                      •
                    </span>
                  </>
                )}

                <span style={{ fontSize: 14 }}>
                  $
                  {item.meta &&
                  item.meta.menuItems &&
                  item.meta.menuItems.length > 0
                    ? item.productDetail?.variant.price!.toFixed(2)
                    : (
                        item.productDetail?.variant.price! * item.quantity
                      ).toFixed(2)}
                </span>
              </div>

              {item.meta && !item.meta.menuItems ? (
                <div className='counter-group'>
                  {item.quantity === 1 ? (
                    <button
                      onClick={() => props.removeCartItem(item)}
                      className='counter-item'
                    >
                      <X />
                    </button>
                  ) : (
                    <button
                      onClick={() => props.removeQtyItem(item)}
                      className='counter-item'
                    >
                      <Minus />
                    </button>
                  )}
                  <input value={item.quantity} />
                  <button
                    onClick={() => props.addQty(item)}
                    className='counter-item'
                  >
                    <Plus />
                  </button>
                </div>
              ) : (
                <button
                  onClick={() => removeMealPackItem(item)}
                  className='counter-item'
                >
                  <X />
                </button>
              )}
            </div>

            {item.meta &&
              item.meta?.menuItems &&
              item.meta.menuItems.length > 0 && (
                <div
                  style={{
                    borderBottom: '1px solid #f1f3f5',
                    paddingBottom: 16,
                  }}
                >
                  {item.meta.menuItems.map((menu) => (
                    <div
                      key={menu.menuId}
                      style={{
                        marginLeft: 60,
                        marginTop: 10,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          fontSize: 12,
                        }}
                      >
                        {menu.menuName}
                      </div>
                      <div className='meal-pack-counter-group'>
                        {menu.qty === 1 ? (
                          <button
                            title='Remove this item'
                            onClick={() => removeCartMealPackItem(menu.menuId)}
                            className='counter-item'
                          >
                            <X />
                          </button>
                        ) : (
                          <button
                            onClick={() => removeQtyMealPackItem(menu.menuId)}
                            className='counter-item'
                          >
                            <Minus />
                          </button>
                        )}
                        <input value={menu.qty} />
                        <button
                          disabled={isMealpackValid(item).isValid}
                          onClick={() => addQtyMealPackItem(menu.menuId)}
                          className={
                            isMealpackValid(item).isValid
                              ? 'counter-item counter-item-disabled'
                              : 'counter-item'
                          }
                        >
                          <Plus
                            style={{
                              opacity: !isMealpackValid(item).isValid ? 1 : 0.4,
                            }}
                          />
                        </button>
                      </div>
                    </div>
                  ))}

                  {!isMealpackValid(item).isValid && (
                    <Alert
                      showIcon
                      style={{ marginTop: 10 }}
                      type='error'
                      message={`You need to add ${
                        item.quantity - totalCurrMenuItemQty(item.productId)
                      } more menu or portion to continue with ${
                        item.productDetail?.name
                      }`}
                    />
                  )}
                </div>
              )}
          </div>
        );
      })}

      {/* {!props.hideDetail && orderType === EOrderType.delivery && (
        <div style={{ marginBottom: 15 }}>
          <CartSummaryItem
            label={`Delivery Fee`}
            value={deliveryFee(props.data.scheduleAt)}
          />
        </div>
      )} */}
    </div>
  );
};

type CartSummaryType = {
  label: string;
  value: number | string;
  negativeValue?: boolean;
  style?: any;
};

const CartSummaryItem: React.FC<CartSummaryType> = (props) => {
  return (
    <div className='cart-summary-item' style={props.style}>
      <div>{props.label}</div>
      <div className={props.negativeValue ? 'value value-negative' : 'value'}>
        {props.negativeValue ? '-' : false}${props.value}
      </div>
    </div>
  );
};

export default Cart;
