import { EOrderType } from "../hooks/useOrder";
import { CategoryProps } from "./category.type";
import { ISchedule } from "./schedules.type";
import { ITag } from "./tag.type";

export interface IVariant {
  menuVariantId: string;
  menuId: string;
  price: number;
  label: string;
  isDefaultPrice: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export interface IMenu {
  menuId: string;
  name: string;
  description: string;
  isPublished: boolean;
  isTopPick: boolean;
  images: Array<{
    fileId: string;
    imageUrl: string | null;
  }>;
  variants: IVariant[];
  tags?: ITag[];
  allergens?: ITag[];
  updatedAt?: Date | string;
  createdAt?: Date | string;
  statusLoading?: boolean;
  category?: CategoryProps;
  categoryId?: string;
  itemIds?: string[];
  maxQty?: number;
  price?: number;
  bestSeller?: boolean;
  isSelected?: boolean;
  items?: IMenu[];
  schedules?: ISchedule[];
  currQty?: number;
  isHide?: boolean;
  isAvailable: boolean;
}

export interface FetchAllMenuResponse {
  payload: {
    results: IMenu[];
  };
}

export const initialVariant: IVariant = {
  price: 0,
  label: "Default",
  isDefaultPrice: true,
  menuVariantId: "",
  menuId: "",
};

export const initialMenu: IMenu = {
  menuId: "",
  name: "",
  description: "",
  isPublished: false,
  isTopPick: false,
  isAvailable: true,
  images: [
    {
      fileId: "",
      imageUrl: "",
    },
  ],
  variants: [initialVariant],
  tags: [],
  updatedAt: "",
  createdAt: "",
  statusLoading: false,
};
