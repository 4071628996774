import React from "react";

type FormInputCustomType = {
  label: string;
  required?: boolean;
};

const FormInputCustom: React.FC<FormInputCustomType> = ({
  children,
  ...props
}) => {
  return (
    <div className='custom-form-input'>
      <div className='form-label'>
        {props.label}{" "}
        <span style={{ color: "red !important" }}>{props.required && "*"}</span>
      </div>
      {children}
    </div>
  );
};

export default FormInputCustom;
