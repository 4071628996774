import { Alert, Button, message, Modal, Spin } from "antd";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useOrder, { EOrderType } from "../hooks/useOrder";
import { ILocation } from "../types/location.type";
import AppButton from "./AppButton";
import ContentCheckDeliveryCoverage from "./ContentCheckDeliveryCoverage";
import ContentChoosePickupLocation from "./ContentChoosePickupLocation";

const ModalSelectOrderType: React.FC = (props) => {
  const {
    isShowModalOrderType,
    tmpOrderType,
    orderType,
    currSelectedLocation,
    schedules,
    scheduleDeliveries,
    isLoadingSchedules,
    isLoadingScheduleDelivery,
    setOrderType,
    setIsShowModalOrderType,
    setTmpOrderType,
    setCurrSelectedLocation,
    setUnavailableSchedule,
  } = useOrder();

  const history = useHistory();
  const [isCovered, setCovered] = useState<boolean>(false);
  const [tmpSelectLocation, setTmpSelectLocation] = useState<ILocation>();

  const handleTmpOrderType = (type: EOrderType) => {
    setTmpOrderType(type);
    // if (type === EOrderType.pickup) {
    //   setOrderType(type);
    //   setIsShowModalOrderType(false);
    // }
  };

  const handleSetOrderType = () => {
    if (tmpOrderType === EOrderType.pickup) {
      setCurrSelectedLocation(tmpSelectLocation);
      localStorage.setItem("location", JSON.stringify(tmpSelectLocation));
      localStorage.removeItem("unavailableSchedule");
      setUnavailableSchedule([]);
    }
    // if (currSelectedLocation) {
    //   localStorage.removeItem("location");
    // }
    localStorage.setItem("orderType", JSON.stringify(tmpOrderType));
    setOrderType(tmpOrderType);
    setIsShowModalOrderType(false);
  };

  const handleCancel = () => {
    handleCloseModal()
    // if (tmpOrderType === EOrderType.delivery && !isCovered) {
    //   setTmpOrderType(undefined);
    // } else {
    //   setIsShowModalOrderType(false);
    // }
  };

  const backToHomepage = () => {
    history.replace("/");
  };

  const handleCloseModal = () => {
    console.log("orderType", orderType);
    if (schedules.length === 0 && scheduleDeliveries.length === 0) {
      backToHomepage();
      setIsShowModalOrderType(false);
    } else {
      setIsShowModalOrderType(false);
    }
  };

  useEffect(() => {
    if (currSelectedLocation) {
      setTmpSelectLocation(currSelectedLocation);
    }
  }, [currSelectedLocation]);

  useEffect(() => {
    if (orderType) {
      setTmpOrderType(orderType);
    }
  }, [orderType]);

  return (
    <Modal
      visible={isShowModalOrderType}
      footer={
        tmpOrderType ? (
          <>
            <AppButton type='default' onClick={() => handleCancel()}>
              Cancel
            </AppButton>
            <AppButton
              type='primary'
              disabled={
                tmpOrderType === EOrderType.delivery
                  ? !isCovered
                  : !tmpSelectLocation
              }
              onClick={() => handleSetOrderType()}
            >
              Confim{" "}
              {tmpOrderType === EOrderType.delivery
                ? "Order Type"
                : "Pickup Location"}
            </AppButton>
          </>
        ) : null
      }
      maskClosable={false}
      closable={true}
      closeIcon={false}
      onCancel={() => {
        handleCloseModal()}
      }
      width={600}
    >
      {isLoadingSchedules || isLoadingScheduleDelivery ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            height: 300,
          }}
        >
          <Spin />
          <span style={{ marginTop: 10 }}>Loading data...</span>
        </div>
      ) : !tmpOrderType ? (
        <ContentSelectOrderType
          handleSelectOrderType={(e) => handleTmpOrderType(e)}
          disabledDelivery={
            scheduleDeliveries.length === 0 ||
            isLoadingScheduleDelivery ||
            isLoadingSchedules
          }
          disabledPickup={
            schedules.length === 0 ||
            isLoadingScheduleDelivery ||
            isLoadingSchedules
          }
        />
      ) : tmpOrderType === EOrderType.delivery ? (
        <ContentCheckDeliveryCoverage
          isCovered={isCovered}
          isContent={!orderType}
          handleIsCovered={(e) => setCovered(e)}
          onClickBtnContent={() => setTmpOrderType(undefined)}
        />
      ) : tmpOrderType === EOrderType.pickup ? (
        <ContentChoosePickupLocation
          isContent={!orderType}
          tmpSelectLocation={tmpSelectLocation}
          onSelectLocation={(loc) => setTmpSelectLocation(loc)}
          onClickBtnContent={() => {
            setTmpOrderType(undefined);
            setCurrSelectedLocation(undefined);
          }}
        />
      ) : (
        false
      )}

      {isLoadingSchedules || isLoadingScheduleDelivery || tmpOrderType ? (
        <div />
      ) : schedules.length === 0 && scheduleDeliveries.length === 0 ? (
        <Alert
          style={{ marginTop: 15 }}
          message="Sorry We didn't have schedule for delivery or pickup"
          type='warning'
          showIcon
          // action={
          //   <AppButton onClick={backToHomepage} type='primary'>
          //     Back to homepage
          //   </AppButton>
          // }
        />
      ) : schedules.length === 0 ? (
        <Alert
          style={{ marginTop: 15 }}
          message="Sorry We didn't have schedule for pickup"
          type='warning'
          showIcon
          // action={
          //   <AppButton onClick={backToHomepage} type='primary'>
          //     Back to homepage
          //   </AppButton>
          // }
        />
      ) : scheduleDeliveries.length === 0 ? (
        <Alert
          style={{ marginTop: 15 }}
          message="Sorry We didn't have schedule for delivery"
          type='warning'
          showIcon
          // action={
          //   <AppButton onClick={backToHomepage} type='primary'>
          //     Back to homepage
          //   </AppButton>
          // }
        />
      ) : (
        false
      )}
    </Modal>
  );
};

interface PropsContent {
  handleSelectOrderType: (e: EOrderType) => void;
  disabledPickup: boolean;
  disabledDelivery: boolean;
}

const ContentSelectOrderType: React.FC<PropsContent> = (props) => {
  return (
    <>
      <div style={{ marginBottom: 40 }}>
        <div className='modal-title'>Choose your order type</div>
        <div className='modal-subtitle'>
          Choose order type wether you want to pickup or deliver your meals
        </div>
      </div>

      <div className='grid-order'>
        <div
          onClick={() =>
            !props.disabledPickup &&
            props.handleSelectOrderType(EOrderType.pickup)
          }
          className={`grid-order-item ${
            props.disabledPickup ? "grid-order-item-disabled" : ""
          }`}
        >
          <img
            width={60}
            src={
              !props.disabledPickup
                ? "/images/ep_food.png"
                : "/images/ep_food-disabled.svg"
            }
          />
          <div className='modal-title'>Pickup</div>
          <div style={{ fontSize: 12, textAlign: "center" }}>
            Collect your meals at any one of our pickup locations.
          </div>
        </div>
        <div
          onClick={() =>
            !props.disabledDelivery &&
            props.handleSelectOrderType(EOrderType.delivery)
          }
          className={`grid-order-item ${
            props.disabledDelivery ? "grid-order-item-disabled" : ""
          }`}
        >
          <img
            width={60}
            src={
              !props.disabledDelivery
                ? "/images/cil_truck.png"
                : "/images/cil_truck-disabled.svg"
            }
          />
          <div className='modal-title'>Delivery</div>
          <div style={{ fontSize: 12, textAlign: "center" }}>
            Let us deliver your meals to your doorstep.
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalSelectOrderType;
