import { Footer } from 'antd/lib/layout/layout';
import { ArrowRight } from 'react-feather';
import { Link, useHistory } from 'react-router-dom';
import useGlobalConfig from '../hooks/useGlobalConfig';
import useWindowSize from '../hooks/useWindowSize';
import { EnvironmentOutlined, PhoneOutlined } from '@ant-design/icons';

export default function AppFooter() {
  const { aboutUs } = useGlobalConfig();

  const history = useHistory();
  const { width } = useWindowSize();

  return (
    <Footer className='footer-container'>
      <div className='footer-wrapper'>
        {/* ©2018 {process.env.REACT_APP_WEBSITE_NAME} */}
        <div className='footer-content'>
          <div className='footer-section'>
            <img
              className='footer-logo'
              alt='logo-spoonful'
              src={'/images/logo-spoonful.svg'}
              height={45}
              style={{ maxHeight: 45, maxWidth: 200 }}
            />
            <h2>{aboutUs.officeTitle}</h2>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <EnvironmentOutlined style={{ color: 'gray', fontSize: 14 }} />
              <p style={{ marginLeft: 10 }}>{aboutUs.officeAddress}</p>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <PhoneOutlined style={{ color: 'gray', fontSize: 14 }} />
              <p style={{ marginLeft: 10 }}>{aboutUs.contact}</p>
            </div>

            {width > 799 && (
              <div className='footer-social-media'>
                <a target='_blank' href={aboutUs.instagramLink}>
                  <img alt='instagram' src='images/instagram.svg' />
                </a>
                <a target='_blank' href={aboutUs.twitterLink}>
                  <img alt='twitter' src='images/twitter.svg' />
                </a>
                <a target='_blank' href={aboutUs.facebookLink}>
                  <img alt='facebook' src='images/facebook.svg' />
                </a>
              </div>
            )}
          </div>

          <div className='footer-section'>
            <h2>About</h2>
            <Link to='/#how-it-works'>How it works</Link>
            <Link to='/menu'>Order Now</Link>
            <Link to='/contact-us'>Contact Us</Link>
          </div>

          <div className='footer-section'>
            <h2>Support</h2>
            <Link to='/faqs'>FAQs</Link>
            <Link to='/privacy-policy'>Privacy Policy</Link>
            <Link to='/terms-and-conditions'>Terms & Conditions</Link>
          </div>

          <div className='footer-section'>
            <h2>Get in Touch</h2>
            <p style={{ lineHeight: '200%', marginBottom: 16 }}>
              Question or Feedback? We`d love to hear from you
            </p>
            <button
              className='btn btn-black'
              onClick={() => {
                history.push('/contact-us');
              }}
            >
              <span style={{ marginRight: 10 }}>Send Us A Message</span>
              <ArrowRight size={18} color='#FFF' />
            </button>
            <div
              style={
                width <= 799
                  ? {
                      marginTop: 40,
                      textAlign: 'center',
                      opacity: 0.6,
                      fontSize: 12,
                    }
                  : { marginTop: 40, opacity: 0.6, fontSize: 12 }
              }
            >
              Version app {process.env.REACT_APP_VERSION_NAME}
            </div>
          </div>

          {width <= 799 && (
            <div className='footer-social-media-wrap'>
              {!!aboutUs.instagramLink && (
                <a target='_blank' href={aboutUs.instagramLink}>
                  <img alt='instagram' src='images/instagram.svg' />
                </a>
              )}
              {!!aboutUs.twitterLink && (
                <a target='_blank' href={aboutUs.twitterLink}>
                  <img alt='twitter' src='images/twitter.svg' />
                </a>
              )}
              {!!aboutUs.facebookLink && (
                <a target='_blank' href={aboutUs.facebookLink}>
                  <img alt='twitter' src='images/facebook.svg' />
                </a>
              )}
              {/* {!!aboutUs.youtubeLink &&
              <a target="_blank" href={aboutUs.youtubeLink}>
                <img alt="youtube" src="images/youtube.svg" />
              </a>} */}
            </div>
          )}
        </div>
      </div>
    </Footer>
  );
}
