import moment from 'moment';
import React, { useEffect } from 'react';
import { createGlobalState } from 'react-hooks-global-state';
import { ILocation, initialAvailability, initialLocation, IAvailability } from '../types/location.type';
import useGlobalConfig from './useGlobalConfig';

type State = {
  isShowModalSelectLocation: boolean;
  selectedLocation: ILocation;
  selectedGlobalDate: Date;
  selectedAvailability: IAvailability

  isShowModalBanner: boolean;

  isShowModalSelectPickupTime: boolean;
  // selectedPickupTime: IAvailability;
};

const initialState: State = {
  isShowModalSelectLocation: false,
  isShowModalBanner: false,
  selectedLocation: { ...initialLocation },
  selectedGlobalDate: new Date(),
  selectedAvailability: { ...initialAvailability },

  isShowModalSelectPickupTime: false,
  // selectedPickupTime: { ...initialAvailability }
};
const { useGlobalState } = createGlobalState(initialState);

export default function useGlobalFilter() {
  const [isShowModalSelectLocation, setIsShowModalSelectLocation] =
    useGlobalState('isShowModalSelectLocation');
  const [selectedLocation, setSelectedLocation] =
    useGlobalState('selectedLocation');
  const [selectedGlobalDate, setSelectedGlobalDate] =
    useGlobalState('selectedGlobalDate');
  const [selectedAvailability, setSelectedAvailability] =
    useGlobalState('selectedAvailability');
  const [isShowModalBanner, setIsShowModalBanner] =
    useGlobalState('isShowModalBanner');

  const [isShowModalSelectPickupTime, setIsShowModalSelectPickupTime] =
    useGlobalState('isShowModalSelectPickupTime');
  // const [selectedPickupTime, setSelectedPickupTime] =
  //   useGlobalState('selectedPickupTime');

  return {
    isShowModalSelectLocation,
    selectedLocation,
    selectedGlobalDate,
    selectedAvailability,

    setSelectedLocation,
    setSelectedGlobalDate,
    setSelectedAvailability,

    setIsShowModalSelectLocation,

    isShowModalBanner,
    setIsShowModalBanner,

    isShowModalSelectPickupTime,
    // selectedPickupTime,
    // setSelectedPickupTime,
    setIsShowModalSelectPickupTime,
  };
}
