import { Alert, Form, Input, Modal, Spin } from "antd";
import Title from "antd/lib/typography/Title";
import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce/lib";
import useOrder, { EOrderType } from "../hooks/useOrder";
import AppButton from "./AppButton";
import ContentCheckDeliveryCoverage from "./ContentCheckDeliveryCoverage";

const ModalCheckDeliveryCoverage: React.FC = () => {
  const {
    isShowModalCheckDeliveryCoverage,
    isLoadingScheduleDelivery,
    setIsShowModalCheckDeliveryCoverage,
  } = useOrder();

  const [isCovered, setCovered] = useState<boolean>(false);

  const handleConfirmDeliveryCoverage = () => {
    setIsShowModalCheckDeliveryCoverage(false);
  };

  return (
    <Modal
      visible={isShowModalCheckDeliveryCoverage}
      width={700}
      onOk={() => handleConfirmDeliveryCoverage()}
      okText='Confirm Order Type'
      okButtonProps={{
        disabled: !isCovered || isLoadingScheduleDelivery,
      }}
      onCancel={() => setIsShowModalCheckDeliveryCoverage(false)}
    >
      <ContentCheckDeliveryCoverage
        isCovered={isCovered}
        handleIsCovered={(e) => setCovered(e)}
      />
    </Modal>
  );
};

export default ModalCheckDeliveryCoverage;
