import { Col, Divider, Modal, Row } from 'antd';
import AppButton from './AppButton';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import IconTudung from './icons/iconTudung';
import { useHistory } from 'react-router-dom';
import useOrder, { EOrderType } from '../hooks/useOrder';

type Props = {
  NavigateURL?: string;
  email?: string;
  locationName?: string;
  locationAddress?: string;
  locationContact?: string;
  totalPrice: number | string;
  paymentGroupedId: string;
  visible: boolean;
  onClose: () => void;
};
export default function ModalOrderCreated(props: Props) {
  const history = useHistory();
  const { checkoutAsGuest, orderType } = useOrder();
  return (
    <Modal
      visible={props.visible}
      footer={null}
      onCancel={() => {
        props.onClose();
      }}
    >
      <Row justify='center'>
        <Col
          style={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              background: '#FF4A50',
              width: 60,
              height: 60,
              borderRadius: 10,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 20,
            }}
          >
            <IconTudung size={40} />
          </div>
          <Title style={{ marginBottom: 15 }} level={3}>
            Order Created
          </Title>
          <Text>
            Your order has been created successfully.
            <br />
            {/* Pay your order before the{' '}
            {orderType === EOrderType.pickup
              ? 'pickup'
              : orderType === EOrderType.delivery
              ? 'delivery'
              : ''}{' '}
            pickup schedule. */}
          </Text>
          {checkoutAsGuest && (
            <Text>
              For payment information detail, please check email that you used
              for checkout
            </Text>
          )}
          {/* <Text>
            We will send payment information via email to{' '}
            <a
              target="_blank"
              href={'mailto:' + props.email}
              style={{ color: '#FF4A50' }}
              rel="noreferrer"
            >
              “{props.email}”
            </a>
            , please check your email to process the payment.
          </Text> */}
        </Col>
      </Row>

      <Divider />

      <Row justify='space-between' style={{ marginBottom: 10 }}>
        <Col>
          <Text>Payment ID:</Text>
        </Col>
        <Col>
          <Text strong>{props.paymentGroupedId}</Text>
        </Col>
      </Row>

      <Row justify='space-between'>
        <Col>
          <Text>Total Pay:</Text>
        </Col>
        <Col>
          <Text strong>${props.totalPrice}</Text>
        </Col>
      </Row>

      {/* <Row
        style={{
          display: "block",
          width: "100%",
          paddingTop: 15,
          paddingBottom: 15,
        }}
      >
        <Col>
          <div style={{ marginBottom: 5 }}>
            For further question please contact us at
          </div>
          <div>
            <Text style={{ fontWeight: "bold" }}>{props.locationName}</Text>
          </div>
          <div>
            <Text>{props.locationAddress}</Text>
          </div>
          <div>
            <Text>{props.locationContact}</Text>
          </div>
        </Col>
      </Row> */}

      <Row gutter={[16, 16]} style={{ marginTop: 15 }}>
        {!checkoutAsGuest && (
          <Col xs={24}>
            <AppButton
              onClick={() => {
                props.onClose();
                history.push('/orders');
              }}
              block
              size='large'
              type='primary'
              htmlType='submit'
              style={{ background: '#000' }}
            >
              Check My Order List
            </AppButton>
          </Col>
        )}
        {/* <Col xs={checkoutAsGuest ? 24 : 12}>
          <AppButton
            onClick={() => {
              // history.push('/orders');
              window.open(props.NavigateURL);
            }}
            block
            size='large'
            type='primary'
            htmlType='submit'
          >
            Pay Now
          </AppButton>
        </Col> */}
      </Row>

      <div style={{ textAlign: 'center', width: '100%', marginTop: 10 }}>
        If already pay you can ignore this message.
      </div>
    </Modal>
  );
}
