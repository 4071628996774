import { useHistory } from 'react-router-dom';
import Payment from '../components/Payment';
import useOrder from '../hooks/useOrder';
import AppLayout from '../layout/AppLayout';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Spin } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import AppButton from '../components/AppButton';

const CheckoutPayment = () => {
  const { setDataForOrderCreatedModal, setIsShowModalOrderCreated } =
    useOrder();
  const history = useHistory();
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  function onHandlePaymentSuccess(groupedId: string) {
    setDataForOrderCreatedModal({
      totalPrice: data.result.finalPrice,
      paymentGroupedId: data.result.groupedId,
    });

    setIsShowModalOrderCreated(true);
    history.replace('/#home');
  }

  useEffect(() => {
    const fetchDataTransaction = async () => {
      const searchParams = new URLSearchParams(document.location.search);
      const gId = searchParams.get('gId');
      const awxId = searchParams.get('awxId');
      const trxId = searchParams.get('trxId');

      try {
        setLoading(true);
        const res: any = await axios.request({
          url: `${process.env.REACT_APP_BASE_URL}/transactions/detail/data-payment`,
          method: 'get',
          params: {
            gId,
            awxId,
            trxId,
          },
        });

        if (res && res.data && res.data.payload) {
          setData(res.data.payload);
        }
      } catch (error) {
        console.error('failed get data transaction', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDataTransaction();
  }, [history]);

  return (
    <AppLayout>
      <div className='homepage-section' style={{ padding: '20px 0' }}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {loading ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: 400,
              }}
            >
              <Spin size='large' />
            </div>
          ) : data && data.airwallexResponse.status !== 'SUCCEEDED' ? (
            <Payment
              id={data.airwallexResponse.id}
              client_secret={data.airwallexResponse.client_secret}
              currency={data.airwallexResponse.currency}
              groupedId={data.result.groupedId}
              handlePaymentSuccess={onHandlePaymentSuccess}
            />
          ) : data ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                border: '1px solid lightgray',
                borderRadius: 10,
                padding: 30,
              }}
            >
              <CheckCircleFilled
                style={{
                  fontSize: 60,
                  color: 'green',
                  marginBottom: 30,
                  marginTop: 10,
                }}
              />
              <h3 style={{ fontWeight: 'bold' }}>Payment Success</h3>
              <p>
                Congratulations! Your payment has been successfully processed.
                Thank you for your purchase!
              </p>

              <AppButton
                onClick={() => history.push('menu')}
                size='large'
                type='primary'
                style={{ width: 200 }}
              >
                Explore our menu
              </AppButton>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                border: '1px solid lightgray',
                borderRadius: 10,
                padding: 30,
              }}
            >
              <p>Payment data not found</p>
            </div>
          )}
        </div>
      </div>
    </AppLayout>
  );
};

export default CheckoutPayment;
