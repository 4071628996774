import { Redirect, Route } from "react-router";
import useAuth from "../hooks/useAuth";

export interface IProps {
  exact?: boolean;
  path: string;
  component?: React.ComponentType<any>;
  restricted?: boolean;
}

const PublicRoute: React.FC<IProps> = ({ children, restricted, ...rest }) => {
  return <Route {...rest} render={() => children} />;
};

export default PublicRoute;
