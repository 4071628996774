import {
  CloseCircleOutlined,
  DownOutlined,
  EnvironmentOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  Carousel,
  Col,
  Divider,
  Modal,
  Row,
  Select,
  Tag,
  Image,
  Typography,
  Dropdown,
  Menu,
  message,
  Alert,
  Button,
} from "antd";
import React from "react";
import { ChevronDown, ChevronLeft, ChevronRight } from "react-feather";
import { settings } from "../screens/menus";
import { IMenu, IVariant } from "../types/menu.type";
import AppButton from "./AppButton";
import IconTudung from "./icons/iconTudung";
import useGlobalConfig from "../hooks/useGlobalConfig";
import { IAvailability, ILocation } from "../types/location.type";
import moment from "moment";
import { SHORT_DAYS_IN_WEEK } from "../helpers/constant";
import ModalSelectPickupTime from "./ModalSelectPickupTime";
import { calculateAddDayForFirstOrder } from "../helpers/order";
import Title from "antd/lib/typography/Title";
import useWindowSize from "../hooks/useWindowSize";
import { BottomSheet, BottomSheetRef } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import useGlobalFilter from "../hooks/useGlobalFilter";
import { ISchedule } from "../types/schedules.type";
import { generateQueryString } from "../helpers/generateQueryString";
import { httpRequest } from "../helpers/api";
import { IHttpResponse, IPayloadPagination } from "../helpers/pagination";
import ComingSoon from "./ComingSoon";
import Paragraph from "antd/lib/typography/Paragraph";
import { convertHtmlToText } from "../helpers/text";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

const isComingSoon = false;

type CarouselDate = {
  identifierIndex: number;
  date: Date;
  isAvailable: boolean;
  isSelected: boolean;
  availabilityDate?: IAvailability;
};
const currentDate = moment().startOf("day");

const { Text } = Typography;
const { Option } = Select;

export type AddToCartItem = {
  location?: ILocation;
  availabilityPickupTime?: IAvailability;
  date?: string;

  menuId: string;
  menuVariantId: string;
  qty: number;
};

type Props = {
  page?: "top-pick" | "menu";
  onCancel: () => void;
  data: IMenu;
  onAddToCart?: (item: AddToCartItem) => void;
  onAddMultipleToCart?: (items: AddToCartItem[]) => void;
};
export default function ModalMenuDetail(props: Props) {
  const history = useHistory();
  const sheetRef: any = React.useRef<BottomSheetRef>();
  const carouselRef = React.useRef<any>(null);
  const { isMobile } = useWindowSize();
  const { locations } = useGlobalConfig();
  const {
    selectedLocation,
    setSelectedLocation,
    setIsShowModalSelectPickupTime,
  } = useGlobalFilter();
  const [itemAmount, setItemAmount] = React.useState(1);
  const [isActive, setIsActive] = React.useState<boolean>(false)
  const [ellipsis, setEllipsis] = React.useState<number| undefined>(5)

  const [selectedVariant, setSelectedVariant] = React.useState<IVariant>(
    props.data.variants[0]
  );
  const [tmpSelectedDate, setTmpSelectedDate] = React.useState<CarouselDate>();
  const [
    tmpSelectedAvailabilityPickupTime,
    setTmpSelectedAvailabilityPickupTime,
  ] = React.useState<IAvailability>();
  // const [availableSchedule, setAvailableSchedule] = React.useState<Array<string>>()

  const addDay = calculateAddDayForFirstOrder();
  const [listOfDates, setListOfDates] = React.useState<Array<CarouselDate>>(
    Array.from(
      [
        0, 1, 2, 3, 4, 5, 6,
        // 7, 8, 9, 10, 11, 12
      ],
      (i) => {
        const date = moment(currentDate).add(i + addDay, "days");
        return {
          identifierIndex: i,
          date: date.toDate(),
          isAvailable: false,
          isSelected: false,
          availabilityDate: date.isSame(currentDate)
            ? tmpSelectedAvailabilityPickupTime
            : undefined,
        };
      }
    )
  );

  let totalPrice = (selectedVariant?.price || 0) * itemAmount;

  if (props.page === "top-pick") {
    totalPrice =
      totalPrice * listOfDates.filter((item) => item.isSelected).length;
  }

  const fetchSchedule = async () => {
    try {
      const res = await httpRequest.get<
        IHttpResponse<IPayloadPagination<ISchedule>>
      >(
        `/schedules${generateQueryString({
          dateFrom: moment(currentDate)
            .add(1 + addDay, "days")
            .toISOString(),
          dateUntil: moment(currentDate)
            .add(6 + addDay, "days")
            .toISOString(),
          menuId: props.data.menuId,
          locationId: selectedLocation.locationId,
        })}`
      );

      const scheduleDates = listOfDates.map((date) => {
        return {
          ...date,
          isAvailable: res.data.payload.results.find(
            (item) =>
              item.scheduleAt ===
              moment(date.date).format("YYYY-MM-DD").toString()
          )
            ? true
            : false,
        };
      });
      setListOfDates(scheduleDates);
    } catch (err) {
      console.log(err);
    }
  };

  const handleMenuOnChangeLocation = (e: any) => {
    if (e.key !== selectedLocation?.locationId) {
      let list = [...listOfDates];
      list = list.map((item) => {
        return {
          ...item,
          isSelected: false,
          availabilityDate: undefined,
        };
      });
      setTmpSelectedDate(undefined);
      setTmpSelectedAvailabilityPickupTime(undefined);
      setListOfDates(list);
      fetchSchedule();
    }
    const findLoc = locations.find((loc) => loc.locationId === e.key);
    if (findLoc) {
      setSelectedLocation(findLoc);
      setTmpSelectedAvailabilityPickupTime(undefined);
    } else {
      message.error(`Location doesn't exist`);
    }
  };

  const ContentDropdownAvailabilityPickupLocation = (
    <Menu onClick={handleMenuOnChangeLocation}>
      {locations.map((loc) => (
        <Menu.Item key={loc.locationId} icon={<EnvironmentOutlined />}>
          <div>
            <Text style={{ fontWeight: "bold" }}>{loc.locationName}</Text>
          </div>
          <div>{loc.locationAddress}</div>
        </Menu.Item>
      ))}
    </Menu>
  );

  const _renderDetailContent = () => (
    <>
      <Row style={{ marginBottom: 10, position: "relative" }}>
        {props.data.images.length > 1 && (
          <div
            onClick={() => carouselRef.current.prev()}
            style={{
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 30,
              cursor: "pointer",
              left: 10,
              top: "45%",
              zIndex: 10,
              background: "rgba(0,0,0,0.4)",
              opacity: 1,
              width: 40,
              height: 40,
            }}
          >
            <ChevronLeft style={{ color: "#FFFFFF", opacity: 1 }} size={24} />
          </div>
        )}
        <Col span={24} style={{ position: "relative" }}>
          <Carousel
            ref={carouselRef}
            // {...settings}
            autoplay
            dots={false}
            style={{ borderRadius: 20 }}
          >
            {props.data?.images.map((item, idx) => {
              return (
                <Image
                  key={idx}
                  preview={false}
                  height={280}
                  width='100%'
                  src={item.imageUrl || "/images/select-image.jpg"}
                  fallback={"/images/blur-image.jpeg"}
                  wrapperStyle={{ borderRadius: 20 }}
                />
              );
            })}
          </Carousel>
        </Col>
        {props.data.images.length > 1 && (
          <div
            onClick={() => carouselRef.current.next()}
            style={{
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 30,
              cursor: "pointer",
              right: 10,
              top: "45%",
              zIndex: 10,
              background: "rgba(0,0,0,0.4)",
              opacity: 1,
              width: 40,
              height: 40,
            }}
          >
            <ChevronRight style={{ color: "#FFFFFF", opacity: 1 }} size={24} />
          </div>
        )}
      </Row>
      <Row>
        <Title
          level={3}
          style={{
            marginTop: 10,
            marginBottom: 10,
            fontFamily: "'Poppins', sans-serif",
          }}
        >
          {props.data.name}
        </Title>
      </Row>

      <Row style={{ marginTop: 5 }}>
        <Paragraph
          style={{
            fontSize: 14,
            // color: "#1D2B36",
            fontWeight: 400,
          }}
          ellipsis={{
            rows: ellipsis,
            onEllipsis: (e) => {setIsActive(e)}
          }}
        >
          {convertHtmlToText(props.data.description)}
        </Paragraph>
        {isActive? ellipsis===3? <ButtonPrimary style={{textDecoration: 'underline', color: 'black', padding: 0, margin: 0}} onClick={()=> setEllipsis(undefined)}>Read More</ButtonPrimary> : <ButtonPrimary style={{textDecoration: 'underline', color: 'black', padding: 0, margin: 0}} onClick={()=> setEllipsis(3)}>Show Less</ButtonPrimary> : null}
      </Row>

      <Row style={{ marginBottom: 16 }}>
        {props.data.allergens
          ?.filter((item) => item.isPublished)
          .map((item) => {
            return (
              <Tag
                key={item.tagId}
                style={{
                  background: "#F1F3F5",
                  borderRadius: 8,
                  padding: "4px 12px",
                }}
              >
                {item.name}
              </Tag>
            );
          })}
      </Row>

      {/* <Row align='middle'>
        <InfoCircleOutlined style={{ fontSize: 20 }} />
        <Text
          style={{
            marginLeft: 8,
            fontSize: "14px",
          }}
        >
          Allergens:{" "}
          {props.data.allergens?.map((item) => {
            return (
              <Tag
                style={{
                  borderRadius: "100px",
                  backgroundColor: "#FFCCD7",
                }}
              >
                {item.name}
              </Tag>
            );
          })}
          {props.data.allergens?.filter((item) => item.isPublished).length ===
            0 && (
            <Text
              style={{ color: "darkgrey", fontStyle: "italic", fontSize: 11 }}
            >
              Not set
            </Text>
          )}
          {props.data.allergens
            ?.filter((item) => item.isPublished)
            .map((allergen, idx) => allergen.name).join(', ')}
        </Text>
      </Row> */}

      <Divider dashed style={{ marginBottom: 15, marginTop: 15 }} />

      <Row align='middle' hidden={props.page === "top-pick"}>
        <Col span={12}>
          <Dropdown
            overlay={
              <Menu
                onClick={(event) => {
                  const findVariant = props.data.variants.find(
                    (vari) => vari.menuVariantId === event.key
                  );
                  if (findVariant) {
                    setSelectedVariant(findVariant);
                  }
                }}
              >
                {props.data.variants.map((variant) => (
                  <Menu.Item key={variant.menuVariantId}>
                    <Row justify='space-between' align='middle'>
                      <Text>{variant.label}</Text>
                      <Text style={{ marginRight: 20, fontWeight: "bold" }}>
                        ${variant.price.toFixed(2)}
                      </Text>
                    </Row>
                  </Menu.Item>
                ))}
              </Menu>
            }
            trigger={["click"]}
          >
            <Row
              justify='space-between'
              align='middle'
              style={{
                flex: 1,
                cursor: "pointer",
                border: "1px solid #D5DCE1",
                borderRadius: 8,
                height: 50,
                padding: "0 10px",
              }}
            >
              <Col>{selectedVariant.label}</Col>
              <Col>
                <Text style={{ marginRight: 10, fontWeight: "bold" }}>
                  ${selectedVariant.price.toFixed(2)}
                </Text>
                <DownOutlined />
              </Col>
            </Row>
          </Dropdown>
        </Col>

        <Col
          xs={12}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Row
            align='middle'
            style={{
              border: "1px solid #D5DCE1",
              borderRadius: 10,
              height: 50,
            }}
          >
            <div
              onClick={() => {
                if (itemAmount > 1) {
                  setItemAmount((oldValue) => oldValue - 1);
                }
              }}
              style={{
                width: 42,
                height: 50,
                cursor: "pointer",
                textAlign: "center",
                borderRight: "1px solid #D5DCE1",
                fontSize: 24,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: 600,
              }}
            >
              -
            </div>
            <div
              style={{
                width: 50,
                textAlign: "center",
                fontSize: 16,
                fontWeight: 600,
              }}
            >
              {itemAmount}
            </div>
            <div
              onClick={() => {
                setItemAmount((oldValue) => oldValue + 1);
              }}
              style={{
                width: 42,
                height: 50,
                cursor: "pointer",
                textAlign: "center",
                borderLeft: "1px solid #D5DCE1",
                fontSize: 24,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: 600,
              }}
            >
              +
            </div>
          </Row>
        </Col>
      </Row>

      {/* {props.page === 'top-pick' && (
        <div style={{ marginBottom: 10 }}>
          <Divider />
          <Text>Availibity pickup locations</Text>

          <div
            style={{
              marginTop: 5,
              padding: '5px 10px 5px 10px',
              border: '1px lightgrey solid',
              borderRadius: 10,
            }}
          >
            <Dropdown overlay={ContentDropdownAvailabilityPickupLocation}>
              <Row justify="space-between" align="middle">
                <Col span={20}>
                  <Row align="middle">
                    <Col
                      style={{
                        backgroundColor: 'lightgrey',
                        borderRadius: 10,
                        padding: '5px 10px 5px 10px',
                      }}
                    >
                      <EnvironmentOutlined style={{ color: '#000000' }} />
                    </Col>
                    <Col style={{ paddingLeft: 10 }}>
                      <div>
                        <Text style={{ fontWeight: 'bold' }}>
                          {selectedLocation?.locationName ||
                            'Choose pickup location'}
                        </Text>
                      </div>
                      <div>
                        <Text>{selectedLocation?.locationAddress}</Text>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col span={2}>
                  <ChevronDown style={{ color: 'grey' }} />
                </Col>
              </Row>
            </Dropdown>
          </div>
        </div>
      )} */}

      {/* {props.page === 'top-pick' && <Divider />} */}

      {/* {props.page === 'top-pick' && (
        <div style={{ marginBottom: 10 }}>
          <div>
            <Text>Availibity date</Text>
          </div>
          <div style={{ marginBottom: 5 }}>
            <Text style={{ fontSize: 11, color: 'darkgrey' }}>
              You can choose more than one order date
            </Text>
          </div>

          <Row wrap gutter={[16, 10]}>
            {listOfDates.map((date) => (
              <Col
                key={date.identifierIndex}
                xs={12}
                md={8}
                lg={6}
                style={{ paddingRight: 5 }}
              >
                <div
                  style={{
                    backgroundColor: date.isAvailable
                      ? 'transparent'
                      : '#F2F2F2',
                    padding: '5px 5px 5px 5px',
                    border: date.isSelected
                      ? '1px red solid'
                      : '1px lightgrey solid',
                    borderRadius: 10,
                    cursor: date.isAvailable ? 'pointer' : 'none',
                  }}
                  onClick={() => {
                    if (!date.isAvailable) {
                      return;
                    }
                    if (selectedLocation.locationId !== '') {
                      setTmpSelectedDate(date);
                      if (selectedLocation.availabilityPickups.length === 1) {
                        const newTime = selectedLocation.availabilityPickups[0];
                        setTmpSelectedAvailabilityPickupTime({ ...newTime });

                        let list = [...listOfDates];
                        list = list.map((item) => {
                          if (item.identifierIndex === date?.identifierIndex) {
                            item.isSelected = true;
                            item.availabilityDate = newTime;
                          }
                          return item;
                        });

                        setListOfDates(list);
                      } else {
                        setIsShowModalSelectPickupTime(true);
                      }
                    } else {
                      message.info('Please Select Location First');
                    }
                  }}
                >
                  <Row justify="space-between">
                    <Col>
                      <Text
                        style={{
                          color: date.isAvailable ? 'black' : 'darkgrey',
                        }}
                      >
                        {SHORT_DAYS_IN_WEEK[date.date.getDay()]}{' '}
                        {date.date.getDate()}
                      </Text>
                    </Col>
                  </Row>
                  {date.availabilityDate ? (
                    <div>
                      <Text style={{ fontSize: 10 }} type="danger">
                        {date.availabilityDate.startAt}
                        {' - '}
                        {date.availabilityDate.endAt}
                      </Text>
                    </div>
                  ) : (
                    <div>
                      <Text
                        style={{
                          color: date.isAvailable ? 'black' : 'darkgrey',
                          fontSize: 11,
                        }}
                      >
                        Time not set
                      </Text>
                    </div>
                  )}
                </div>

                {!!date.availabilityDate && (
                  <div
                    onClick={() => {
                      let list = [...listOfDates];
                      list = list.map((item) => {
                        if (item.identifierIndex === date.identifierIndex) {
                          item.isSelected = false;
                          item.availabilityDate = undefined;
                        }
                        return item;
                      });
                      setListOfDates(list);
                    }}
                    style={{
                      padding: 5,
                      position: 'absolute',
                      right: 8,
                      top: 0,
                      cursor: 'pointer',
                    }}
                  >
                    <CloseCircleOutlined style={{ color: 'grey' }} />
                  </div>
                )}
              </Col>
            ))}
          </Row>
        </div>
      )} */}

      {/* {props.page === 'top-pick' && <Divider />} */}

      {/* {isComingSoon ? (
        <ComingSoon containerStyle={{ height: 400 }} />
      ) : (
      )} */}
      <Row style={{ marginTop: 20 }}>
        <AppButton
          block
          size='large'
          type='primary'
          htmlType='submit'
          style={{ borderRadius: 8, height: 50 }}
          disabled={
            props.page === "top-pick"
              ? false
              : /* !selectedLocation ||
                !itemAmount ||
                !listOfDates.find((item) => item.availabilityDate) */
                !itemAmount
          }
          onClick={() => {
            if (props.page === "top-pick" && props.onAddMultipleToCart) {
              // if (selectedLocation) {
              //   const list: AddToCartItem[] = [];
              //   for (const item of listOfDates) {
              //     if (item.availabilityDate) {
              //       list.push({
              //         qty: itemAmount,
              //         location: selectedLocation,
              //         availabilityPickupTime: item.availabilityDate,
              //         date: new Date(item.date).toISOString(),
              //         menuId: props.data.menuId,
              //         menuVariantId: selectedVariant?.menuVariantId || "",
              //       });
              //     }
              //   }
              //   console.info("list", list);
              //   props.onAddMultipleToCart(list);
              // }
              history.push("/menu");
            } else if (props.onAddToCart) {
              props.onAddToCart({
                qty: itemAmount,
                location: selectedLocation,
                // availabilityPickupTime: item.availabilityDate,
                // date: new Date(item.date).toISOString(),
                menuId: props.data.menuId,
                menuVariantId: selectedVariant?.menuVariantId || "",
              });
            }
          }}
        >
          {props.page === "top-pick" ? (
            <Row justify='space-between' style={{ width: "100%" }}>
              <div style={{ textAlign: "center" }}>Go to Menu</div>
            </Row>
          ) : (
            <Row justify='space-between' style={{ width: "100%" }}>
              <Col>Add To Cart</Col>
              <Col>${totalPrice.toFixed(2)}</Col>
            </Row>
          )}
        </AppButton>
      </Row>
    </>
  );

  return (
    <>
      {isMobile ? (
        <BottomSheet
          expandOnContentDrag
          open
          ref={sheetRef}
          onDismiss={() => props.onCancel()}
        >
          <div style={{ padding: 15 }}>{_renderDetailContent()}</div>
        </BottomSheet>
      ) : (
        <Modal
          // title={
          //   <Row>
          //     <IconTudung size={20} color='darkgrey' />
          //     <Text style={{ marginLeft: 10, color: "darkgrey" }}>Menu</Text>
          //   </Row>
          // }
          visible={true}
          footer={null}
          onCancel={() => {
            props.onCancel();
          }}
          width={500}
          style={{
            borderRadius: "100px",
          }}
          bodyStyle={{
            paddingTop: 24,
          }}
        >
          {_renderDetailContent()}
        </Modal>
      )}

      <ModalSelectPickupTime
        onChange={(newTime) => {
          let list = [...listOfDates];
          list = list.map((item) => {
            if (item.identifierIndex === tmpSelectedDate?.identifierIndex) {
              item.isSelected = true;
              item.availabilityDate = newTime;
            }
            return item;
          });

          setListOfDates(list);
          setTmpSelectedAvailabilityPickupTime(undefined);
          setIsShowModalSelectPickupTime(false);
        }}
      />
    </>
  );
}

const ButtonPrimary = styled(Button)`
  border-color: ${({ theme }) => theme.colors.white};
  &::after {
    all: unset;
  }
`
