import { createElement, loadAirwallex } from 'airwallex-payment-elements';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { createPaymentIntent } from '../helpers/utils';
import useOrder, { EOrderType } from '../hooks/useOrder';

export type EmbedPaymentProductProps = {
  imageUrl: string;
  name: string;
  description: string;
  unitPrice: number;
  qty: number;
};

export interface EmbedPaymentProps {
  id: string;
  client_secret: string;
  currency: string;
  groupedId: string;
  handlePaymentSuccess: (groupedId: string) => void;
}

const Payment: React.FC<EmbedPaymentProps> = (props) => {
  const history = useHistory();

  useEffect(() => {
    const loadDropInElement = async () => {
      try {
        // STEP #2: Initialize Airwallex on mount with the appropriate production environment and other configurations
        await loadAirwallex({
          /**
           * Build Note:
           *
           * Kalau waktu build process.env.REACT_STAGE_APP tidak terbaca
           * untuk value env langsung set ke 'prod'
           */
          // env: 'prod',
          env: process.env.REACT_APP_STAGE === 'production' ? 'prod' : 'demo', // Can choose other production environments, 'staging | 'demo' | 'prod'
          origin: window.location.origin, // Setup your event target to receive the browser events message
          // For more detailed documentation at https://github.com/airwallex/airwallex-payment-demo/tree/master/docs#loadAirwallex
        });
        // STEP #3: Create payment intent
        // const intent = await createPaymentIntent({
        //   request_id: props.requestId,
        //   merchant_order_id: props.merchantOrderId,
        //   amount: props.totalNeedToPay,
        //   currency: 'AUD',
        //   order: {
        //     products: props.products.map((item) => {
        //       return {
        //         url: item.imageUrl,
        //         name: item.name,
        //         desc: item.description,
        //         unit_price: item.unitPrice,
        //         currency: 'AUD',
        //         quantity: item.qty,
        //       };
        //     }),
        //   },
        // });
        const { id, client_secret, currency } = props;
        console.log('REACT_STAGE_APP', process.env.REACT_STAGE_APP);
        console.log('id', id);
        console.log('client_secret', client_secret);
        console.log('currency', currency);
        // STEP #4: Create the drop-in element
        const element = createElement('dropIn', {
          // Required, dropIn use intent Id, client_secret and currency to prepare checkout
          intent_id: id,
          client_secret,
          currency,
          googlePayRequestOptions: {
            countryCode: 'AU',
            billingAddressParameters: {
              format: 'FULL',
              phoneNumberRequired: true,
            },
            billingAddressRequired: true,
            merchantInfo: {
              merchantName: 'Spoonful Kitchen',
            },
            buttonType: 'buy', // Indicate the type of button you want displayed on your payments form. Like 'buy'
          },
          applePayRequestOptions: {
            countryCode: 'AU',
            // requiredBillingContactFields: ['postalAddress'], // optional field
            // requiredShippingContactFields: [
            //   'email',
            //   'name',
            //   'phoneticName',
            //   'phone',
            // ], // optional field
            buttonType: 'buy', // Indicate the type of button you want displayed on your payments form. Like 'buy'
            buttonColor: 'black', // Indicate the color of the button. Default value is 'black'
          },
          style: {
            // the 3ds popup window dimension
            popupWidth: 400,
            popupHeight: 549,
          },
        });
        // STEP #5: Mount the drop-in element to the empty container created previously
        element?.mount('dropIn'); // This 'dropIn' id MUST MATCH the id on your empty container created in Step 4
      } catch (error) {
        console.error(error);
      }
    };
    loadDropInElement();
    // STEP #6: Add an event listener to handle events when the element is mounted
    const onReady = (event: CustomEvent): void => {
      /**
       * Handle events on element mount
       */
      console.log(`Element is mounted: ${JSON.stringify(event.detail)}`);
    };

    // STEP #7: Add an event listener to handle events when the payment is successful.
    const onSuccess = (event: CustomEvent): void => {
      /**
       * Handle events on success
       */
      console.log(`Confirm success with ${JSON.stringify(event.detail)}`);
      // history.push('/checkout-success');
      props.handlePaymentSuccess(props.groupedId);
    };

    // STEP #8: Add an event listener to handle events when the payment has failed.
    const onError = (event: CustomEvent) => {
      /**
       * Handle events on error
       */
      const { error } = event.detail;
      console.error('There is an error', error);
    };
    const domElement = document.getElementById('dropIn');
    domElement?.addEventListener('onReady', onReady as EventListener);
    domElement?.addEventListener('onSuccess', onSuccess as EventListener);
    domElement?.addEventListener('onError', onError as EventListener);
    return () => {
      domElement?.removeEventListener('onReady', onReady as EventListener);
      domElement?.removeEventListener('onSuccess', onSuccess as EventListener);
      domElement?.removeEventListener('onError', onError as EventListener);
    };
  }, [history]); // This effect should ONLY RUN ONCE as we do not want to reload Airwallex and remount the elements

  const containerStyle = {
    width: '100%',
    margin: '10px auto',
  };

  const { orderType } = useOrder();

  return (
    <div>
      {/**
       * STEP #4a: Add an empty container for the dropin element to be placed into
       * - Ensure this is the only element in your document with this id,
       *   otherwise the element may fail to mount.
       */}
      <div
        className='checkout-content-header'
        style={{ flexDirection: 'column', alignItems: 'flex-start' }}
      >
        <h3>Payment</h3>
        <p>
          Pay your order before the{' '}
          {orderType === EOrderType.pickup
            ? 'pickup'
            : orderType === EOrderType.delivery
            ? 'delivery'
            : ''}{' '}
          schedule.
        </p>
      </div>
      <div
        id='dropIn'
        style={{
          ...containerStyle, // Example: container styling can be moved to css
        }}
      />
    </div>
  );
};

export default Payment;
