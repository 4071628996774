import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import Homepage from '../screens';
import TermAndCondition from '../screens/TermAndConditions';
import ContactUs from '../screens/ContactUs';
import ForgotPassword from '../screens/ForgotPassword';
import ResetPassword from '../screens/ResetPassword';
import MyAccount from '../screens/customers/MyAccount';
import PrivacyPolicy from '../screens/PrivacyPolicy';
import FAQ from '../screens/FAQ';
import VerifyEmail from '../screens/VerifyEmail';
// import Menus from '../screens/menus';
import MyOrders from '../screens/customers/MyOrder';
import EditProfile from '../screens/customers/Edit';
import ChangePassword from '../screens/customers/ChangePassword';
import MenusStatic from '../screens/menus/menu-statis';
import AboutUs from '../screens/AboutUs';
import Menus from '../screens/menus/index2';
import Checkout from '../screens/Checkout';
import MyAddress from '../screens/customers/MyAddress';
import EditMyAddress from '../screens/customers/EditMyAddress';
import CheckoutPayment from '../screens/CheckoutPayment';

const RootNavigator = () => {
  return (
    <Router>
      <Switch>
        {/* Public Route */}
        <PublicRoute exact path='/' restricted={true}>
          <Homepage />
        </PublicRoute>

        {/* <PublicRoute exact path="/menu" restricted={true}>
          <MenusStatic />
        </PublicRoute> */}
        <PublicRoute exact path='/menu' restricted={true}>
          <Menus />
        </PublicRoute>
        <PublicRoute exact path='/checkout' restricted={true}>
          <Checkout />
        </PublicRoute>
        <PublicRoute exact path='/checkout-payment' restricted={true}>
          <CheckoutPayment />
        </PublicRoute>

        <PublicRoute exact path='/forgot-password' restricted={true}>
          <ForgotPassword />
        </PublicRoute>
        <PublicRoute exact path='/reset-password' restricted={true}>
          <ResetPassword />
        </PublicRoute>
        <PublicRoute exact path='/reset-password/:sessionId' restricted={true}>
          <ResetPassword />
        </PublicRoute>
        <PublicRoute exact path='/verify-email/:sessionId' restricted={true}>
          <VerifyEmail />
        </PublicRoute>

        <PublicRoute exact path='/faqs' restricted={true}>
          <FAQ />
        </PublicRoute>
        <PublicRoute exact path='/privacy-policy' restricted={true}>
          <PrivacyPolicy />
        </PublicRoute>
        <PublicRoute exact path='/terms-and-conditions' restricted={true}>
          <TermAndCondition />
        </PublicRoute>
        <PublicRoute exact path='/contact-us' restricted={true}>
          <ContactUs />
        </PublicRoute>
        <PublicRoute exact path='/about-us' restricted={true}>
          <AboutUs />
        </PublicRoute>

        <PrivateRoute exact path='/profile'>
          <MyAccount />
        </PrivateRoute>
        <PrivateRoute exact path='/profile/edit'>
          <EditProfile />
        </PrivateRoute>
        <PrivateRoute exact path='/profile/change-old-password'>
          <ChangePassword />
        </PrivateRoute>
        <PrivateRoute exact path='/orders'>
          <MyOrders />
        </PrivateRoute>
        <PrivateRoute exact path='/address'>
          <MyAddress />
        </PrivateRoute>
        <PrivateRoute exact path='/address/edit/:addressId'>
          <EditMyAddress />
        </PrivateRoute>
        <PrivateRoute exact path='/address/add'>
          <EditMyAddress />
        </PrivateRoute>
      </Switch>
    </Router>
  );
};

export default RootNavigator;
