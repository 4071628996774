import {
  UserOutlined,
  OrderedListOutlined,
  LogoutOutlined,
  HomeOutlined,
  CheckCircleFilled,
  WarningFilled,
  InfoCircleFilled,
} from '@ant-design/icons';
import {
  Avatar,
  Col,
  Divider,
  Menu,
  Modal,
  Row,
  Image,
  Tag,
  Tooltip,
} from 'antd';
import Text from 'antd/lib/typography/Text';
import Sider from 'antd/lib/layout/Sider';
import React from 'react';
import styled from 'styled-components';
import useAuth from '../hooks/useAuth';
import { useHistory, useLocation } from 'react-router-dom';
import { appVersion, getInitialName, thisYear } from '../helpers/constant';

type Props = {
  isVerified: boolean;
};

export default function SidebarMenu(props: Props) {
  const location = useLocation();
  const history = useHistory();
  const { user, logout, membershipDiscount } = useAuth();

  const menus = [
    {
      key: 'profile',
      label: 'Account',
      icon: <UserOutlined />,
      iconSelected: (
        <UserOutlined style={{ color: '#FF4A50', fontWeight: 'bold' }} />
      ),
    },
    {
      key: 'orders',
      label: 'My Orders',
      icon: <OrderedListOutlined />,
      iconSelected: (
        <OrderedListOutlined style={{ color: '#FF4A50', fontWeight: 'bold' }} />
      ),
    },
    {
      key: 'address',
      label: 'My Address',
      icon: <HomeOutlined />,
      iconSelected: (
        <HomeOutlined style={{ color: '#FF4A50', fontWeight: 'bold' }} />
      ),
    },
    {
      key: 'logout',
      label: 'Logout',
      icon: <LogoutOutlined />,
      iconSelected: (
        <LogoutOutlined style={{ color: '#FF4A50', fontWeight: 'bold' }} />
      ),
    },
  ];

  const handleClick = (e: any) => {
    if (e.key === 'logout') {
      Modal.confirm({
        title: 'Confirmation',
        content: 'Are you sure want to logout?',
        onOk: () => {
          logout();
          history.replace({ pathname: '/' });
        },
        okText: `Yes, I'm sure`,
        okType: 'primary',
      });
    } else {
      history.push('/' + e.key);
    }
  };
  return (
    <Sider
      width='22%'
      style={{ marginLeft: 50, marginTop: 20, background: 'transparent' }}
    >
      <HeaderBackground />

      <div
        style={{
          border: '1px solid #f0f0f0',
          borderRadius: '0 0 10px 10px',
          background: '#FFFFFF',
        }}
        className='sidebar-wrapper'
      >
        <Row style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 15 }}>
          <Col>
            {user.imageUrl ? (
              <AppAvatar
                size='large'
                icon={
                  <Image
                    preview={false}
                    height={40}
                    width={40}
                    src={user.imageUrl || '/images/blur-image.jpeg'}
                    fallback={'/images/blur-image.jpeg'}
                    style={{ objectFit: 'cover', borderRadius: '50%' }}
                    placeholder={
                      <Image
                        preview={false}
                        src='/images/blur-image.jpeg'
                        width={40}
                        height={40}
                      />
                    }
                  />
                }
              />
            ) : (
              <AppAvatar size='large'>
                {getInitialName(user.fullName)}
              </AppAvatar>
            )}
          </Col>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {membershipDiscount && (
              <div>
                <Tag color='#000'>{membershipDiscount.discountName}</Tag>
              </div>
            )}
            <div style={{ fontWeight: 'bold' }}>{user.fullName}</div>
            <Tooltip
              placement='bottom'
              title={props.isVerified ? 'Email verified' : 'Email not verified'}
            >
              <div style={{ fontSize: 11 }}>
                {props.isVerified ? (
                  <CheckCircleFilled style={{ color: 'green' }} />
                ) : (
                  <InfoCircleFilled style={{ color: 'gray' }} />
                )}{' '}
                {user.email}
              </div>
            </Tooltip>
          </div>
        </Row>

        <Divider style={{ marginTop: 15, marginBottom: 5 }} />

        <Menu onClick={handleClick} mode='inline'>
          {menus.map((menu) => (
            <Menu.Item
              key={menu.key}
              className={
                location.pathname.includes('/' + menu.key)
                  ? 'ant-menu-item-active'
                  : ''
              }
              icon={
                location.pathname.includes('/' + menu.key)
                  ? menu.iconSelected
                  : menu.icon
              }
              // style={
              //   location.pathname.includes('/' + menu.key)
              //     ? { color: '#FF4A50', fontWeight: 'bold' }
              //     : undefined
              // }
            >
              {menu.label}
            </Menu.Item>
          ))}
        </Menu>
        <Row justify='center' style={{ paddingTop: 50 }}>
          <Text type='secondary' style={{ fontSize: '10px' }}>
            v{appVersion} · © {thisYear}. Spoonful Kitchen{' '}
          </Text>
        </Row>
      </div>
    </Sider>
  );
}

const HeaderBackground = styled.div`
  background: ${({ theme }) =>
    'url(/images/bg-circle.svg) no-repeat center ' + theme.colors.primary};
  background-size: cover;
  text-align: center;
  width: 100%;
  height: 100px;
  padding-top: 100px;
  border-radius: 10px 10px 0 0;
`;

const AppAvatar = styled(Avatar)`
  background-color: ${({ theme }) => theme.colors.ash500};
  color: ${({ theme }) => theme.colors.black};
  margin-right: 10px;
`;
