import React from 'react';
import {
  Button,
  Col,
  Divider,
  Row,
  Typography,
  Image,
  Card,
  Avatar,
  Space,
} from 'antd';
import AppLayout from '../../layout/AppLayout';
import useAuth from '../../hooks/useAuth';
import { useHistory } from 'react-router-dom';
import { EditOutlined, UploadOutlined, UserOutlined } from '@ant-design/icons';
import { getInitialName } from '../../helpers/constant';
import styled from 'styled-components';

const { Title, Text } = Typography;

const MyAccount: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();

  const handleEditButton = () => {
    history.push('/profile/edit');
  };

  const handleChangePassword = () => {
    history.push('/profile/change-old-password');
  };

  return (
    <AppLayout hasSidebar isMyAccount>
      <Card style={{}}>
        <Row justify='space-between'>
          <Col>
            <Title level={3}>Account</Title>
          </Col>
          <Row>
            <Col>
              <Button
                size='middle'
                style={{
                  backgroundColor: '#FF4A50',
                  color: 'white',
                }}
                onClick={() => handleEditButton()}
              >
                <UserOutlined />
                Edit Account
              </Button>
            </Col>
            <div style={{ width: 10 }} />
            <Col>
              <Button
                style={{
                  backgroundColor: '#FF4A50',
                  color: 'white',
                }}
                onClick={() => handleChangePassword()}
              >
                <EditOutlined />
                Change Password
              </Button>
            </Col>
          </Row>
        </Row>
        <Divider />
        <Row gutter={[16, 16]} wrap justify='space-between'>
          <Col
            xs={24}
            lg={8}
            style={{
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {user.imageUrl ? (
              <Image
                preview={false}
                width={120}
                height={120}
                src={user.imageUrl}
                fallback={'/images/blur-image.jpeg'}
                style={{ borderRadius: '50%' }}
                placeholder={
                  <Image
                    preview={false}
                    src='/images/blur-image.jpeg'
                    width='100%'
                    height={200}
                  />
                }
              />
            ) : (
              <AppAvatar style={{ width: 120, height: 120 }} size='small'>
                {getInitialName(user.fullName)}
              </AppAvatar>
            )}

            <Row
              justify='center'
              align='middle'
              style={{
                marginTop: 10,
                cursor: 'pointer',
                padding: '10px 20px 10px 20px',
                borderRadius: 30,
                border: '1px solid black',
                marginLeft: '10%',
                marginRight: '10%',
              }}
              onClick={() => {
                history.push({
                  pathname: '/profile/edit',
                  state: {
                    do: 'upload',
                  },
                });
              }}
            >
              <UploadOutlined />
              <Text style={{ marginLeft: 10 }}>Upload Photo</Text>
            </Row>
          </Col>
          <Col xs={24} lg={16}>
            <Row style={{ marginBottom: 3 }}>
              <Text type='secondary'>Full Name</Text>
            </Row>
            <Row style={{ marginBottom: 10 }}>
              <Text strong>{user.fullName}</Text>
            </Row>
            <Row style={{ marginBottom: 3 }}>
              <Text type='secondary'>Phone Number</Text>
            </Row>
            <Row style={{ marginBottom: 10 }}>
              <Text strong>{user.phoneNumber}</Text>
            </Row>
            <Row style={{ marginBottom: 3 }}>
              <Text type='secondary'>Email Address</Text>
            </Row>
            <Row style={{ marginBottom: 10 }}>
              <Text strong> {user.email}</Text>
            </Row>
          </Col>
        </Row>
      </Card>
      {/* <br></br>
      <Card>
        <Row justify='space-between'>
          <Col>
            <Title level={3}>Password</Title>
          </Col>
          <Col>
            <Button
              style={{
                backgroundColor: '#FF4A50',
                color: 'white',
              }}
              onClick={() => handleChangePassword()}
            >
              <EditOutlined />
              Change Password
            </Button>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col>
            <Row>
              <Text
                type='secondary'
                style={{ color: '#556575', fontSize: '12px' }}
              >
                Password
              </Text>
            </Row>
            <Row>
              <Title level={3}>•••••••••</Title>
            </Row>
          </Col>
        </Row>
      </Card> */}
    </AppLayout>
  );
};

export default MyAccount;

const AppAvatar = styled(Avatar)`
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.ash500};
  color: ${({ theme }) => theme.colors.black};
  margin-right: 10px;
  font-size: 46px;
`;
