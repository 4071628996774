import React from "react";
import { Col, Row } from "antd";
import useWindowSize from "../hooks/useWindowSize";

type Props = {
  title: string;
  description: string;
  image: string;
  index: number;
};

const HowItWorkItem: React.FC<Props> = ({
  title,
  description,
  image,
  index,
}) => {
  const { width } = useWindowSize();

  return (
    <div className='how-item'>
      <div className='how-image-wrapper'>
        <img alt={title} src={image} />
      </div>
      <div>
        <div className='how-title'>{title}</div>
        <div className='how-description'>{description}</div>
      </div>
    </div>
  );
};

export default HowItWorkItem;
