import { EOrderType } from "../hooks/useOrder";

type Props = {
  value?: EOrderType;
  onChange: (value: EOrderType) => void;
  options: EOrderType[];
};

const CustomTabs: React.FC<Props> = (props) => {
  return (
    <div className='tabs-container'>
      {props.options.map((item, idx) => (
        <div
          onClick={() => props.onChange(item)}
          className={
            props.value === item ? "tabs-item tabs-item-active" : "tabs-item"
          }
          key={idx}
        >
          {item}
        </div>
      ))}
    </div>
  );
};

export default CustomTabs;
