import { Collapse } from "antd";
import React from "react";
import { IFAQs } from "../screens";

type Props = {
  data: IFAQs[];
};

const { Panel } = Collapse;

const FAQItem: React.FC<Props> = ({ data }) => {
  return (
    <Collapse expandIconPosition='left' defaultActiveKey={["1"]} ghost>
      {data.map((item) => (
        <Panel header={item.question} key={item.faqId}>
          <p>{item.answer}</p>
        </Panel>
      ))}
    </Collapse>
  );
};

export default FAQItem;
