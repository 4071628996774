/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Modal, Row, Select, Typography } from "antd";
import AppButton from "./AppButton";
import useGlobalFilter from "../hooks/useGlobalFilter";
import React from "react";
import { IAvailability, initialAvailability } from "../types/location.type";
import useWindowSize from "../hooks/useWindowSize";
import { BottomSheet, BottomSheetRef } from "react-spring-bottom-sheet";

const { Text } = Typography;
const { Option } = Select;

type Props = {
  onChange?: (newItem: IAvailability) => void;
};

export default function ModalSelectPickupTime(props: Props) {
  const sheetRef: any = React.useRef<BottomSheetRef>();
  const { isMobile } = useWindowSize();
  const {
    selectedLocation,

    isShowModalSelectPickupTime,
    setIsShowModalSelectPickupTime,
  } = useGlobalFilter();

  const [tmpSelectedPickupTime, setTmpSelectedPickupTime] =
    React.useState<IAvailability>({ ...initialAvailability });

  const [isBlocking, setIsBlocking] = React.useState(true);

  React.useEffect(() => {
    init();
  }, []);

  React.useEffect(() => {
    if (isShowModalSelectPickupTime) {
      init();
    }
  }, [isShowModalSelectPickupTime]);

  React.useEffect(() => {
    setTmpSelectedPickupTime({ ...initialAvailability });
  }, [selectedLocation]);

  const init = () => {
    if (selectedLocation.availabilityPickups.length === 1) {
      setTmpSelectedPickupTime(selectedLocation.availabilityPickups[0]);
    } else {
      if (
        !selectedLocation.availabilityPickups.find(
          (item) =>
            item.availabilitypickupId ===
            tmpSelectedPickupTime.availabilitypickupId
        )
      ) {
        setTmpSelectedPickupTime({ ...initialAvailability });
      }
    }
  };

  const _renderContent = () => (
    <>
      <Row>
        <Text
          style={{
            color: "#93A1B0",
            fontSize: "12px",
            marginTop: "2%",
          }}
        >
          Pick a time
        </Text>
      </Row>
      <Row style={{ marginBottom: 20 }}>
        {/* {isMobile ? (
          <Col xs={24}>
            <Row wrap gutter={[16,16]}>
              {selectedLocation.availabilityPickups.map((item) => (
                <Col
                  onClick={() => {
                    setTmpSelectedPickupTime(item)
                  }}
                  xs={11}
                  style={{
                    padding: '5px 10px',
                    marginRight: 10,
                    borderRadius: 10,
                    border:
                      tmpSelectedPickupTime.availabilitypickupId ===
                      item.availabilitypickupId
                        ? '1px solid ' + theme.colors.primary
                        : '1px solid lightgrey',
                  }}
                >
                  {item.startAt} - {item.endAt}
                </Col>
              ))}
            </Row>
          </Col>
        ) : ( */}
        <Select
          size='large'
          style={{ width: "100%", borderRadius: "100px" }}
          placeholder='Pick a time'
          onFocus={() => {
            setIsBlocking(false);
          }}
          onBlur={() => {
            setIsBlocking(true);
          }}
          value={
            tmpSelectedPickupTime.availabilitypickupId
              ? tmpSelectedPickupTime.availabilitypickupId
              : undefined
          }
          onChange={(value) => {
            const findOne = selectedLocation.availabilityPickups.find(
              (item) => item.availabilitypickupId === value
            );
            if (findOne) {
              setTmpSelectedPickupTime(findOne);
            }
            setIsBlocking(true);
          }}
        >
          {selectedLocation.availabilityPickups.map((item) => {
            return (
              <Option
                key={item.availabilitypickupId}
                value={item.availabilitypickupId}
              >
                <Text strong style={{ fontWeight: 600, fontSize: "12px" }}>
                  {item.startAt} - {item.endAt}
                </Text>
              </Option>
            );
          })}
        </Select>
        {/* )} */}
      </Row>
      <AppButton
        block
        type='primary'
        disabled={
          !tmpSelectedPickupTime ||
          (tmpSelectedPickupTime && !tmpSelectedPickupTime.availabilitypickupId)
        }
        onClick={() => {
          if (tmpSelectedPickupTime) {
            if (props.onChange) {
              props.onChange(tmpSelectedPickupTime);
            }
            setIsShowModalSelectPickupTime(false);
          }
        }}
      >
        Confirm Time
      </AppButton>
    </>
  );

  if (!isShowModalSelectPickupTime) {
    return <></>;
  }

  if (isMobile) {
    return (
      <BottomSheet
        blocking={false}
        open
        ref={sheetRef}
        expandOnContentDrag
        onDismiss={() => setIsShowModalSelectPickupTime(false)}
      >
        <div style={{ padding: 15 }}>{_renderContent()}</div>
      </BottomSheet>
    );
  } else {
    return (
      <Modal
        title='Schedule'
        visible={isShowModalSelectPickupTime}
        footer={null}
        onCancel={() => {
          setIsShowModalSelectPickupTime(false);
        }}
        width={624}
      >
        {_renderContent()}
      </Modal>
    );
  }
}
