import { TransactionProps } from "./transaction.type";

export interface PaymentProps {
    paymentId: string;
    externalId: string; //token?
    transactionId: string;
    groupedId: string;
    paymentType: enumPaymentType;
    paymentTypeVendorId: string;
    paymentMethod: enumPaymentMethod;
    paymentStatus: enumPaymentStatus;
    paymentVendorId: enumPaymentVendorId;
    expiredAt: Date;
    createdByUserId: string;
    transactions: TransactionProps[];
    metaCreatedByUser: any;
    metaPaymentDetail: any;
    updatedAt: Date;
    createdAt: Date;
}

export enum enumPaymentMethod {
    BANK_TRANSFER = 'BANK_TRANSFER',
    EWALLET = 'EWALLET',
    OUTLET = 'OUTLET',
    POLI_LINK = 'POLI_LINK',
}

export enum enumPaymentType {
    PAYMENT_GATEWAY = 'PAYMENT_GATEWAY',
    CASH = 'CASH'
}

export enum enumPaymentStatus {
  WAITING_PAYMENT = 'WAITING_PAYMENT',
  PAID_FULL = 'PAID_FULL',
  CANCELLED_BY_SYSTEM = 'CANCELLED_BY_SYSTEM',
  CANCELLED_BY_BUYER = 'CANCELLED_BY_BUYER',
  DONE = 'DONE',
}

export enum enumPaymentVendorId {
    BNI = 'bni',
    BRI = 'bri',
    MANDIRI = 'mandiri',
    GOPAY = 'gopay',
    OVO = 'ovo',
    DANA = 'dana',
    INDOMARET = 'indomaret',
    ALFAMART = 'alfamart',
    WESTPAC = 'WESTPAC',
    ANZ = 'ANZ',
    COMMONWEALTH = 'COMMONWEALTH',
}

