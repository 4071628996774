import React from "react";
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps";
import { GOOGLE_MAP_API_KEY, PROPS_MAPS } from "../helpers/map";

type Props = {
  width?: number | string;
  height?: number;
  defaultZoom?: number;
  center?: {
    lat: number;
    lng: number;
  };
  coords?: {
    onClick?: () => void;
    isSelected?: boolean;
    isMain?: boolean;
    lat: number;
    lng: number;
  }[];
};
const CustomMap = () => <div>map</div>;
// const CustomMap = withScriptjs(
//   withGoogleMap((props: Props) => {
//     let refMap = React.useRef<any>();

//     const mapCenter =
//       props.center ||
//       (props.coords && props.coords.length > 0
//         ? { lat: props.coords[0].lat, lng: props.coords[0].lng }
//         : { lat: 0, lng: 0 });

//     const coords = props.coords || [];

//     return (
//       <GoogleMap
//         ref={refMap}
//         defaultZoom={props.defaultZoom || 17}
//         defaultCenter={{ lat: 5, lng: 5 }}
//         center={mapCenter}
//         // onBoundsChanged={handleBoundsChanged}
//       >
//         {coords.map((coord) => (
//           <Marker
//             onClick={coord.onClick}
//             position={{ lat: coord.lat, lng: coord.lng }}
//             icon={{
//               url: coord.isMain
//                 ? '/images/marker/main-kitchen.png'
//                 : '/images/marker/default.png',
//               scaledSize: new (window as any).google.maps.Size(42, 65),
//             }}
//           />
//         ))}
//       </GoogleMap>
//     );
//   })
// );

export default function AppMap(props: Props) {
  const height = props.height || 300;
  const width = props.width || "100%";

  return (
    <div style={{ width, height, borderRadius: 10, overflow: "hidden" }}>
      {/* <CustomMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height, width }} />}
        mapElement={<div style={{ height: `100%` }} />}
        {...props}
      /> */}
    </div>
  );
}
