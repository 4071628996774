import React from 'react';
import { Carousel } from 'antd';
import HeaderBannerItem from './HeaderBannerItem';
import useWindowSize from '../hooks/useWindowSize';
import { httpRequest } from '../helpers/api';
import { IHttpResponse, IPayloadPagination } from '../helpers/pagination';
import { generateQueryString } from '../helpers/generateQueryString';
// import { Settings } from "react-slick";
import { IAvailability } from '../types/location.type';
import { Link, useHistory } from 'react-router-dom';
import useGlobalFilter from '../hooks/useGlobalFilter';
import ModalSelectLocation from './ModalSelectLocation';
import ModalSelectPickupTime from './ModalSelectPickupTime';
import ModalBanner from './ModalBanner';
import { formatDate } from '../helpers/constant';

interface IBanner {
  bannerId: string;
  bannerImg: string;
  subtitle: string;
  title: string;
}

export default function CarouselHeaderBanner() {
  const history = useHistory();
  let refCarouselBanner = React.useRef<any>();
  const { isMobile } = useWindowSize();
  const [dataBanners, setDataBanners] = React.useState<IBanner[]>([]);

  // const [selectedPickupTime, setSelectedPickupTime] =
  //   React.useState<IAvailability>();

  const [selectedDate, setSelectedDate] = React.useState<Date>();
  const {
    selectedLocation,
    setSelectedLocation,

    selectedAvailability,
    setSelectedAvailability,

    selectedGlobalDate,

    // setIsShowModalSelectLocation,
    // setIsShowModalSelectPickupTime,
    setIsShowModalBanner,
  } = useGlobalFilter();

  React.useEffect(() => {
    const fetchBanners = async () => {
      const res = await httpRequest.get<
        IHttpResponse<IPayloadPagination<IBanner>>
      >('banners' + generateQueryString({ isActive: true }));
      if (res.data.payload.results.length > 0) {
        setDataBanners(res.data.payload.results);
      }
    };

    fetchBanners();
  }, []);

  const _renderModalBanner = () => (
    <ModalBanner
      onChange={(newItemLocation, newItemAvailability, newItemDate) => {
        setSelectedLocation(newItemLocation);
        setSelectedAvailability(newItemAvailability);
        setSelectedDate(newItemDate.date);
      }}
    />
  );

  const handleClickOrder = () => {
    history.push('/menu');
  };

  const sampleBanners = [
    'https://res.cloudinary.com/djq6ydsfm/image/upload/v1710214061/f0nmwrozldkbit3dugna.png',
    'https://res.cloudinary.com/djq6ydsfm/image/upload/v1710213596/filejzn4cvxtvr9gjwrr.png',
  ];

  return (
    <>
      <Carousel autoplay dots={true}>
        {dataBanners.map((item) => (
          <Link key={item.bannerId} to='/menu'>
            <img
              style={{
                height: !isMobile ? 'auto' : 175,
                objectFit: 'cover',
              }}
              alt='banner-image'
              src={item.bannerImg}
              className='banner-image'
            />
          </Link>
        ))}
      </Carousel>
    </>
  );
}
