import React from "react";
import {
  Modal,
  Row,
  Select,
  Button,
  Typography,
  Alert,
  Col,
  message,
} from "antd";
import AppMap from "./AppMap";
import useGlobalConfig from "../hooks/useGlobalConfig";
import { BottomSheet, BottomSheetRef } from "react-spring-bottom-sheet";
import useWindowSize from "../hooks/useWindowSize";
import useGlobalFilter from "../hooks/useGlobalFilter";
import useCart from "../hooks/useCart";
import {
  IAvailability,
  ILocation,
  initialAvailability,
  initialLocation,
} from "../types/location.type";
import Title from "antd/lib/typography/Title";
import moment from "moment";
import { calculateAddDayForFirstOrder } from "../helpers/order";
import { SHORT_DAYS_IN_WEEK } from "../helpers/constant";
import { CloseCircleOutlined } from "@ant-design/icons";
import { httpRequest } from "../helpers/api";
import { IHttpResponse, IPayloadPagination } from "../helpers/pagination";
import { generateQueryString } from "../helpers/generateQueryString";
import { ISchedule } from "../types/schedules.type";

const { Text } = Typography;
const { Option } = Select;

type Props = {
  onChange?: (
    newItemLocation: ILocation,
    newItemAvailability: IAvailability,
    newItemDate: CarouselDate
  ) => void;
};

type CarouselDate = {
  identifierIndex: number;
  date: Date;
  isSelected: boolean;
  availabilityDate?: IAvailability;
  isDisabled: boolean;
};

const currentDate = moment().startOf("day");

export default function ModalBanner(props: Props) {
  const { locationIdInCart, clearCart } = useCart();
  const sheetRef: any = React.useRef<BottomSheetRef>();
  const { isMobile } = useWindowSize();
  const { locations } = useGlobalConfig();
  const {
    // isShowModalSelectLocation,
    isShowModalBanner,

    setIsShowModalBanner,
    // setIsShowModalSelectLocation,
    setSelectedLocation,
    setSelectedGlobalDate,
    setSelectedAvailability,
  } = useGlobalFilter();

  const [tmpSelectedLocation, setTmpSelectedLocation] = React.useState({
    ...initialLocation,
  });
  const [tmpSelectedPickupTime, setTmpSelectedPickupTime] =
    React.useState<IAvailability>({ ...initialAvailability });

  const [tmpSelectedDate, setTmpSelectedDate] = React.useState<CarouselDate>();

  const addDay = calculateAddDayForFirstOrder();
  const [listOfDates, setListOfDates] = React.useState<Array<CarouselDate>>(
    Array.from(
      [
        0, 1, 2, 3, 4, 5, 6,
        // 7, 8, 9, 10, 11, 12
      ],
      (i) => {
        const date = moment(currentDate).add(i + addDay, "days");
        return {
          identifierIndex: i,
          date: date.toDate(),
          isSelected: false,
          availabilityDate: date.isSame(currentDate)
            ? tmpSelectedPickupTime
            : undefined,
          isDisabled: false,
        };
      }
    )
  );

  const [isBlocking, setIsBlocking] = React.useState(true);

  React.useEffect(() => {
    if (
      locations.length > 0 &&
      (!tmpSelectedLocation || !tmpSelectedLocation?.locationGeoloc?.lat)
    ) {
      setTmpSelectedLocation(locations[0]);
      fetchSchedule(locations[0].locationId);
    }
  }, [locations]);

  React.useEffect(() => {
    if (locationIdInCart) {
      const findOne = locations.find(
        (loc) => loc.locationId === locationIdInCart
      );
      if (findOne) {
        setTmpSelectedLocation(findOne);
      }
    }
  }, [locationIdInCart]);

  const fetchSchedule = async (locationId: string) => {
    try {
      const res = await httpRequest.get<
        IHttpResponse<IPayloadPagination<ISchedule>>
      >(
        `/schedules${generateQueryString({
          dateFrom: moment(currentDate)
            .add(1 + addDay, "days")
            .toISOString(),
          dateUntil: moment(currentDate)
            .add(6 + addDay, "days")
            .toISOString(),
          locationId: locationId,
        })}`
      );

      const scheduleDates = listOfDates.map((date) => {
        return {
          ...date,
          isDisabled: res.data.payload.results.find(
            (item) =>
              item.scheduleAt ===
              moment(date.date).format("YYYY-MM-DD").toString()
          )
            ? false
            : true,
        };
      });
      setListOfDates(scheduleDates);
    } catch (err) {
      console.log(err);
    }
  };

  const handleConfirm = (
    newItemLocation: ILocation,
    newItemDate: CarouselDate,
    newItemAvailability: IAvailability
  ) => {
    if (locationIdInCart && locationIdInCart !== newItemLocation.locationId) {
      // const locationInCart = locations.find(item => item.locationId === locationIdInCart);
      Modal.confirm({
        title: "Confirmation",
        content: (
          <div>
            {/* <Alert
              type="warning"
              message={
                <div>
                  <Text>Location in Your Cart: </Text>
                  <Text style={{ fontWeight: 'bold' }}>
                    {locationInCart?.locationName}
                  </Text>
                </div>
              }
            />

            <Alert
              type="warning"
              message={
                <div>
                  <Text>Will change to: </Text>
                  <Text style={{ fontWeight: 'bold' }}>
                    {newLoc?.locationName}
                  </Text>
                </div>
              }
            /> */}

            <div style={{ marginTop: 10 }}>
              <div>
                <Text>Your existing location in cart is different.</Text>
              </div>
              <Text>
                Are you sure want to change pickup location? Your existing cart
                will be removed.
              </Text>
            </div>
          </div>
        ),
        onOk: () => {
          clearCart();
          setSelectedLocation(newItemLocation);
          setSelectedAvailability(newItemAvailability);
          setSelectedGlobalDate(newItemDate.date);
          setIsShowModalBanner(false);
          setIsBlocking(false);
          if (props.onChange) {
            // props.onChange(newItemLocation);
          }
        },
        okText: `Yes, I'm sure`,
        okType: "primary",
      });
    } else {
      setSelectedLocation(newItemLocation);
      setSelectedLocation(newItemLocation);
      setSelectedAvailability(newItemAvailability);
      setSelectedGlobalDate(newItemDate.date);
      setIsShowModalBanner(false);
      setIsBlocking(false);

      if (props.onChange) {
        props.onChange(newItemLocation, newItemAvailability, newItemDate);
      }
    }
  };

  const _renderContent = () => (
    <>
      {isMobile && <Title level={3}>Pickup Location</Title>}
      <Row>
        <AppMap
          height={250}
          coords={locations.map((item, idx) => ({
            onClick: () => {
              setTmpSelectedLocation(item);
            },
            isSelected: tmpSelectedLocation?.locationId === item.locationId,
            isMain: item.locationType === "main_kitchen",
            lat: Number(item?.locationGeoloc?.lat) || 5,
            lng: Number(item?.locationGeoloc?.lng) || 5,
          }))}
          center={
            tmpSelectedLocation?.locationGeoloc &&
            tmpSelectedLocation?.locationGeoloc.lat
              ? {
                  lat: Number(tmpSelectedLocation?.locationGeoloc.lat),
                  lng: Number(tmpSelectedLocation?.locationGeoloc.lng),
                }
              : undefined
          }
        />
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Text
          style={{
            fontSize: "12px",
          }}
        >
          Pickup Point
        </Text>
      </Row>

      <Row>
        <Select
          size='large'
          allowClear
          style={{ width: "100%", borderRadius: "100px" }}
          placeholder='Pickup Location'
          onFocus={() => {
            setIsBlocking(false);
          }}
          onBlur={() => {
            setIsBlocking(true);
          }}
          onChange={(value) => {
            const findOne = locations.find((item) => item.locationId === value);
            if (findOne) {
              setTmpSelectedLocation(findOne);
              //reset value
              setTmpSelectedPickupTime({ ...initialAvailability });
              setTmpSelectedDate(undefined);
              fetchSchedule(findOne.locationId);

              let list = [...listOfDates];
              list = list.map((item) => {
                item.isSelected = false;
                return item;
              });
              setListOfDates(list);
            }
            setIsBlocking(true);
          }}
          defaultValue={tmpSelectedLocation.locationId}
          value={tmpSelectedLocation?.locationId}
        >
          {locations.map((item) => {
            return (
              <Option value={item.locationId} key={item.locationId}>
                <Row>
                  <Text strong style={{ fontWeight: 600, fontSize: "12px" }}>
                    {item.locationName}
                  </Text>
                </Row>
                <Row>
                  <Text style={{ fontSize: "12px" }}>
                    {item.locationAddress}
                  </Text>
                </Row>
              </Option>
            );
          })}
        </Select>

        <Row>
          <Text
            style={{
              color: "#93A1B0",
              fontSize: "12px",
              marginTop: "2%",
            }}
          >
            Select pin from map or select from pickup point list
          </Text>
        </Row>
      </Row>

      <Row wrap gutter={[16, 10]}>
        {listOfDates.map((date) => (
          <Col
            key={date.identifierIndex}
            xs={8}
            md={8}
            lg={6}
            style={{ paddingRight: 5 }}
          >
            <div
              style={{
                padding: "5px 5px 5px 5px",
                border: date.isSelected
                  ? "1px red solid"
                  : "1px lightgrey solid",
                borderRadius: 10,
                backgroundColor: date.isDisabled ? "#F2F2F2" : "transparent",
                cursor: date.isDisabled ? "none" : "pointer",
              }}
              onClick={() => {
                if (date.isDisabled) {
                  return;
                } else if (tmpSelectedLocation.locationId !== "") {
                  setTmpSelectedDate(date);

                  // if (selectedLocation.availabilityPickups.length === 1) {
                  const newTime = tmpSelectedLocation.availabilityPickups[0];
                  setTmpSelectedPickupTime({ ...newTime });

                  let list = [...listOfDates];
                  list = list.map((item) => {
                    if (item.identifierIndex === date?.identifierIndex) {
                      item.isSelected = true;
                      item.availabilityDate = newTime;
                    } else {
                      item.isSelected = false;
                      item.availabilityDate = undefined;
                    }
                    return item;
                  });

                  setListOfDates(list);
                  // }
                  // else {
                  //   setIsShowModalSelectPickupTime(true);
                  // }
                } else {
                  message.info("Please Select Location First");
                }
              }}
            >
              <Row justify='space-between'>
                <Col>
                  <Text
                    style={{
                      color: date.isDisabled ? "darkgrey" : "black",
                    }}
                  >
                    {SHORT_DAYS_IN_WEEK[date.date.getDay()]}{" "}
                    {date.date.getDate()}
                  </Text>
                </Col>
              </Row>
            </div>

            {!!date.availabilityDate && (
              <div
                onClick={() => {
                  let list = [...listOfDates];
                  list = list.map((item) => {
                    if (item.identifierIndex === date.identifierIndex) {
                      item.isSelected = false;
                      item.availabilityDate = undefined;
                    }
                    return item;
                  });
                  setListOfDates(list);
                }}
                style={{
                  padding: 5,
                  position: "absolute",
                  right: 8,
                  top: 0,
                  cursor: "pointer",
                }}
              >
                <CloseCircleOutlined style={{ color: "grey" }} />
              </div>
            )}
          </Col>
        ))}
      </Row>

      <Row>
        <Text
          style={{
            color: "#93A1B0",
            fontSize: "12px",
            marginTop: "2%",
          }}
        >
          Pick a time
        </Text>
      </Row>
      <Row style={{ marginBottom: 20 }}>
        <Select
          size='large'
          style={{ width: "100%", borderRadius: "100px" }}
          placeholder='Pick a time'
          onFocus={() => {
            setIsBlocking(false);
          }}
          onBlur={() => {
            setIsBlocking(true);
          }}
          value={
            tmpSelectedPickupTime.availabilitypickupId
              ? tmpSelectedPickupTime.availabilitypickupId
              : undefined
          }
          onChange={(value) => {
            const findOne = tmpSelectedLocation.availabilityPickups.find(
              (item) => item.availabilitypickupId === value
            );
            if (findOne) {
              setTmpSelectedPickupTime(findOne);
            }
            setIsBlocking(true);
          }}
        >
          {tmpSelectedLocation.availabilityPickups.map((item) => {
            return (
              <Option
                key={item.availabilitypickupId}
                value={item.availabilitypickupId}
              >
                <Text strong style={{ fontWeight: 600, fontSize: "12px" }}>
                  {item.startAt} - {item.endAt}
                </Text>
              </Option>
            );
          })}
        </Select>
        {/* )} */}
      </Row>

      <Button
        disabled={
          !tmpSelectedLocation && !tmpSelectedDate && !tmpSelectedPickupTime
        }
        style={{
          justifyContent: "center",
          alignItems: "center",
          height: "44px",
          backgroundColor: "#FF4A50",
          borderRadius: "100px",
          width: "100%",
          color: "white",
          marginTop: "5%",
        }}
        onClick={() => {
          if (tmpSelectedLocation && tmpSelectedDate && tmpSelectedPickupTime) {
            handleConfirm(
              tmpSelectedLocation,
              tmpSelectedDate,
              tmpSelectedPickupTime
            );
          }
          setIsBlocking(false);
          setIsShowModalBanner(false);
        }}
      >
        Confirm
      </Button>
    </>
  );

  // if (!isShowModalSelectLocation) {
  //   return <></>;
  // }

  if (isMobile) {
    return (
      <BottomSheet
        expandOnContentDrag
        blocking={false}
        open={isShowModalBanner}
        ref={sheetRef}
        onDismiss={() => {
          setIsShowModalBanner(false);
          setIsBlocking(false);
        }}
      >
        <div style={{ padding: "10px 20px" }}>{_renderContent()}</div>
      </BottomSheet>
    );
  } else {
    return (
      <Modal
        title={`Pickup Location`}
        visible={isShowModalBanner}
        footer={null}
        onCancel={() => {
          setIsShowModalBanner(false);
          setIsBlocking(false);
        }}
        width={624}
      >
        {_renderContent()}
      </Modal>
    );
  }
}
