import React from "react";
import {
  Col,
  Divider,
  Row,
  Typography,
  Form,
  Input,
  FormInstance,
  Card,
  message,
  Alert,
} from "antd";
import { IChangePassword, ICustomer } from "../../types/customer.type";
import AppLayout from "../../layout/AppLayout";
import { generateFormRules } from "../../helpers/formRules";
import AppButton from "../../components/AppButton";
import { useHistory } from "react-router-dom";
import axios from "axios";
import useAuth from "../../hooks/useAuth";
import { WarningOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

const ChangePassword: React.FC = () => {
  const formRef = React.useRef<FormInstance<IChangePassword>>(null);
  const history = useHistory();
  const { user } = useAuth();
  const [isSuccess, setIsSuccess] = React.useState<boolean>(false);
  const [isLoadingUserAction, setIsLoadingUserAction] =
    React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed: ", errorInfo);
  };

  const handleCancel = () => {
    history.push("/profile");
  };

  const { token, logout } = useAuth();

  const handleChangePassword = async (values: IChangePassword) => {
    setIsLoadingUserAction(true);
    axios
      .post(
        "/auth/change-password/old-password",
        {
          email: user.email,
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then(() => {
        message.success("Password updated succesfully. Please re-login.");
        setIsLoading(false);

        logout();
      })
      .catch((err) => {
        setIsLoading(false);
        message.error("Failed. " + err);
        formRef.current?.resetFields();
      });
  };

  return (
    <AppLayout hasSidebar isMyAccount>
      <Card>
        <Row>
          <Col>
            <Title level={2}>Change Password</Title>
          </Col>
        </Row>
        <Divider />

        <Form
          ref={formRef}
          name='passwordForm'
          layout='vertical'
          onFinish={handleChangePassword}
          onFinishFailed={onFinishFailed}
          initialValues={{
            oldPassword: "",
            newPassword: "",
          }}
          autoComplete='off'
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label='Old Password'
                name='oldPassword'
                rules={generateFormRules("oldPassword", ["required"])}
              >
                <Input.Password
                  style={{
                    height: "44px",
                  }}
                />
              </Form.Item>
              <Form.Item
                label='New Password'
                name='newPassword'
                rules={generateFormRules("newPassword", [
                  "required",
                  "password",
                ])}
              >
                <Input.Password
                  style={{
                    height: "44px",
                  }}
                />
              </Form.Item>
              <Alert
                showIcon
                icon={
                  <WarningOutlined
                    style={{
                      backgroundColor: "#FFC555",
                      color: "white",
                      borderRadius: "4px",
                      width: "24px",
                      height: "24px",
                      fontSize: "18px",
                      paddingTop: "2px",
                      paddingLeft: "1px",
                    }}
                  />
                }
                closable
                type='warning'
                style={{
                  height: "36px",
                  border: "1px solid #FFC555",
                  borderRadius: "8px",
                  fontSize: "12px",
                }}
                message='Make sure to remember your new password after changed'
              />
              <Divider />
              <Row justify='end'>
                <AppButton
                  onClick={handleCancel}
                  style={{
                    width: "100px",
                    height: "36px",
                    border: "1px solid #C5CED6",
                  }}
                >
                  Cancel
                </AppButton>
                <AppButton
                  loading={isLoadingUserAction}
                  type='primary'
                  style={{
                    width: "100px",
                    height: "36px",
                    marginLeft: "1%",
                  }}
                  onClick={() => formRef?.current?.submit()}
                >
                  Save
                </AppButton>
              </Row>
            </Col>
          </Row>
        </Form>
      </Card>
    </AppLayout>
  );
};

export default ChangePassword;
