import { Col, Row } from 'antd';
import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';

interface Props {
  title?: string;
  subtitle?: string;
}

export default function EmptyData(props: Props) {
  return (
    <Row style={{ marginBottom: 20 }}>
      <Col span={24} style={{ textAlign: 'center', padding: 50 }}>
        <div style={{ padding: 20 }}>
          <SearchOutlined style={{ fontSize: 40, color: 'darkgrey' }} />
        </div>
        <Title level={5} style={{ color: 'darkgrey' }}>
          {props.title || 'There is nothing to show here...'}
        </Title>
        {props.subtitle && (
          <div style={{ textAlign: 'center', opacity: 0.5 }}>
            {props.subtitle}
          </div>
        )}
      </Col>
    </Row>
  );
}
