import { InfoCircleFilled, WarningFilled } from '@ant-design/icons';
import { Tag, Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';
import useOrder, { EOrderType, MenuGroup } from '../hooks/useOrder';
import useWindowSize from '../hooks/useWindowSize';
import useCart from '../hooks/useCart';

type MenuTabsType = {
  onCLick: (date: string) => void;
  dates: MenuGroup[];
  activeTabIdx: number;
  showModalMenu?: boolean;
  freeDelivery?: boolean;
};

const MenuTabs: React.FC<MenuTabsType> = (props) => {
  const { orderType } = useOrder();
  const { freeDeliveryRule } = useCart();
  const { isMobile } = useWindowSize();

  const handleClickItem = (date: string) => {
    props.onCLick(date);
  };

  const orderLimit = (date: string | Date) => {
    return moment(new Date(date))
      .subtract(1, 'days')
      .hours(10)
      .format('dddd H a');
  };

  return (
    <div
      className='menu-tabs-group'
      style={
        (isMobile && props.showModalMenu) || !orderType
          ? { position: 'initial' }
          : { position: 'initial' }
      }
    >
      <div className='menu-tabs-container'>
        {props.dates.map((item, idx) => (
          <MenuTabItem
            key={idx}
            date={item.scheduleAt}
            isActive={idx === props.activeTabIdx}
            onClick={(data) => handleClickItem(data)}
          />
        ))}
      </div>
      <div className='menu-tabs-info'>
        <span>
          {orderType === EOrderType.delivery ? 'Delivery ' : 'Pickup '} for{' '}
          {moment(props.dates[props.activeTabIdx].scheduleAt).format(
            'dddd, DD MMM YYYY'
          )}{' '}
          will be closed on{' '}
          <strong>
            {orderLimit(props.dates[props.activeTabIdx].scheduleAt)}
          </strong>
        </span>
        {freeDeliveryRule && freeDeliveryRule > 0 && (
          <div style={{ marginLeft: 5 }}>
            {' '}
            · Free delivery for orders above ${freeDeliveryRule}
          </div>
        )}
      </div>
    </div>
  );
};

type MenuTabItemType = {
  onClick: (date: string) => void;
  date: string;
  isActive: boolean;
};

export const MenuTabItem: React.FC<MenuTabItemType> = (props) => {
  const { unavailableSchedule, partialDelivery, deliveryPostcode } = useOrder();

  const isUnavailable = unavailableSchedule.find(
    (item) => moment(props.date).format('MMM DD YYYY') === item
  );

  return (
    <div
      onClick={() => props.onClick(props.date)}
      className={
        props.isActive ? 'menu-tab-item menu-tab-item-active' : 'menu-tab-item'
      }
    >
      {/* TODO */}
      {/* Sorry, we do not serve pickup at your location on this date */}
      <span>
        {/* {orderType === EOrderType.delivery
          ? "Delivery on "
          : orderType === EOrderType.pickup
          ? "Pickup on "
          : false} */}
        {moment(props.date).format('dddd, DD MMM YYYY')}
        {isUnavailable && (
          <Tooltip
            placement='right'
            title='Sorry, we do not delivery to your area on this date'
            // title={`Sorry this schedule isn\'t available to delivery to this postcode (${deliveryPostcode})`}
          >
            <WarningFilled style={{ marginLeft: 10 }} color='red' />
          </Tooltip>
        )}
      </span>
    </div>
  );
};

export default MenuTabs;
