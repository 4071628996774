import { CartItem } from '../hooks/useCart';
import { IMenu, initialMenu, IVariant } from './menu.type';

export interface TransactionProps {
  transactionId: string;
  items: Array<TransactionItemProps>;
  // payment: Payment;
  shipment: ShipmentProps;
  transactionStatus: ETransactionStatus;
  transactionCode: string;
}

export interface TransactionItemProps {
  productId: string;
  productVariantId?: string;
  productPrice: number;
  quantity: number;
  metaProduct: IMenu & { variant?: IVariant; menuItems?: IMenu[] };
}

export interface ShipmentProps {
  transactionId: string;
  method: EShipmentMethod;
  shipmentVendorId?: string;
  scheduledAt?: string; // yyyy-mm-dd
  meta: MetaShipmentPickup;
  cost: number;
  waybill?: string;
  status: EShipmentStatus;
}

export enum EShipmentMethod {
  PICKUP = 'PICKUP',
  DELIVERY = 'DELIVERY',
}

export enum ETransactionStatus {
  CREATED = 'CREATED',

  WAITING_PAYMENT = 'WAITING_PAYMENT',
  PAID = 'PAID',

  CANCELED_BY_BUYER = 'CANCELED_BY_BUYER',
  CANCELED_BY_ADMIN = 'CANCELED_BY_ADMIN',
  CANCELED_BY_SYSTEM = 'CANCELED_BY_SYSTEM',

  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED',

  PROCESS = 'PROCESS ',
  READY_FOR_SHIPMENT = 'READY_FOR_SHIPMENT',
  SHIPMENT = 'SHIPMENT',
  ARRIVED = 'ARRIVED',
  COMPLETED = 'COMPLETED',
}

export enum EShipmentStatus {
  CREATED = 'CREATED',
  PROCESS = 'PROCESS',
  DELIVERING = 'DELIVERING',
  ARRIVED = 'ARRIVED',
}

enum EPaymentMethod {
  BANK_TRANSFER = 'BANK_TRANSFER',
  EWALLET = 'EWALLET',
  OUTLET = 'OUTLET',
}

export type MetaShipment =
  | MetaShipmentPickup
  | {
      deliveryFromLocationId: string;
      deliveryFromLocationName: string;

      deliveryToLocationId: string;
      deliveryToLocationName: string;
    };

export type MetaShipmentPickup = {
  pickupFromLocationId: string;
  pickupFromLocationName: string;
  pickupFromLocationAddress?: string;

  pickupTimeStart?: string;
  pickupTimeEnd?: string;

  deliveryAddress?: string;
  deliveryAddressNote?: string;
  deliveryAddressPostcode?: string;
  deliveryAddressState?: string;
  deliveryAddressSuburb?: string;
  deliveryInformation?: string;
  deliveryScheduleTimeframe?: {
    scheduleAt: string;
    selectedTimeframe: string;
  }[];
};

export interface FetchAllTransactionResponse {
  payload: {
    results: TransactionProps[];
  };
}

export const initialTransactionItem: TransactionItemProps = {
  productId: '',
  productVariantId: '',
  productPrice: 0,
  quantity: 0,
  metaProduct: { ...initialMenu },
};

export const initialShipment: ShipmentProps = {
  transactionId: '',
  method: EShipmentMethod.PICKUP,
  shipmentVendorId: '',
  scheduledAt: '', // yyyy-mm-dd
  meta: {
    pickupFromLocationId: '',
    pickupFromLocationName: '',
  },
  cost: 0,
  waybill: '',
  status: EShipmentStatus.CREATED,
};

export const initialTransactions: TransactionProps = {
  transactionId: '',
  items: [initialTransactionItem],
  // payment: Payment;
  shipment: initialShipment,
  transactionStatus: ETransactionStatus.CREATED,
  transactionCode: '',
};

export type ITransaction = {
  buyerId?: string;
  metaBuyer?: any;
  items?: Array<CartItem>; // if items not exist, get from cart

  payment?: {
    paymentMethod: EPaymentMethod;
    paymentVendorId: string;
  };

  shipment: {
    cost: number;
    method: EShipmentMethod;
    scheduledAt?: Date;
    shipmentVendorId: string;
    meta?: any;
    waybill?: string;
  };

  discount?: {
    couponCode?: string;
    discountName: string;
    discountNominal: number;
  };
};

interface CartItemProperties {
  productId: string;
  productVariantId?: string;
  productPrice: number;
  metaProduct: any;
  quantity: number;
}

export type ITransactionGuest = {
  deliveryFee: number;
  groupedId?: string;
  metaBuyer: {
    fullName: string;
    phoneNumber: string;
    email: string;
  };
  items?: Array<CartItemProperties>;
  payment?: {
    paymentMethod: EPaymentMethod;
    paymentVendorId: string;
  };
  shipment: {
    cost: number;
    method: EShipmentMethod;
    scheduledAt?: Date;
    shipmentVendorId: string;
    meta?: any;
    waybill?: string;
  };
  discount?: number;
  metaDiscount?: any;
};
