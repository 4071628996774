import {
  Card,
  Col,
  Divider,
  Input,
  Row,
  Select,
  Switch,
  Typography,
} from 'antd';
import {
  Autocomplete,
  GoogleMap,
  LoadScript,
  Marker,
} from '@react-google-maps/api';
import TextArea from 'antd/lib/input/TextArea';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import { PostcodeProps, StateProps } from '../../components/CheckoutDelivery';
import FormInputCustom from '../../components/FormInputCustom';
import { httpRequest } from '../../helpers/api';
import { generateQueryString } from '../../helpers/generateQueryString';
import AppLayout from '../../layout/AppLayout';
import { CustomerAddressProperties } from '../../types/address.type';

const { Title, Text } = Typography;

interface IParams {
  addressId: string;
}

const onLoadMarker = (marker: any) => {};

const EditMyAddress = () => {
  const { addressId } = useParams<IParams>();
  const history = useHistory();
  const [states, setStates] = useState<StateProps[]>([]);
  const [tmpPostcodes, setTmpPostcodes] = useState<PostcodeProps[]>([]);
  const [postcodes, setPostcodes] = useState<PostcodeProps[]>([]);
  const [suburbs, setSuburb] = useState<PostcodeProps[]>([]);
  const [tmpSuburbs, setTmpSuburb] = useState<PostcodeProps[]>([]);

  const [currPostcode, setCurrPostcode] = useState<string>('');
  const [currSuburb, setCurrSuburb] = useState<string>('');
  const [currState, setCurrState] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [addressNote, setAddressNote] = useState<string>('');
  const [label, setLabel] = useState<string>('');
  const [isMainAddress, setIsMainAddress] = useState<boolean>(false);

  const handleChangeState = async (stateId: string) => {
    setCurrState(stateId);
    setCurrPostcode('');
    setCurrSuburb('');
    await fetchPostcodeByStateId(stateId);
  };

  const handleChangePostcode = (value: string) => {
    setCurrPostcode(value);
    setCurrSuburb('');
    if (tmpPostcodes.length > 0) {
      const currSuburbs = tmpPostcodes.filter(
        (item) => item.postcode === value
      );
      setSuburb(currSuburbs);
    }
  };

  const handleChangeSuburb = (value: string) => {
    setCurrSuburb(value);
  };

  const fetchPostcodeByStateId = async (stateId: string) => {
    const res = await httpRequest.get<{ payload: PostcodeProps[] }>(
      'postcodes' +
        generateQueryString({
          stateId,
        })
    );
    if (res && res.data && res.data.payload && res.data.payload.length > 0) {
      setTmpPostcodes(res.data.payload);
      setPostcodes(_.uniqBy(res.data.payload, 'postcode'));
    }
  };

  const fetchSuburbByPostcode = async (postcodeNumbers: string[]) => {
    const res = await httpRequest.get<{ payload: PostcodeProps[] }>(
      'postcodes' +
        generateQueryString({
          postcodeNumbers,
        })
    );
    if (res && res.data && res.data.payload && res.data.payload.length > 0) {
      setTmpSuburb(res.data.payload);
    }
  };

  const fetchStates = async () => {
    const res = await httpRequest.get<{ payload: StateProps[] }>(
      'postcodes/get-all-state'
    );
    if (res && res.data && res.data.payload && res.data.payload.length > 0) {
      setStates(res.data.payload);
    }
  };

  const fetchAddress = async () => {
    const res = await httpRequest.get<{ payload: CustomerAddressProperties }>(
      'customer-addresses/' + addressId
    );
    if (res && res.data && res.data.payload) {
      await fetchPostcodeByStateId(res.data.payload.state);

      setLabel(res.data.payload.label || '');
      setAddress(res.data.payload.address || '');
      setAddressNote(res.data.payload.note || '');
      setIsMainAddress(res.data.payload.isMainAddress || false);
      setCurrState(res.data.payload.state || '');
      setCurrSuburb(res.data.payload.suburb || '');
      setCurrPostcode(res.data.payload.postCode || '');
      if (res.data.payload.geoLoc) {
        setCoords(res.data.payload.geoLoc);
      }
    }
  };

  useEffect(() => {
    if (addressId) {
      fetchAddress();
    }
  }, [addressId]);

  useEffect(() => {
    fetchStates();
  }, []);

  useEffect(() => {
    if (postcodes.length > 0 && addressId && currPostcode) {
      const suburbs = postcodes.filter(
        (item) => item.postcode === currPostcode
      );
      setSuburb(suburbs);
    }
  }, [postcodes, currPostcode]);

  const handleSubmitAddress = async () => {
    try {
      const res = await httpRequest.post<any>('customer-addresses', {
        label: label,
        suburb: currSuburb,
        state: currState,
        city: '',
        postCode: currPostcode,
        geoLoc: {
          lat: coords.lat,
          lng: coords.lng,
        },
        note: addressNote,
        isMainAddress: isMainAddress,
        isPublished: true,
        address: address,
      });
      if (res && res.data && res.data.payload) {
        history.goBack();
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const handleEditAddress = async () => {
    try {
      const res = await httpRequest.patch<any>(
        'customer-addresses/' + addressId,
        {
          label: label,
          suburb: currSuburb,
          state: currState,
          city: '',
          postCode: currPostcode,
          geoLoc: {
            lat: coords.lat,
            lng: coords.lng,
          },
          note: addressNote,
          isMainAddress: isMainAddress,
          isPublished: true,
          address: address,
        }
      );
      if (res && res.data && res.data.payload) {
        history.goBack();
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const handleSubmit = () => {
    if (addressId) {
      handleEditAddress();
    } else {
      handleSubmitAddress();
    }
  };

  const [autocomplete, setAutocomplete] = useState<any>();
  const [map, setMap] = useState<any>();
  const [coords, setCoords] = useState<any>();

  const onLoad = (a: any) => {
    setAutocomplete(a);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      setCoords({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
      setAddress(place.formatted_address);
    } else {
      console.log('Autocomplete is not loaded yet!');
    }
  };

  const handleData = (e: any) => {
    console.log('ee', e);
  };

  const handleClickMap = (e: any) => {
    console.log('handleClickMap', { lat: e.latLng.lat(), lng: e.latLng.lng() });
    setCoords({ lat: e.latLng.lat(), lng: e.latLng.lng() });
    map.setCenter({ lat: e.latLng.lat(), lng: e.latLng.lng() });
  };

  return (
    <AppLayout hasSidebar>
      <Card>
        <Row justify='space-between'>
          <Col>
            <Title level={2}>{addressId ? 'Edit' : 'Add'} Address</Title>
          </Col>
          <Row>
            <Col>
              <AppButton onClick={() => history.goBack()} block type='text'>
                Cancel
              </AppButton>
            </Col>
            <Col>
              <AppButton onClick={() => handleSubmit()} block type='primary'>
                {addressId ? 'Update' : 'Add New'} Address
              </AppButton>
            </Col>
          </Row>
        </Row>
        <Divider />

        <Row gutter={10}>
          <Col span={24}>
            <FormInputCustom label='Label'>
              <Input
                style={{ width: '100%' }}
                placeholder='Enter label'
                size='large'
                value={label}
                onChange={(e) => setLabel(e.target.value)}
              />
            </FormInputCustom>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col span={24}>
            <FormInputCustom label='Is Main Address'>
              <Switch
                checked={isMainAddress}
                onChange={(e) => setIsMainAddress(e)}
              />
            </FormInputCustom>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col span={24}>
            <FormInputCustom label='Address'>
              <div className='checkout-info-container'>
                <LoadScript
                  libraries={['places']}
                  region='AU'
                  googleMapsApiKey={process.env.REACT_APP_MAP_API_KEY as string}
                >
                  <GoogleMap
                    id='checkout-map-delivery'
                    mapContainerStyle={{
                      height: 300,
                      width: '100%',
                    }}
                    center={
                      coords || {
                        lat: -33.86945338595781,
                        lng: 151.16476129339597,
                      }
                    }
                    zoom={15}
                    options={{
                      // streetViewControl: false,
                      disableDefaultUI: true,
                    }}
                    onLoad={(map) => setMap(map)}
                    onClick={handleClickMap}
                  >
                    <Autocomplete
                      onLoad={onLoad}
                      onPlaceChanged={onPlaceChanged}
                      restrictions={{ country: 'AU' }}
                    >
                      <input
                        defaultValue={address}
                        type='text'
                        placeholder='Enter your delivery address...'
                        style={{
                          boxSizing: `border-box`,
                          border: `1px solid transparent`,
                          width: `95%`,
                          height: `42px`,
                          padding: `0 12px`,
                          borderRadius: `3px`,
                          boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                          fontSize: `14px`,
                          outline: `none`,
                          textOverflow: `ellipses`,
                          position: 'absolute',
                          left: '0',
                          right: '0',
                          top: '10px',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                        }}
                      />
                      {/* setDataCustomer({ ...dataCustomer, address: e.target.value }) */}
                    </Autocomplete>
                    {coords && (
                      <Marker
                        onDragEnd={handleData}
                        position={coords}
                        onLoad={onLoadMarker}
                      />
                    )}
                  </GoogleMap>
                </LoadScript>
              </div>
              <div>
                Search your address and set your precise location with click on
                map
              </div>
            </FormInputCustom>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col span={24}>
            <FormInputCustom label='Address Note'>
              <TextArea
                placeholder='Write your address note'
                rows={5}
                value={addressNote}
                onChange={(e) => setAddressNote(e.target.value)}
              />
            </FormInputCustom>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col span={24}>
            <FormInputCustom label='State'>
              <Select
                value={currState}
                showSearch
                disabled={states.length === 0}
                size='large'
                options={states.map((item) => ({
                  label: item.stateName,
                  value: item.stateId,
                }))}
                filterOption={(input, option) =>
                  (option?.label ?? ('' as any))
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                style={{ width: '100%' }}
                onChange={handleChangeState}
                placeholder='Select state'
              />
            </FormInputCustom>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col span={12}>
            <FormInputCustom label='Postcode'>
              <Select
                value={currPostcode}
                showSearch
                disabled={postcodes.length === 0}
                size='large'
                options={postcodes.map((item) => ({
                  label: item.postcode,
                  value: item.postcode,
                }))}
                filterOption={(input, option) =>
                  (option?.label ?? ('' as any))
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                style={{ width: '100%' }}
                onChange={handleChangePostcode}
                placeholder='Search postcode'
              />
            </FormInputCustom>
          </Col>
          <Col span={12}>
            <FormInputCustom label='Suburb'>
              <Select
                showSearch
                disabled={postcodes.length === 0 || !currPostcode}
                size='large'
                value={currSuburb}
                options={suburbs.map((item) => ({
                  label: item.suburbName,
                  value: item.suburbName,
                }))}
                filterOption={(input, option) =>
                  (option?.label ?? ('' as any))
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                style={{ width: '100%' }}
                onChange={handleChangeSuburb}
                placeholder='Select suburb'
              />
            </FormInputCustom>
          </Col>
        </Row>
      </Card>
    </AppLayout>
  );
};

export default EditMyAddress;
