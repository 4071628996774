import React, { useEffect, useState } from 'react';
import AppLayout from '../layout/AppLayout';
import { IFAQ } from '../types/faq.type';
import { Button, Collapse, Form, Input } from 'antd';
import { RightOutlined, SearchOutlined } from '@ant-design/icons';
import useFetchList from '../hooks/useFetchList';
import styled from 'styled-components';
import Title from 'antd/lib/typography/Title';
import IconTudung from '../components/icons/iconTudung';
import { Link, useHistory } from 'react-router-dom';
import EmptyData from '../components/EmptyData';
import useWindowSize from '../hooks/useWindowSize';

const { Panel } = Collapse;

const FAQ: React.FC = () => {
  const { isMobile } = useWindowSize();
  const history = useHistory();
  const [activeIds, setActiveIds] = useState([]);
  const {
    data: faqs,
    setSearch,
    changePage,
    pagination,
  } = useFetchList<IFAQ>({ endpoint: 'faqs', limit: 100 });

  useEffect(() => {
    if (pagination.next) {
      changePage(pagination.page + 1);
    }
  }, [faqs]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <AppLayout
      isHomepage
      showPageHeader={true}
      pageHeaderTitle='Frequently asked questions'
      componentUnderSubtitle={
        <div
          style={{
            // paddingLeft: isMobile ? 0 : "20%",
            // paddingRight: isMobile ? 0 : "20%",
            textAlign: 'center',
            width: '40%',
          }}
        >
          <Form
            name='form'
            layout='vertical'
            initialValues={{
              search: '',
            }}
            autoComplete='off'
            style={{ width: '100%' }}
          >
            <Form.Item name='search' rules={[]}>
              <Input
                style={{ borderRadius: 8, width: '100%' }}
                size='large'
                placeholder='What are you looking for?'
                prefix={<SearchOutlined />}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Form.Item>
          </Form>
        </div>
      }
    >
      <div className='homepage-section'>
        {faqs.length === 0 && <EmptyData />}
        <Collapse
          bordered={false}
          defaultActiveKey={activeIds}
          expandIcon={({ isActive }) => (
            <RightOutlined rotate={isActive ? 90 : 0} />
          )}
          expandIconPosition='left'
          className='site-collapse-custom-collapse'
          // onChange={(key) => setActiveIds(key)}
        >
          {faqs.map((faq) => (
            <Panel
              header={faq.question}
              key={faq.faqId}
              className='site-collapse-custom-panel'
            >
              <p>{faq.answer}</p>
            </Panel>
          ))}
        </Collapse>
        {/* <CustomDiv>
          <Title level={3} style={{ color: "#FFFFFF" }}>
            Can’t find what you are looking for?
          </Title>

          <div>
            <Button
              size='large'
              style={{ borderRadius: 20 }}
              onClick={() => {
                history.push("/contact-us");
              }}
            >
              Contact Us
            </Button>
          </div>

          {!isMobile && (
            <div
              style={{
                width: 40,
                height: 40,
                borderRadius: 5,
                background: "#FFC555",
                textAlign: "center",
                color: "#FFFFFF",
                fontWeight: "bold",
                fontSize: 25,
                position: "absolute",
                left: -20,
                top: "30%",
                transform: "rotate(-12.55deg)",
              }}
            >
              ?
            </div>
          )}

          {!isMobile && (
            <div
              style={{
                width: 40,
                height: 40,
                borderRadius: 5,
                background: "#FFC555",
                textAlign: "center",
                color: "#FFFFFF",
                fontWeight: "bold",
                fontSize: 25,
                position: "absolute",
                right: isMobile ? 20 : 70,
                top: isMobile ? "50%" : "30%",
                transform: "rotate(-12.55deg)",
              }}
            >
              <IconTudung />
            </div>
          )}
        </CustomDiv> */}
      </div>

      <div className='section-cta'>
        <div className='bottom-banner-wrapper'>
          <h1>Ready to try our meals?</h1>
          <Link to='menu' className='bottom-banner-link'>
            <img alt='menu-icon' src='./images/tudung.svg' />
            <span>Yes Please!</span>
          </Link>
        </div>
      </div>
    </AppLayout>
  );
};

export default FAQ;

const CustomDiv = styled.div`
  background: ${({ theme }) =>
    'url(/images/bg-circle.svg) no-repeat center ' + theme.colors.primary};
  background-size: cover;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 250px;
  border-radius: 20px;
  position: relative;
  padding: 10px;
`;
