import React, { useEffect } from 'react';
import { httpRequest } from '../helpers/api';
import AppLayout from '../layout/AppLayout';
import {
  Col,
  message,
  Row,
  Typography,
  Space,
  Form,
  Input,
  FormInstance,
  Divider,
} from 'antd';
import {
  EnvironmentOutlined,
  FacebookOutlined,
  InstagramOutlined,
  MailOutlined,
  PhoneOutlined,
  TwitterOutlined,
} from '@ant-design/icons';
import { generateFormRules } from '../helpers/formRules';
import { ICustomerMessage } from '../types/customerMessage.type';
import TextArea from 'antd/lib/input/TextArea';
import AppButton from '../components/AppButton';
import AppMap from '../components/AppMap';
import useGlobalConfig from '../hooks/useGlobalConfig';
import useWindowSize from '../hooks/useWindowSize';
import {
  GoogleMap,
  LoadScript,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api';

const { Title, Text } = Typography;

const ContactUs: React.FC = () => {
  const { isMobile } = useWindowSize();
  const { isLoadingAboutUs, aboutUs } = useGlobalConfig();

  const formRef =
    React.useRef<
      FormInstance<Omit<ICustomerMessage, 'createdAt' | 'updatedAt'>>
    >(null);

  const [isLoadingSubmit, setIsLoadingSubmit] = React.useState<boolean>(false);

  const handleSubmit = async (
    values: Omit<ICustomerMessage, 'createdAt' | 'updatedAt'>
  ) => {
    setIsLoadingSubmit(true);
    await httpRequest.post('customer-messages', values);
    message.success("Thank you - we'll be in touch");

    formRef.current?.resetFields();
    setIsLoadingSubmit(false);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY as string,
  });

  return (
    <AppLayout
      showPageHeader={!isMobile}
      pageHeaderTitle={isMobile ? undefined : 'Chat to us'}
      pageHeaderSubtitle={
        isMobile
          ? undefined
          : 'Any question or remarks? Fill out the form below or send us an email!'
      }
    >
      <div className='homepage-section'>
        <Row gutter={16}>
          <Col xs={24} md={11} lg={11}>
            {!isLoadingAboutUs && isLoaded ? (
              <GoogleMap
                id='about-us-map'
                mapContainerStyle={{
                  height: 300,
                  width: '100%',
                }}
                center={{
                  ...aboutUs.officeCoords,
                }}
                zoom={15}
                options={{
                  disableDefaultUI: true,
                }}
                // onLoad={(map) => setMap(map)}
              >
                <Marker position={{ ...aboutUs.officeCoords }} />
              </GoogleMap>
            ) : (
              false
            )}

            <div style={{ marginTop: 20 }}>
              <Title level={3}>{aboutUs?.officeTitle}</Title>
              <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                <EnvironmentOutlined
                  style={{ color: '#FF4A50', fontSize: 18 }}
                />
                <span style={{ color: 'grey', marginLeft: 5 }}>
                  {' '}
                  {aboutUs?.officeAddress}
                </span>
              </div>
              <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                <PhoneOutlined style={{ color: '#FF4A50', fontSize: 18 }} />
                <span style={{ color: 'grey', marginLeft: 5 }}>
                  {' '}
                  {aboutUs?.contact}
                </span>
              </div>
              <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                <MailOutlined style={{ color: '#FF4A50', fontSize: 18 }} />
                <span style={{ color: 'grey', marginLeft: 5 }}>
                  {' '}
                  {aboutUs?.officeEmail}
                </span>
              </div>
            </div>

            <Space size='middle' style={{ marginTop: 10 }}>
              {!!aboutUs.instagramLink && (
                <a target='_blank' href={aboutUs.instagramLink}>
                  <InstagramOutlined style={{ fontSize: 20, color: 'grey' }} />
                </a>
              )}
              {!!aboutUs.twitterLink && (
                <a target='_blank' href={aboutUs.twitterLink}>
                  <TwitterOutlined style={{ fontSize: 20, color: 'grey' }} />
                </a>
              )}
              {!!aboutUs.facebookLink && (
                <a target='_blank' href={aboutUs.facebookLink}>
                  <FacebookOutlined style={{ fontSize: 20, color: 'grey' }} />
                </a>
              )}
              {/* {!!aboutUs.youtubeLink &&
            <a target="_blank" href={aboutUs.youtubeLink}>
              <YoutubeOutlined style={{ fontSize: 20, color: 'grey' }} />
            </a>} */}
            </Space>
          </Col>

          {isMobile ? (
            <div
              style={{
                padding: '0px 10px',
                textAlign: 'center',
                marginBottom: 20,
              }}
            >
              <Divider />
              <Text style={{ textAlign: 'center', fontSize: 14 }}>
                Any question or remarks? Just write us message. Fill out the
                form below or send us an email!
              </Text>
            </div>
          ) : (
            <Col lg={2} style={{ textAlign: 'center' }}>
              <Divider
                type='vertical'
                style={{ borderLeftWidth: 1, height: '100%' }}
              />
            </Col>
          )}

          {/** FORM CONTACT US */}
          <Col xs={24} md={11} lg={11}>
            <Form
              ref={formRef}
              name='profileForm'
              layout='vertical'
              onFinish={handleSubmit}
            >
              <Form.Item
                label='Full Name'
                name='fullName'
                rules={generateFormRules('Full Name', ['required'])}
              >
                <Input placeholder='Enter your full name' />
              </Form.Item>
              <Form.Item
                label='Email'
                name='email'
                rules={generateFormRules('Email', ['required', 'email'])}
              >
                <Input placeholder='Enter your email' />
              </Form.Item>
              <Form.Item
                label='Subject'
                name='subject'
                rules={generateFormRules('Subject', ['required'])}
              >
                <Input placeholder='Enter your subject' />
              </Form.Item>
              <Form.Item
                label='Message'
                name='message'
                rules={generateFormRules('Name', ['required'])}
              >
                <TextArea rows={4} placeholder='Enter your message here...' />
              </Form.Item>
            </Form>

            <AppButton
              style={{ width: '100%' }}
              loading={isLoadingSubmit}
              type='primary'
              onClick={() => formRef?.current?.submit()}
            >
              Send Us A Message
            </AppButton>
          </Col>
        </Row>
      </div>
    </AppLayout>
  );
};

export default ContactUs;
