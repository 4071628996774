import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Form,
  Input,
  Layout,
  Row,
  message,
  Modal,
  FormInstance,
  Checkbox,
  Drawer,
  Divider,
  Avatar,
  Alert,
} from 'antd';
import {
  LockOutlined,
  MailOutlined,
  UserOutlined,
  PhoneOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import PageHeader from '../components/PageHeader';
import { Link, useHistory, useLocation } from 'react-router-dom';
import AppButton from '../components/AppButton';
import Text from 'antd/lib/typography/Text';
import { httpRequest } from '../helpers/api';
import Title from 'antd/lib/typography/Title';
import { generateFormRules } from '../helpers/formRules';
import { getErrorMessage } from '../helpers/errorHandler';
import useModalAuth from '../hooks/useModalAuth';
import useAuth from '../hooks/useAuth';
import { IHttpResponse } from '../helpers/pagination';
import { IAuthResponsePayload } from '../types/auth.type';
import SidebarMenu from '../components/SidebarMenu';
import useWindowSize from '../hooks/useWindowSize';
import MenuIcon from '../components/MenuIcon';
import { Menu as Ham, X, ChevronRight, ShoppingBag } from 'react-feather';
import AppFooter from './AppFooter';
import useCart from '../hooks/useCart';
import SignInOrProfile from '../components/SignInOrProfile';
import ModalOrderCreated from '../components/ModalOrderCreated';
import axios from 'axios';
import { ICustomer } from '../types/customer.type';
import useGlobalSearch from '../hooks/useGlobalSearch';
import useGlobalConfig from '../hooks/useGlobalConfig';
import { generateInitialFromName } from '../helpers/generator';
import useGlobalFilter from '../hooks/useGlobalFilter';
import { appVersion, thisYear } from '../helpers/constant';
import useOrder, { EOrderType } from '../hooks/useOrder';
import ModalCart2 from '../components/ModalCart2';
import CustomTabs from '../components/CustomTabs';
import { AppConfigProps, BaseResponseProps } from '../types/config.type';
import UserAvatar from '../components/UserAvatar';
import moment from 'moment';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { FetchAllAppConfigResponse } from '../types/appConfig.type';

const { Content } = Layout;

const headerMenus = [
  {
    key: '/menu',
    label: 'Order Now',
  },
  {
    key: '/#how-it-works',
    label: 'How it Works',
  },
  {
    key: 'faqs',
    label: 'FAQs',
  },
  {
    key: 'contact-us',
    label: 'Contact Us',
  },
  {
    key: '/about-us',
    label: 'About Us',
  },
];

const signInMenus = [
  {
    key: 'orders',
    label: 'My Orders',
  },
  {
    key: 'profile',
    label: 'My Profile',
  },
  {
    key: 'sign-out',
    label: 'Sign Out',
  },
];

type AdditionalProps = {
  showPageHeader?: boolean;
  pageHeaderTitle?: string;
  pageHeaderSubtitle?: string;
  showBanner?: boolean;
  hasSidebar?: boolean;
  isHomepage?: boolean;
  componentUnderSubtitle?: any;
  isMenu?: boolean;
  isMyAccount?: boolean;
};

export interface CheckCustomerEmailConfirm extends BaseResponseProps {
  payload: {
    isVerify: boolean;
  };
}

const AppLayout: React.FC<AdditionalProps> = ({
  children,
  showPageHeader,
  pageHeaderTitle,
  pageHeaderSubtitle,
  hasSidebar,
  isHomepage,
  componentUnderSubtitle,
  isMenu,
  isMyAccount,
}) => {
  const location = useLocation();
  const { aboutUs, init } = useGlobalConfig();
  const {
    tmpOrderType,
    orderType,
    schedules,
    dataForOrderCreatedModal,
    isShowModalOrderCreated,
    dataPromo,
    setIsShowModalOrderCreated,
    setIsShowModalOrderType,
    setTmpOrderType,
    setDataPromo,
    scheduleDeliveries,
  } = useOrder();
  const { globalKeyword, setGlobalKeyword } = useGlobalSearch();
  const history = useHistory();
  const { isLoggedIn, login, user, logout, getProfile, getMembershipDiscount } =
    useAuth();
  const { width, isMobile } = useWindowSize();
  const {
    isShowModalCart,
    carts,
    localCarts,
    unavailableCartItems,
    isShowModalCartWarning,
    setIsShowModalCart,
    cartInit,
    checkCartItems,
    setIsShowModalCartWarning,
    removeCartItems,
    removeAllCartItems,
    checkRemoveAllCartItems,
    subTotal,
    setMinimumOrderRule,
    setFreeDeliveryRule,
    constructCart,
  } = useCart();

  const [loadingEmailConfirm, setLoadingEmailConfirm] =
    React.useState<boolean>(false);
  const [isMyEmailConfrimed, setIsMyEmailConfirmed] =
    React.useState<boolean>(true);

  const isPathnameForCustomer = useMemo(() => {
    if (
      location.pathname.includes('profile') ||
      location.pathname.includes('orders') ||
      location.pathname.includes('address')
    ) {
      return true;
    }

    return false;
  }, [location]);

  const checkEmailConfirm = React.useCallback(async () => {
    if (user.email) {
      setLoadingEmailConfirm(true);
      try {
        const result = await httpRequest.post<CheckCustomerEmailConfirm>(
          '/auth/check-email-verify',
          {
            email: user.email,
          }
        );
        setIsMyEmailConfirmed(result.data.payload.isVerify);
      } catch (error) {
        console.error('failed check email confirm', error);
      } finally {
        setLoadingEmailConfirm(false);
      }
    }
  }, [user]);

  const onVerifyMyEmail = async () => {
    setLoadingEmailConfirm(true);
    try {
      const result = await httpRequest.post<CheckCustomerEmailConfirm>(
        '/auth/send-email-verification',
        {
          email: user.email,
        }
      );
      setIsMyEmailConfirmed(result.data.payload.isVerify);
      message.success(
        'Success send verification link. Please check your email.',
        8
      );
    } catch (error) {
      console.error('failed send email verification', error);
      message.warning('Failed send verification link');
    } finally {
      setLoadingEmailConfirm(false);
    }
  };

  React.useEffect(() => {
    if (user.email) {
      checkEmailConfirm();
    }
  }, [user.email]);

  React.useEffect(() => {
    if (isLoggedIn) {
      getProfile();
      getMembershipDiscount();
    }
  }, [isLoggedIn]);

  const formRef =
    React.useRef<FormInstance<{ email: string; password: string }>>(null);

  const formRefRegister = React.useRef<
    FormInstance<{
      fullName: string;
      phoneNumber: string;
      email: string;
      password: string;
    }>
  >(null);

  const {
    isShowModalLogin,
    setIsShowModalLogin,
    isShowModalRegister,
    setIsShowModalRegister,
  } = useModalAuth();
  const [isLoadingLogin, setIsLoadingLogin] = React.useState(false);
  const [isLoadingRegister, setIsLoadingRegister] = React.useState(false);
  const [isAgreeSignUp, setIsAgreeSignUp] = React.useState(false);
  const [isShowDrawer, setIsShowDrawer] = React.useState(false);
  const { selectedLocation, setIsShowModalSelectLocation } = useGlobalFilter();
  const [showBadgePromo, setBadgePromo] = React.useState<boolean>(true);
  const [showModalPromo, setShowModalPromo] = React.useState<boolean>(false);

  const toggleDrawer = (value: boolean) => {
    setIsShowDrawer(value);
  };

  // scroll to top
  const refHeader = React.useRef<any>();
  React.useEffect(() => {
    if (!location.hash && refHeader && refHeader.current) {
      refHeader.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);
  // scroll to top

  React.useEffect(() => {
    init();
  }, []);

  const onSubmitLogin = async (values: { email: string; password: string }) => {
    try {
      setIsLoadingLogin(true);
      const res = await axios.post<IHttpResponse<IAuthResponsePayload>>(
        'auth/signin',
        { email: values.email, password: values.password }
      );

      const resProfile = await axios.get<IHttpResponse<ICustomer>>(
        'customers/me',
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + res.data.payload.token,
          },
        }
      );

      setIsLoadingLogin(false);

      login({
        token: res.data.payload.token,
        user: resProfile.data.payload,
      });

      // message.success('Login successfully');

      setIsShowModalRegister(false);
      setIsShowModalLogin(false);
    } catch (err) {
      message.error('Login failed. ' + getErrorMessage(err));
      setIsLoadingLogin(false);
    } finally {
      formRef.current?.resetFields();
    }
  };

  const onSubmitRegister = (values: {
    fullName: string;
    phoneNumber: string;
    email: string;
    password: string;
  }) => {
    setIsLoadingRegister(true);
    httpRequest
      .post('customers/register', {
        fullName: values.fullName,
        phoneNumber: values.phoneNumber,
        email: values.email,
        password: values.password,
      })
      .then(async (res) => {
        console.info('res', res.data);
        setIsLoadingRegister(false);

        // message.success('Register successfully');

        setIsShowModalRegister(false);
        setIsShowModalLogin(false);

        await onSubmitLogin({
          email: values.email,
          password: values.password,
        });

        setShowModalPromo(false);
        localStorage.setItem('promo', JSON.stringify(false));
        localStorage.setItem(
          'promoClosed',
          JSON.stringify(moment().format('YYYY-MM-DD'))
        );
      })
      .catch((err) => {
        message.error(getErrorMessage(err));
        setIsLoadingRegister(false);
      })
      .finally(() => {
        formRefRegister.current?.resetFields();
      });
  };

  const handleClickOpenCart = async () => {
    if (isLoggedIn) {
      setIsShowModalCart(!isShowModalCart);
      cartInit();
    } else {
      setIsShowModalCart(true);
    }
  };

  const isCurrentMenu = (menu: any) => {
    return (
      location.pathname.includes(menu.key) ||
      (location.pathname + location.hash).includes(menu.key)
    );
  };

  const handleOpenModalOrderType = async (type: EOrderType) => {
    if (type === EOrderType.delivery) {
      setTmpOrderType(type);
    } else if (type === EOrderType.pickup) {
      // setCurrSelectedLocation(undefined);
      setTmpOrderType(type);
    }

    if (localCarts && localCarts.length > 0) {
      const res = checkRemoveAllCartItems();
      if (res === 0) {
        setIsShowModalOrderType(true);
      } else {
        setIsShowModalCartWarning(true);
      }
    } else {
      setIsShowModalOrderType(true);
    }
  };

  const handleRemoveCartItems = () => {
    removeAllCartItems();
    handleCancelRemoveCartItems();
    setIsShowModalOrderType(true);
  };

  const handleCancelRemoveCartItems = () => {
    setIsShowModalCartWarning(false);
  };

  useEffect(() => {
    const loc = location.pathname.includes('checkout');

    if (loc && !orderType && !localCarts) {
      history.replace('/menu');
    }
  }, [location]);

  const local = localStorage.getItem('promo');

  useEffect(() => {
    if (scheduleDeliveries.length) {
      constructCart();
      console.log('triggered');
    }
  }, [localCarts, scheduleDeliveries]);

  useEffect(() => {
    const fetchAppConfig = async () => {
      const res = await httpRequest.get<FetchAllAppConfigResponse>(
        'app-configs?keys=MINIMUM_ORDER,FREE_DELIVERY,NEW_CUSTOMER_DISCOUNT'
      );
      if (res.data.payload.results) {
        res.data.payload.results.forEach((item) => {
          if (item.key === 'MINIMUM_ORDER') {
            setMinimumOrderRule(
              parseInt(item.value) ? parseInt(item.value) : 0
            );
          }
          if (item.key === 'FREE_DELIVERY') {
            setFreeDeliveryRule(
              parseInt(item.value) ? parseInt(item.value) : 0
            );
          }
          // if (local) {
          //   // const a: boolean = JSON.parse(local);
          //   // if (!a) {
          //   // }
          //   // localStorage.setItem("promo", JSON.stringify(true));
          // }
          if (item.key === 'NEW_CUSTOMER_DISCOUNT') {
            setDataPromo(item);
          }
        });
      }
    };
    fetchAppConfig();
  }, []);

  useEffect(() => {
    const datePromoClosed = localStorage.getItem('promoClosed');
    const dateAddToCart = localStorage.getItem('dateAddToCart');
    let diffPromo = 0;
    let diffAddToCart = 0;

    if (dateAddToCart) {
      diffAddToCart = moment(dateAddToCart).diff(moment(), 'days');
      if (diffAddToCart < 0) {
        removeAllCartItems();
        localStorage.removeItem('location');
        localStorage.removeItem('orderType');
        localStorage.removeItem('partialDelivery');
        localStorage.removeItem('dateAddToCart');
        localStorage.removeItem('dataUnavailableSchedule');
        localStorage.removeItem('deliveryPostcode');
        setTmpOrderType(undefined);
      }
    }

    if (dataPromo && !location.pathname.includes('menu')) {
      if (datePromoClosed) {
        diffPromo = moment(datePromoClosed).diff(moment(), 'days');
        if (diffPromo < 0) {
          localStorage.setItem('promo', JSON.stringify(true));
          localStorage.removeItem('promoClosed');
          setShowModalPromo(true);
        }
      } else if (local) {
        const a: boolean = JSON.parse(local);
        setShowModalPromo(a);
      } else {
        localStorage.setItem('promo', JSON.stringify(true));
      }
    }
  }, [local, dataPromo]);

  const tawkMessengerRef: any = useRef();
  const [showWidget, setShowWidget] = useState(true);

  const handleToggleWidget = () => {
    if (showWidget) {
      tawkMessengerRef.current.hideWidget();
    } else {
      tawkMessengerRef.current.showWidget();
    }
    setShowWidget(!showWidget);
  };

  const locationToShowMobileCart = useMemo(() => {
    if (location.pathname.includes('checkout')) {
      return false;
    }

    return true;
  }, [location]);

  return (
    <Layout
      className={'layout'}
      style={{
        position: 'relative',
        overflowX: isHomepage ? 'unset' : 'unset',
      }}
    >
      <header
        ref={refHeader}
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 15,
          background: '#fff',
          border:
            location.pathname.includes('/checkout') ||
            location.pathname.includes('/menu')
              ? '1px solid #F1F3F5'
              : 'none',
        }}
      >
        {/* drawer mobile */}
        <Drawer
          closable={false}
          placement='left'
          onClose={() => toggleDrawer(false)}
          visible={isShowDrawer}
        >
          <Link
            className='drawer-nav'
            to='#'
            onClick={() => {
              toggleDrawer(false);
            }}
          >
            <img
              alt='logo-spoonful'
              src={'/images/logo-spoonful.svg'}
              height={40}
              style={{ maxHeight: 45, maxWidth: 200 }}
            />
            <X onClick={() => toggleDrawer(false)} />
          </Link>
          <div className='drawer-menu'>
            <Link to='/'>Home</Link>
            {headerMenus.map((item) => (
              <Link key={item.key} to={item.key}>
                {item.label}
              </Link>
            ))}
          </div>

          <Divider />

          {/* TODO: Get auth status */}

          <div className='user-info-container'>
            {isLoggedIn ? (
              <div>
                <div className='user-info-wrapper'>
                  <Avatar
                    style={{
                      backgroundColor: '#e5e9ec',
                      verticalAlign: 'middle',
                    }}
                    size='large'
                  >
                    {generateInitialFromName(user.fullName)}
                  </Avatar>
                  <div className='user-info'>
                    <span>Welcome back,</span>
                    <h4>{user.fullName}</h4>
                  </div>
                </div>
                <div className='user-menu'>
                  {signInMenus.map((item) =>
                    item.key === 'sign-out' ? (
                      <Link
                        to='#'
                        key={item.key}
                        onClick={() => {
                          Modal.confirm({
                            title: 'Confirmation',
                            content: 'Are you sure want to logout?',
                            onOk: () => {
                              logout();
                              history.replace({ pathname: '/' });
                            },
                            okText: `Yes, I'm sure`,
                            okType: 'primary',
                            width: isMobile ? '90%' : undefined,
                          });
                        }}
                      >
                        {item.label}
                      </Link>
                    ) : (
                      <Link key={item.key} to={item.key}>
                        {item.label}
                      </Link>
                    )
                  )}
                </div>

                <Divider />

                <button
                  className='btn'
                  style={{
                    width: '100%',
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                  onClick={() => history.push('/menu')}
                >
                  <MenuIcon color='#fff' />
                  <span
                    style={{
                      flex: 1,
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                    }}
                  >
                    Order Now
                  </span>
                  <ChevronRight color='#fff' />
                </button>
              </div>
            ) : (
              <div className='user-info-signin-wrapper'>
                {/* <p>Didn’t have account?</p>
                <p>Create your account now</p>
                <button
                  onClick={() => setIsShowModalRegister(true)}
                  className='btn'
                  style={{ width: '100%' }}
                >
                  Create account
                </button>
                <p>or</p> */}
                <button
                  onClick={() => setIsShowModalLogin(true)}
                  className='btn'
                  style={{ width: '100%' }}
                >
                  Sign In
                </button>
              </div>
            )}

            <div className='menu-footer-container'>
              <div className='footer-social-media-wrap'>
                <a href={aboutUs.instagramLink}>
                  <img alt='instagram' src='images/instagram.svg' />
                </a>
                <a href={aboutUs.twitterLink}>
                  <img alt='twitter' src='images/twitter.svg' />
                </a>
                <a href={aboutUs.facebookLink}>
                  <img alt='facebook' src='images/facebook.svg' />
                </a>
              </div>
            </div>
          </div>
        </Drawer>

        <div
          className='header-homepage'
          style={
            isMobile
              ? { height: 60, borderBottom: '1px solid #F1F3F5' }
              : undefined
          }
        >
          {/* mobile navigation */}
          {width <= 800 || isMobile ? (
            <>
              <button
                onClick={() => toggleDrawer(true)}
                className='btn-sidebar'
              >
                <Ham />
              </button>

              {/* {isMobile && location.pathname === '/menu' ? (
                <Row
                  style={isMobile ? { width: '90%' } : undefined}
                  onClick={() => {
                    setIsShowModalSelectLocation(true);
                  }}
                >
                  {selectedLocation.locationId ? (
                    <Col xs={24}>
                      <Row
                        align="middle"
                        justify="space-between"
                        style={{
                          padding: '5px 10px',
                        }}
                      >
                        <Col xs={20}>
                          <Row align="middle">
                            <Col xs={7}>
                              <Text
                                style={{
                                  background: theme.colors.primary,
                                  padding: '5px 8px',
                                  fontSize: 10,
                                  
                                  color: '#FFFFFF',
                                }}
                              >
                                Pickup
                              </Text>
                            </Col>
                            <Col xs={17}>
                              <Row>
                                <Text
                                  style={{ color: 'darkgrey', fontSize: 10 }}
                                >
                                  Pickup Location
                                </Text>
                              </Row>
                              <Row>
                                <Text ellipsis style={{ fontSize: 11 }}>
                                  {selectedLocation?.locationAddress}
                                </Text>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                        <ChevronDown />
                      </Row>
                    </Col>
                  ) : (
                    <Col xs={24}>
                      <Row
                        justify="space-between"
                        style={{
                          padding: '5px 10px',
                          border: '1px lightgrey solid',
                          borderRadius: 30,
                        }}
                      >
                        <Text>Choose Pickup Location</Text>
                        <ChevronDown />
                      </Row>
                    </Col>
                  )}
                </Row>
              ) : (
                <Link className="logo-wrapper" to="/">
                  <img
                    alt="logo-spoonful"
                    src="/images/logo-spoonful.svg"
                    height={40}
                  />
                </Link>
              )} */}

              {/* 
                uncomment line 400 - 474
                comment line 481 - 487
              */}

              {location.pathname === '/menu' ? (
                <CustomTabs
                  value={orderType}
                  options={[EOrderType.pickup, EOrderType.delivery]}
                  onChange={(e) => handleOpenModalOrderType(e)}
                />
              ) : (
                <Link className='logo-wrapper' to='/'>
                  <img
                    alt='logo-spoonful'
                    src='/images/logo-spoonful.svg'
                    height={40}
                  />
                </Link>
              )}

              {location.pathname === '/menu' ||
              location.pathname.includes('/profile') ||
              location.pathname === '/orders' ? (
                <div style={{ marginLeft: 10 }}>
                  {isLoggedIn ? (
                    <UserAvatar />
                  ) : (
                    <div onClick={() => setIsShowModalLogin(true)}>Sign In</div>
                  )}
                </div>
              ) : (
                <Link to='/menu' className='btn-menu'>
                  <MenuIcon color='#FFF' />
                </Link>
              )}
            </>
          ) : (
            /* web navigation */
            <>
              <Link className='logo-wrapper' to='/'>
                <img
                  alt='logo-spoonful'
                  src='/images/logo-spoonful.svg'
                  height={40}
                />
              </Link>

              {/* navigation for menu page */}
              {location.pathname.includes('/menu') ? (
                <div
                  className='homepage-menu'
                  style={{ gap: 20, width: '75%' }}
                >
                  <CustomTabs
                    value={orderType}
                    options={[EOrderType.pickup, EOrderType.delivery]}
                    onChange={(e) => handleOpenModalOrderType(e)}
                  />

                  <Form
                    name='form'
                    layout='vertical'
                    initialValues={{
                      search: '',
                    }}
                    autoComplete='off'
                    style={{ flex: 1 }}
                  >
                    <Form.Item
                      name='search'
                      rules={[]}
                      style={{ marginBottom: 0 }}
                    >
                      <Input
                        style={{ borderRadius: 50 }}
                        size='large'
                        placeholder='What are you looking for?'
                        prefix={<SearchOutlined />}
                        onChange={(e) => setGlobalKeyword(e.target.value)}
                        value={globalKeyword}
                      />
                    </Form.Item>
                  </Form>

                  <button
                    className={
                      (localCarts && localCarts.length > 0) ||
                      (carts && carts.length > 0)
                        ? 'btn-cart-active'
                        : 'btn-cart'
                    }
                    onClick={handleClickOpenCart}
                  >
                    <ShoppingBag size={18} color='#000' />
                    {(localCarts && localCarts.length > 0) ||
                    (carts && carts.length > 0) ? (
                      <span style={{ marginLeft: 5 }}>
                        Cart · {localCarts ? localCarts.length : carts.length}
                      </span>
                    ) : (
                      false
                    )}
                  </button>

                  {/* <button className="btn" onClick={handleClickOpenCart}>
                    <ShoppingCartOutlined
                      size={18}
                      color="#fff"
                      style={{ marginRight: 10 }}
                    />
                    <span>Cart · {carts.length}</span>
                  </button> */}

                  <SignInOrProfile />
                </div>
              ) : (
                /* default web navigation */
                <>
                  <div className='homepage-menu'>
                    {headerMenus.map((menu) => (
                      <Link
                        to={menu.key}
                        key={menu.key}
                        className={isCurrentMenu(menu) ? 'active-menu' : ''}
                      >
                        {menu.label}
                      </Link>
                    ))}
                  </div>

                  <div className='auth-action-wrapper'>
                    <button
                      className={
                        (localCarts && localCarts.length > 0) ||
                        (carts && carts.length > 0)
                          ? 'btn-cart-active'
                          : 'btn-cart'
                      }
                      style={{ marginRight: 10 }}
                      onClick={handleClickOpenCart}
                    >
                      <ShoppingBag
                        size={18}
                        color='#000'
                        style={{ marginRight: 15 }}
                      />
                      {(localCarts && localCarts.length > 0) ||
                      (carts && carts.length > 0) ? (
                        <span style={{ marginLeft: 5 }}>
                          Cart · {localCarts ? localCarts.length : carts.length}
                        </span>
                      ) : (
                        false
                      )}
                    </button>
                    <SignInOrProfile />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </header>

      {showPageHeader && !!pageHeaderTitle && (
        <PageHeader
          title={pageHeaderTitle}
          subtitle={pageHeaderSubtitle}
          componentUnderSubtitle={componentUnderSubtitle}
        />
      )}

      {/* {location.pathname.includes("/about-us") && <CarouselAboutUs />} */}

      {isLoggedIn && !isMyEmailConfrimed && isPathnameForCustomer ? (
        <div
          style={{
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: isMobile ? 0 : 100,
            paddingRight: isMobile ? 0 : 100,
          }}
        >
          <Alert
            style={{
              width: '100%',
              margin: '0 auto',
            }}
            type='warning'
            message='Your email is not verified yet. Please verify your email.'
            action={
              <AppButton
                loading={loadingEmailConfirm}
                onClick={onVerifyMyEmail}
                type='text'
                size='small'
                style={{
                  fontSize: 12,
                  textDecoration: 'underline',
                  fontWeight: 800,
                }}
              >
                Verify My Email Now
              </AppButton>
            }
          />
        </div>
      ) : (
        false
      )}

      <Row
        style={
          hasSidebar && !isMobile
            ? { paddingLeft: 100, paddingRight: 100, background: '#F9FAFA' }
            : undefined
        }
      >
        {hasSidebar && !isMobile && (
          <SidebarMenu isVerified={isMyEmailConfrimed} />
        )}

        <Content
          className='layout-content'
          style={
            hasSidebar && !isMobile
              ? {
                  padding: '0 20px',
                  width: '65%',
                  borderRadius: 10,
                  minHeight: 500,
                  background:
                    isHomepage ||
                    location.pathname.includes('/menu') ||
                    location.pathname.includes('/checkout') ||
                    location.pathname.includes('/contact-us')
                      ? '#FFFFFF'
                      : '#F9FAFA',
                }
              : hasSidebar && isMobile
              ? {
                  width: '100%',
                  borderRadius: 10,
                  minHeight: 500,
                  background:
                    isHomepage ||
                    location.pathname.includes('/menu') ||
                    location.pathname.includes('/checkout') ||
                    location.pathname.includes('/contact-us')
                      ? '#FFFFFF'
                      : '#F9FAFA',
                }
              : {
                  width: '100%',
                  padding: isHomepage || isMenu ? 0 : 15,
                  minHeight: 500,
                  background:
                    isHomepage ||
                    location.pathname.includes('/menu') ||
                    location.pathname.includes('/checkout') ||
                    location.pathname.includes('/contact-us')
                      ? '#FFFFFF'
                      : '#F9FAFA',
                }
          }
        >
          <div
            className='site-layout-content'
            style={
              isMyAccount
                ? { padding: '20px 0' }
                : hasSidebar && isMobile
                ? { paddingTop: 0 }
                : undefined
            }
          >
            {children}
          </div>
        </Content>
      </Row>

      {!location.pathname.includes('checkout') ? (
        <div>
          {showWidget ? (
            <div
              onClick={() => handleToggleWidget()}
              className='close-widget'
              style={{
                position: 'fixed',
                bottom: isMobile ? 150 : 90,
                right: 10,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 26,
                width: 26,
                borderRadius: 100,
                background: 'white',
                border: '1px solid #c2c2c2',
              }}
            >
              <X color='black' width={16} />
            </div>
          ) : (
            <div
              onClick={() => handleToggleWidget()}
              className='close-widget-min'
              style={{
                position: 'fixed',
                bottom: 110,
                right: -34,
                transform: 'rotate(-90deg)',
                background: 'white',
                border: '1px solid #c2c2c2',
                padding: '0 10px',
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                color: 'black',
              }}
            >
              Show chat
            </div>
          )}
          <TawkMessengerReact
            customStyle={{
              visibility: {
                mobile: {
                  xOffset: 5,
                  yOffset: 80,
                  position: 'br',
                },
              },
            }}
            // onLoad={onLoad}
            propertyId='65d7178e9131ed19d96ff580'
            widgetId='1hn83821p'
            ref={tawkMessengerRef}
          />
        </div>
      ) : (
        false
      )}

      {isMobile &&
        localCarts &&
        localCarts.length > 0 &&
        locationToShowMobileCart && (
          <div
            className='floating-cart'
            onClick={() => setIsShowModalCart(true)}
          >
            <span>View Order ({localCarts?.length} items)</span>
            <div>${subTotal.toFixed(2)}</div>
          </div>
        )}

      <Modal
        title={<Title level={3}>Sign In</Title>}
        visible={isShowModalLogin}
        footer={null}
        onCancel={() => {
          setIsShowModalLogin(false);
        }}
        width={isMobile ? '90%' : 400}
      >
        <Form
          ref={formRef}
          layout='vertical'
          name='basic'
          initialValues={{
            email: '',
            password: '',
          }}
          onFinish={onSubmitLogin}
        >
          <Form.Item
            name='email'
            label='Email address'
            rules={generateFormRules('Email address', ['required', 'email'])}
          >
            <Input
              size='large'
              type='email'
              prefix={<MailOutlined />}
              placeholder='Email address'
            />
          </Form.Item>

          <Form.Item name='password' label='Password'>
            <Input.Password
              size='large'
              prefix={<LockOutlined />}
              placeholder='Password'
            />
          </Form.Item>

          <Text
            style={{
              display: 'block',
              textAlign: 'right',
              paddingBottom: 20,
            }}
          >
            <Link
              style={{
                textDecoration: 'underline',
              }}
              onClick={() => {
                setIsShowModalLogin(false);
              }}
              to='/forgot-password'
            >
              Forgot Password?
            </Link>
          </Text>

          <Form.Item>
            <AppButton
              loading={isLoadingLogin}
              size='large'
              type='primary'
              htmlType='submit'
              style={{ width: '100%' }}
            >
              Sign In
            </AppButton>
          </Form.Item>

          <Text style={{ display: 'block', textAlign: 'center' }}>
            Didn't have an account?{' '}
            <Link
              style={{
                color: '#FF4A50',
                fontWeight: 'bold',
                textDecoration: 'none',
              }}
              onClick={() => {
                setIsShowModalLogin(false);
                setIsShowModalRegister(true);
              }}
              to='#'
            >
              Sign Up
            </Link>
          </Text>
        </Form>
        <Row justify='center' style={{ paddingTop: '10px' }}>
          <Text type='secondary' style={{ fontSize: '10px' }}>
            v{appVersion} · © {thisYear}. Spoonful Kitchen
          </Text>
        </Row>
      </Modal>

      <Modal
        title={<Title level={3}>Sign Up</Title>}
        visible={isShowModalRegister}
        footer={null}
        onCancel={() => {
          setIsShowModalRegister(false);
        }}
        width={isMobile ? '90%' : 500}
      >
        <Form
          ref={formRefRegister}
          layout='vertical'
          name='basic'
          initialValues={{
            fullName: '',
            phoneNumber: '',
            email: '',
            password: '',
          }}
          onFinish={onSubmitRegister}
        >
          <Form.Item
            name='fullName'
            label='Full Name'
            rules={generateFormRules('Full Name', ['required'])}
          >
            <Input
              size='large'
              type='fullName'
              prefix={<UserOutlined />}
              placeholder='Full Name'
            />
          </Form.Item>
          <Form.Item
            name='phoneNumber'
            label='Phone Number'
            rules={generateFormRules('Phone Number', [
              'required',
              'phoneNumber',
            ])}
          >
            <Input
              size='large'
              type='phoneNumber'
              prefix={<PhoneOutlined />}
              placeholder='Phone Number'
            />
          </Form.Item>
          <Form.Item
            name='email'
            label='Email address'
            rules={generateFormRules('Email address', ['required', 'email'])}
          >
            <Input
              size='large'
              type='email'
              prefix={<MailOutlined />}
              placeholder='Email address'
            />
          </Form.Item>

          <Form.Item
            name='password'
            label='Password'
            rules={generateFormRules('Password', ['required', 'password'])}
          >
            <Input.Password
              size='large'
              prefix={<LockOutlined />}
              placeholder='Password'
            />
          </Form.Item>

          <Form.Item>
            <Checkbox
              onChange={() => setIsAgreeSignUp(!isAgreeSignUp)}
              style={{ fontSize: 12 }}
            >
              I agree to Spoonful{' '}
              <Link
                target='_blank'
                style={{
                  color: '#FF4A50',
                  fontWeight: 'bold',
                  textDecoration: 'none',
                }}
                to='/terms-and-conditions'
              >
                Terms and conditions
              </Link>{' '}
              &{' '}
              <Link
                target='_blank'
                style={{
                  color: '#FF4A50',
                  fontWeight: 'bold',
                  textDecoration: 'none',
                }}
                to='/privacy-policy'
              >
                Privacy Policy
              </Link>
            </Checkbox>
          </Form.Item>

          <Form.Item>
            <AppButton
              disabled={!isAgreeSignUp}
              loading={isLoadingRegister}
              size='large'
              type='primary'
              htmlType='submit'
              style={{ width: '100%' }}
              // onClick={() => formRefRegister.current?.submit()}
            >
              Sign Up
            </AppButton>
          </Form.Item>

          <Text style={{ display: 'block', textAlign: 'center' }}>
            Already have an account?{' '}
            <Link
              style={{
                color: '#FF4A50',
                fontWeight: 'bold',
                textDecoration: 'none',
              }}
              onClick={() => {
                setIsShowModalRegister(false);
                setIsShowModalLogin(true);
              }}
              to='#'
            >
              Sign In
            </Link>
          </Text>

          <Row justify='center' style={{ paddingTop: '10px' }}>
            <Text type='secondary' style={{ fontSize: '10px' }}>
              v{appVersion} · © {thisYear}. Spoonful Kitchen
            </Text>
          </Row>
        </Form>
      </Modal>

      <ModalCart2 />

      <Modal
        title='Warning'
        visible={isShowModalCartWarning}
        onOk={handleRemoveCartItems}
        okText="Yes, i'm sure"
        onCancel={handleCancelRemoveCartItems}
      >
        <p>
          {/* {`Are you sure want to change order type into "${tmpOrderType}" ? Menu that unavailable for "${tmpOrderType}" will be removed from your cart.`} */}
          {`Are you sure want to change order type into "${tmpOrderType}"? Your cart item will be removed.`}
        </p>
        {/* <p>List of unavailable menu:</p>
        {unavailableCartItems && unavailableCartItems.length > 0
          ? unavailableCartItems.map((item, idx) => (
              <p key={item.productId}>
                {idx + 1}. {item.productDetail?.name}
              </p>
            ))
          : false} */}
      </Modal>

      <ModalOrderCreated
        {...dataForOrderCreatedModal}
        visible={isShowModalOrderCreated}
        onClose={() => {
          setIsShowModalOrderCreated(false);
        }}
      />

      <AppFooter />

      {dataPromo && showBadgePromo && (
        <div
          className='promo-wrapper'
          style={
            isMobile &&
            locationToShowMobileCart &&
            localCarts &&
            localCarts.length > 0
              ? { bottom: 80, left: 10 }
              : !isMobile && locationToShowMobileCart
              ? { bottom: 20 }
              : {}
          }
        >
          <div onClick={() => setBadgePromo(false)} className='promo-close'>
            <X color='black' width={16} />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              cursor: 'pointer',
            }}
            onClick={() => {
              setShowModalPromo(true);
              localStorage.setItem('promo', JSON.stringify(true));
            }}
          >
            <h3>{dataPromo.value} OFF</h3>
            <span>Get it now!</span>
          </div>
        </div>
      )}

      <Modal
        width={1000}
        visible={showModalPromo}
        footer={null}
        closable
        onCancel={() => {
          setShowModalPromo(false);
          localStorage.setItem('promo', JSON.stringify(false));
          localStorage.setItem(
            'promoClosed',
            JSON.stringify(moment().format('YYYY-MM-DD'))
          );
        }}
      >
        <div className='banner-wrapper'>
          <div className='box-image'>
            <img src='/images/TD19642.png' />
          </div>
          <div className='box-info'>
            <h3>GET {dataPromo?.value} OFF YOUR FIRST ORDER!</h3>
            <p>
              Be the first to know about our tasty new dishes and unlock access
              to exclusive offer for registered member!
            </p>

            <Form
              ref={formRefRegister}
              layout='vertical'
              name='basic'
              initialValues={{
                fullName: '',
                phoneNumber: '',
                email: '',
                password: '',
              }}
              onFinish={onSubmitRegister}
            >
              <Form.Item
                style={{ marginBottom: 5, marginTop: 0 }}
                name='fullName'
                label='Full Name'
                rules={generateFormRules('Full Name', ['required'])}
              >
                <Input
                  size='large'
                  type='fullName'
                  prefix={<UserOutlined />}
                  placeholder='Full Name'
                />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 5, marginTop: 0 }}
                name='phoneNumber'
                label='Phone Number'
                rules={generateFormRules('Phone Number', [
                  'required',
                  'phoneNumber',
                ])}
              >
                <Input
                  size='large'
                  type='phoneNumber'
                  prefix={<PhoneOutlined />}
                  placeholder='Phone Number'
                />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 5, marginTop: 0 }}
                name='email'
                label='Email address'
                rules={generateFormRules('Email address', [
                  'required',
                  'email',
                ])}
              >
                <Input
                  size='large'
                  type='email'
                  prefix={<MailOutlined />}
                  placeholder='Email address'
                />
              </Form.Item>

              <Form.Item
                style={{ marginBottom: 5, marginTop: 0 }}
                name='password'
                label='Password'
                rules={generateFormRules('Password', ['required', 'password'])}
              >
                <Input.Password
                  size='large'
                  prefix={<LockOutlined />}
                  placeholder='Password'
                />
              </Form.Item>

              <Form.Item style={{ marginBottom: 5, marginTop: 0 }}>
                <Checkbox
                  onChange={() => setIsAgreeSignUp(!isAgreeSignUp)}
                  style={{ fontSize: 12 }}
                >
                  I agree to Spoonful{' '}
                  <Link
                    target='_blank'
                    style={{
                      color: '#FF4A50',
                      fontWeight: 'bold',
                      textDecoration: 'none',
                    }}
                    to='/terms-and-conditions'
                  >
                    Terms and conditions
                  </Link>{' '}
                  &{' '}
                  <Link
                    target='_blank'
                    style={{
                      color: '#FF4A50',
                      fontWeight: 'bold',
                      textDecoration: 'none',
                    }}
                    to='/privacy-policy'
                  >
                    Privacy Policy
                  </Link>
                </Checkbox>
              </Form.Item>

              <Form.Item style={{ marginBottom: 5, marginTop: 0 }}>
                <AppButton
                  disabled={!isAgreeSignUp}
                  loading={isLoadingRegister}
                  size='large'
                  type='primary'
                  htmlType='submit'
                  style={{ width: '100%' }}
                  // onClick={() => formRefRegister.current?.submit()}
                >
                  Register
                </AppButton>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default AppLayout;
