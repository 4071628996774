import AppLayout from '../../layout/AppLayout';
import {
  Image,
  Select,
  DatePicker,
  Row,
  Col,
  Typography,
  Tag,
  Card,
  Divider,
  Dropdown,
  Menu,
  Button,
} from 'antd';
import { httpRequest } from '../../helpers/api';
import {
  ETransactionStatus,
  TransactionProps,
} from '../../types/transaction.type';
import moment from 'moment';
import useWindowSize from '../../hooks/useWindowSize';
import EmptyData from '../../components/EmptyData';
import { DownOutlined } from '@ant-design/icons';
import airwallexLogo from '../../assets/images/airwallex-logo.png';
import { enumPaymentStatus, PaymentProps } from '../../types/payment.type';
import useFetchList from '../../hooks/useFetchList';
import { EOrderType } from '../../hooks/useOrder';
import { Calendar } from 'react-feather';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

const { Text, Title } = Typography;

const MyOrders = () => {
  const { isMobile } = useWindowSize();
  const { Option } = Select;
  const history = useHistory();

  const {
    isLoading,
    data: payments,
    pagination,
    query,
    setQueryAndResetData,
    changePage,
  } = useFetchList<PaymentProps>({
    endpoint: 'payments/list',
    limit: 6,
    initialQuery: {
      paymentStatus: '',
      offset: 0,
      startDate: '',
      endDate: '',
      sortBy: 'createdAt',
      sortOrder: 'DESC',
    },
    pushData: true,
    columnId: 'paymentId',
  });

  const handleChangeStatus = (paymentStatus: string) => {
    if (paymentStatus === 'CANCELLED') {
      setQueryAndResetData({
        ...query,
        paymentStatus: 'CANCELLED_BY_BUYER,CANCELLED_BY_SYSTEM',
      });
    } else if (paymentStatus !== 'all') {
      setQueryAndResetData({ ...query, paymentStatus });
    } else {
      setQueryAndResetData({ ...query, paymentStatus: '' });
    }
  };

  const [isLoadingPayNow, setLoadingPayNow] = useState(false);

  const handleUpdatePayment = async (paymentData: PaymentProps) => {
    let NavigateURL = '';
    setLoadingPayNow(true);
    try {
      // const res: any = await httpRequest.post(`/payments/initiate/payment`, {
      //   paymentId: paymentData.paymentId,
      // });
      if (paymentData && paymentData.metaPaymentDetail?.id) {
        // NavigateURL = res.data.payload.metaPaymentDetail?.url;
        NavigateURL = `/checkout-payment?gId=${paymentData.groupedId}&trxId=${paymentData.transactionId}&awxId=${paymentData.metaPaymentDetail.id}`;
        console.info('NavigateURL', NavigateURL);
        history.push(NavigateURL);
      }

      // Old Code => Payment Link
      // code 1 -> not working
      // window.open(urlPayment);

      // code 2 -> work on specific case
      // const a = document.createElement('a');
      // a.setAttribute('href', urlPayment);
      // a.setAttribute('target', '_blank');
      // a.click();

      // code 3 ->
      // setTimeout(() => {
      //   window.open(NavigateURL, '_blank');
      // }, 1000);

      // if (paymentData.metaPaymentDetail.url) {
      //   if (paymentData.expiredAt) {
      //     if (new Date(paymentData.expiredAt).getTime() <= new Date().getTime()) {
      //       // expired
      //       const res: any = await httpRequest.post(
      //         `/payments/initiate/payment`,
      //         {
      //           paymentId: paymentData.paymentId,
      //         }
      //       );

      //       if (
      //         res &&
      //         res.data &&
      //         res.data.payload &&
      //         res.data.payload.metaPaymentDetail?.url
      //       ) {
      //         NavigateURL = res.data.payload.metaPaymentDetail?.url;
      //       }
      //     } else {
      //       NavigateURL = paymentData.metaPaymentDetail.url;
      //     }
      //   } else {
      //     NavigateURL = paymentData.metaPaymentDetail.url;
      //   }
      // } else {
      //   const res: any = await httpRequest.post(`/payments/initiate/payment`, {
      //     paymentId: paymentData.paymentId,
      //   });

      //   if (
      //     res &&
      //     res.data &&
      //     res.data.payload &&
      //     res.data.payload.metaPaymentDetail?.url
      //   ) {
      //     NavigateURL = res.data.payload.metaPaymentDetail?.url;
      //   }
      // }

      // console.info('NavigateURL', NavigateURL);

      // window.open(NavigateURL);
    } catch (error) {
      console.log('failed open payment url', error);
    } finally {
      setLoadingPayNow(false);
    }
  };

  const handleCancelTransactions = async (props: TransactionProps) => {
    await httpRequest.patch(`/transactions/${props.transactionId}/cancel`);
    setQueryAndResetData({ ...query });
  };

  const handleClickPagination = async () => {
    changePage(pagination.page + 1);
  };

  return (
    <AppLayout hasSidebar isMyAccount>
      <Card>
        <Row gutter={[8, 8]} justify='space-between' wrap>
          <Col xs={24} md={24} lg={8}>
            <Title level={2} style={{ margin: 0 }}>
              My Orders
            </Title>
          </Col>
          <Col xs={24} md={24} lg={8} style={{ textAlign: 'right' }}>
            <Select
              size='large'
              style={{ borderRadius: 100, width: '100%', textAlign: 'left' }}
              onChange={handleChangeStatus}
              placeholder='Status'
            >
              <Option value='all'>All</Option>
              <Option value='WAITING_PAYMENT'>Waiting Payment</Option>
              <Option value='PAID_FULL'>Paid</Option>
              <Option value='CANCELLED'>Cancelled</Option>
              <Option value='DONE'>Done</Option>
            </Select>
          </Col>

          <Col xs={24} md={24} lg={8}>
            <DatePicker.RangePicker
              style={{
                width: '100%',
              }}
              size='large'
              format='MM / DD / YYYY'
              order={false}
              onChange={(_, formatString: [string, string]) => {
                setQueryAndResetData({
                  ...query,
                  startDate: moment
                    .utc(formatString[0])
                    .startOf('day')
                    .toISOString(),
                  endDate: moment
                    .utc(formatString[1])
                    .endOf('day')
                    .toISOString(),
                });
              }}
            />
          </Col>
        </Row>
      </Card>
      <br></br>

      <div>
        {payments.length === 0 && <EmptyData />}
        {!isLoading &&
          payments.map((payment) => {
            return (
              <div key={payment.paymentId} className='card-order'>
                {isMobile ? (
                  <div
                    style={{
                      background: 'rgba(0,0,0,0.05)',
                      padding: 10,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: 10,
                      }}
                    >
                      <div>
                        <div
                          style={{
                            fontSize: 12,
                            opacity: 0.5,
                          }}
                        >
                          Order ID
                        </div>
                        <div>{payment.transactions[0].transactionCode}</div>
                      </div>
                      <div>
                        <div
                          style={{
                            fontSize: 12,
                            opacity: 0.5,
                          }}
                        >
                          Order Type
                        </div>
                        <Tag color='#383838'>
                          {payment.transactions[0].shipment.method}
                        </Tag>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div>
                        <div style={{ fontSize: 12, opacity: 0.5 }}>
                          Payment Status
                        </div>
                        <Tag color='#1292EE'>
                          {payment.paymentStatus ===
                          enumPaymentStatus.WAITING_PAYMENT
                            ? 'Waiting Payment'
                            : payment.paymentStatus ===
                              enumPaymentStatus.PAID_FULL
                            ? 'Paid'
                            : payment.paymentStatus === enumPaymentStatus.DONE
                            ? 'Done'
                            : payment.paymentStatus ===
                              enumPaymentStatus.CANCELLED_BY_BUYER
                            ? 'Cancelled'
                            : payment.paymentStatus ===
                              enumPaymentStatus.CANCELLED_BY_SYSTEM
                            ? 'Cancelled By System'
                            : '-'}
                        </Tag>
                      </div>
                      {payment.paymentStatus ===
                        enumPaymentStatus.WAITING_PAYMENT && (
                        <Button
                          style={{
                            borderRadius: 20,
                            backgroundColor: 'white',
                            border: '1px solid black',
                            color: 'black',
                            height: '3em',
                            width: '9em',
                          }}
                          loading={isLoadingPayNow}
                          disabled={isLoadingPayNow}
                          onClick={() => handleUpdatePayment(payment)}
                        >
                          {
                            <Image
                              src={airwallexLogo}
                              preview={false}
                              style={{ paddingRight: '3%' }}
                            />
                          }
                          <Text style={{ paddingLeft: '5%' }}>Pay Now</Text>
                        </Button>
                      )}
                    </div>
                  </div>
                ) : (
                  <Row
                    className='wrapper header'
                    justify='space-between'
                    align='middle'
                  >
                    <Col>
                      <Row align='middle'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span style={{ marginRight: 10 }}>Order ID</span>
                          <Text
                            style={{
                              fontWeight: '600',
                              fontSize: '16px',
                              lineHeight: '150%',
                              letterSpacing: '-0.02em',
                            }}
                          >
                            {payment.transactions[0].transactionCode}
                          </Text>
                        </div>
                        <Tag color='#383838' style={{ marginLeft: 20 }}>
                          {payment.transactions[0].shipment.method}
                        </Tag>
                      </Row>
                    </Col>
                    <div
                      style={{ display: 'flex', gap: 15, alignItems: 'center' }}
                    >
                      <Tag
                        color={
                          payment.paymentStatus ===
                          enumPaymentStatus.WAITING_PAYMENT
                            ? '#eb9636'
                            : payment.paymentStatus ===
                              enumPaymentStatus.PAID_FULL
                            ? '#1292EE'
                            : payment.paymentStatus === enumPaymentStatus.DONE
                            ? '#08962b'
                            : payment.paymentStatus ===
                              enumPaymentStatus.CANCELLED_BY_BUYER
                            ? '#696969'
                            : payment.paymentStatus ===
                              enumPaymentStatus.CANCELLED_BY_SYSTEM
                            ? '#696969'
                            : '#1292EE'
                        }
                      >
                        {payment.paymentStatus ===
                        enumPaymentStatus.WAITING_PAYMENT
                          ? 'Waiting Payment'
                          : payment.paymentStatus ===
                            enumPaymentStatus.PAID_FULL
                          ? 'Paid'
                          : payment.paymentStatus === enumPaymentStatus.DONE
                          ? 'Done'
                          : payment.paymentStatus ===
                            enumPaymentStatus.CANCELLED_BY_BUYER
                          ? 'Cancelled'
                          : payment.paymentStatus ===
                            enumPaymentStatus.CANCELLED_BY_SYSTEM
                          ? 'Cancelled By System'
                          : '-'}
                      </Tag>
                      {payment.paymentStatus ===
                        enumPaymentStatus.WAITING_PAYMENT && (
                        <Button
                          style={{
                            borderRadius: 20,
                            backgroundColor: 'white',
                            border: '1px solid black',
                            color: 'black',
                            height: '2em',
                            width: '9em',
                          }}
                          loading={isLoadingPayNow}
                          disabled={isLoadingPayNow}
                          onClick={() => handleUpdatePayment(payment)}
                        >
                          {
                            <Image
                              src={airwallexLogo}
                              preview={false}
                              style={{ paddingRight: '3%' }}
                            />
                          }
                          <Text style={{ paddingLeft: '5%' }}>Pay Now</Text>
                        </Button>
                      )}
                    </div>
                  </Row>
                )}
                <div className='wrapper info'>
                  <div>
                    <div className='label'>Order date</div>
                    <div style={{ fontSize: 12 }}>
                      {moment(payment.createdAt).format('ddd, DD MMM YYYY')}
                    </div>
                  </div>
                  {payment.transactions[0].shipment.method === 'PICKUP' && (
                    <>
                      <div style={{ flex: 1 }}>
                        <div className='label'>Pickup Point</div>
                        <div style={{ fontSize: 12 }}>
                          {
                            payment.transactions[0].shipment.meta
                              .pickupFromLocationName
                          }{' '}
                          -
                          {
                            payment.transactions[0].shipment.meta
                              .pickupFromLocationAddress
                          }
                        </div>
                      </div>
                      <div style={{ flex: 1 }}>
                        <div className='label'>Pickup Timeframe</div>
                        <div style={{ fontSize: 12 }}>
                          {
                            payment.transactions[0].shipment.meta
                              .pickupTimeStart
                          }{' '}
                          -{' '}
                          {payment.transactions[0].shipment.meta.pickupTimeEnd}
                        </div>
                      </div>
                    </>
                  )}
                  {payment.transactions[0].shipment.method === 'DELIVERY' && (
                    <div style={{ flex: 1 }}>
                      <div className='label'>Delivery Address</div>
                      <div style={{ fontSize: 12 }}>
                        {payment.transactions[0].shipment.meta.deliveryAddress}
                        {', '}
                        {
                          payment.transactions[0].shipment.meta
                            .deliveryAddressSuburb
                        }
                        {', '}
                        {
                          payment.transactions[0].shipment.meta
                            .deliveryAddressPostcode
                        }
                        {', '}
                        {
                          payment.transactions[0].shipment.meta
                            .deliveryAddressState
                        }
                      </div>
                    </div>
                  )}
                  {payment.transactions[0].shipment.method === 'DELIVERY' && (
                    <div style={{ flex: 1 }}>
                      <div className='label'>Delivery at</div>
                      <div style={{ fontSize: 12 }}>
                        {payment.transactions[0].shipment.meta
                          .deliveryScheduleTimeframe &&
                        payment.transactions[0].shipment.meta
                          .deliveryScheduleTimeframe.length > 0
                          ? moment(
                              payment.transactions[0].shipment.meta
                                .deliveryScheduleTimeframe[0].selectedTimeframe
                            ).format('ddd, DD MMM YYYY')
                          : '-'}
                      </div>
                    </div>
                  )}
                  <div>
                    <div className='label'>Total Payment</div>
                    <div style={{ fontWeight: 600, fontSize: 12 }}>
                      ${payment.metaPaymentDetail.amount}
                    </div>
                  </div>
                </div>

                <Divider style={{ marginTop: 0, marginBottom: 0 }} />

                <p
                  className='label'
                  style={{
                    padding: 15,
                    paddingBottom: 0,
                    marginBottom: 0,
                    fontSize: 12,
                  }}
                >
                  Order details (Group by menu schedule):
                </p>

                {payment.transactions.map((transaction, idx) => {
                  return (
                    <div key={idx}>
                      <Row
                        justify='space-between'
                        align='middle'
                        className='wrapper'
                        // style={{ paddingLeft: isMobile ? undefined : "5%" }}
                      >
                        <Col lg={18} xs={24}>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              color: '#FF4A50',
                            }}
                          >
                            <Calendar
                              style={{ marginRight: 10 }}
                              size={14}
                              color='#FF4A50'
                            />
                            <div style={{ fontWeight: 600 }}>
                              {moment(transaction.shipment.scheduledAt).format(
                                'dddd, MMM DD YYYY'
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <div
                        className='wrapper'
                        style={{
                          display: 'grid',
                          gap: 15,
                          flexWrap: 'wrap',
                          gridTemplateColumns: isMobile
                            ? 'repeat(1, 1fr)'
                            : 'repeat(2, 1fr)',
                        }}
                      >
                        {transaction.items.map((menu) => {
                          return (
                            <div
                              // style={{ flex: isMobile ? '1' : '0 1 30%' }}
                              key={menu.productId}
                            >
                              <div style={{ display: 'flex', gap: 15 }}>
                                <div style={{ position: 'relative' }}>
                                  <Image
                                    style={{
                                      borderRadius: 5,
                                      border: '1px solid #ddd',
                                      objectFit:
                                        menu.metaProduct &&
                                        menu.metaProduct.images &&
                                        menu.metaProduct.images.length > 0
                                          ? 'cover'
                                          : 'contain',
                                    }}
                                    preview={false}
                                    width={80}
                                    height={50}
                                    src={
                                      (menu.metaProduct &&
                                      menu.metaProduct.images &&
                                      menu.metaProduct.images.length > 0
                                        ? menu.metaProduct?.images[0].imageUrl
                                        : '/images/select-image.jpg') ||
                                      '/images/select-image.jpg'
                                    }
                                    fallback={'/images/blur-image.jpeg'}
                                  />
                                  {menu.metaProduct.menuItems &&
                                    menu.metaProduct.menuItems.length > 0 && (
                                      <div
                                        style={{
                                          position: 'absolute',
                                          bottom: 0,
                                          left: 0,
                                          width: 80,
                                          padding: '4px 0;',
                                          textAlign: 'center',
                                          background: '#14b5b0',
                                          borderBottomLeftRadius: 5,
                                          borderBottomRightRadius: 5,
                                          color: 'white',
                                          fontSize: 10,
                                          fontWeight: 600,
                                        }}
                                      >
                                        Meal Pack
                                      </div>
                                    )}
                                </div>
                                <div>
                                  <div>{menu.metaProduct.name}</div>
                                  <div style={{ fontSize: 12, marginTop: 5 }}>
                                    <span>
                                      {menu.metaProduct.menuItems &&
                                      menu.metaProduct.menuItems.length > 0
                                        ? menu.quantity + ' portion'
                                        : menu.quantity + 'x'}
                                    </span>
                                    <span
                                      style={{ opacity: 0.2, margin: '0 5px' }}
                                    >
                                      •
                                    </span>
                                    <span>
                                      $
                                      {menu.metaProduct.variant?.price.toFixed(
                                        2
                                      )}
                                    </span>
                                    {menu.metaProduct?.variant?.label && (
                                      <>
                                        <span
                                          style={{
                                            opacity: 0.2,
                                            margin: '0 5px',
                                          }}
                                        >
                                          •
                                        </span>
                                        <span>
                                          {menu.metaProduct?.variant?.label}
                                        </span>
                                      </>
                                    )}
                                    {/* <span>$
                                      {(menu.productPrice * menu.quantity).toFixed(
                                        2
                                      )}</span> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        {pagination.next ? (
          <Row align='middle' justify='center'>
            <Button
              style={{
                backgroundColor: '#FF4A50',
                color: 'white',
                borderRadius: '100px',
              }}
              onClick={handleClickPagination}
            >
              Show more
            </Button>
          </Row>
        ) : (
          false
        )}
      </div>
    </AppLayout>
  );
};

export default MyOrders;

// const CustomSelect = styled(Select)`
//   .ant-select-selector {
//     border-radius: 100px;
//   }
// `;

/* 
<Col>
                          <Dropdown
                            disabled={
                              transaction.transactionStatus !==
                              ETransactionStatus.CREATED
                            }
                            overlay={
                              <Menu
                                onClick={(e) =>
                                  handleCancelTransactions(transaction)
                                }
                                disabled={
                                  transaction.transactionStatus !==
                                  ETransactionStatus.CREATED
                                }
                              >
                                <Menu.Item key={"cancel"}>Cancel</Menu.Item>
                              </Menu>
                            }
                            trigger={["click"]}
                          >
                            <a
                              className='ant-dropdown-link'
                              onClick={(e) => e.preventDefault()}
                            >
                              <Tag
                                style={{
                                  backgroundColor:
                                    transaction.transactionStatus ===
                                    ETransactionStatus.CREATED
                                      ? "#FFF9EF"
                                      : transaction.transactionStatus ===
                                        ETransactionStatus.COMPLETED
                                      ? "#F5FFF9"
                                      : transaction.transactionStatus ===
                                        ETransactionStatus.PAID
                                      ? "#F3FBFF"
                                      : "#FFF9FB",

                                  color:
                                    transaction.transactionStatus ===
                                    ETransactionStatus.CREATED
                                      ? "#E89635"
                                      : transaction.transactionStatus ===
                                        ETransactionStatus.COMPLETED
                                      ? "#39AC6E"
                                      : transaction.transactionStatus ===
                                        ETransactionStatus.PAID
                                      ? "#1292EE"
                                      : "#FF4A50",

                                  borderColor:
                                    transaction.transactionStatus ===
                                    ETransactionStatus.CREATED
                                      ? "#E89635"
                                      : transaction.transactionStatus ===
                                        ETransactionStatus.COMPLETED
                                      ? "#39AC6E"
                                      : transaction.transactionStatus ===
                                        ETransactionStatus.PAID
                                      ? "#1292EE"
                                      : "#FF4A50",
                                  borderRadius: "10px",
                                }}
                              >
                                {transaction.transactionStatus ===
                                ETransactionStatus.COMPLETED
                                  ? "DONE"
                                  : transaction.transactionStatus ===
                                    ETransactionStatus.CANCELED_BY_BUYER
                                  ? "CANCELLED"
                                  : transaction.transactionStatus ===
                                    ETransactionStatus.CANCELED_BY_ADMIN
                                  ? "CANCELLED BY ADMIN"
                                  : transaction.transactionStatus ===
                                    ETransactionStatus.CANCELED_BY_SYSTEM
                                  ? "CANCELLED BY SYSTEM"
                                  : transaction.transactionStatus}
                              </Tag>
                              {transaction.transactionStatus ===
                                ETransactionStatus.CREATED && <DownOutlined />}
                            </a>
                          </Dropdown>
                        </Col>
*/
