import { useEffect } from 'react';
import { getToken, removeToken, saveToken } from '../helpers/auth';
import { createGlobalState } from 'react-hooks-global-state';
import {
  ICustomer,
  IMembershipDiscount,
  initialCustomer,
  IUser,
} from '../types/customer.type';
import { httpRequest } from '../helpers/api';
import { IHttpResponse } from '../helpers/pagination';
import { message } from 'antd';
import { getErrorMessage } from '../helpers/errorHandler';

type IAuthData = {
  isLoading?: boolean;
  token: string;
  user: ICustomer;
  membershipDiscount?: IMembershipDiscount;
};

const initialState: IAuthData = {
  isLoading: true,
  token: '',
  user: { ...initialCustomer },
  membershipDiscount: undefined,
};
const { useGlobalState } = createGlobalState(initialState);

export default function useAuth() {
  const [isLoading, setIsLoading] = useGlobalState('isLoading');
  const [token, setToken] = useGlobalState('token');
  const [user, setUser] = useGlobalState('user');
  const [membershipDiscount, setMembershipDiscount] =
    useGlobalState('membershipDiscount');

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const tokenString = await getToken();
    if (tokenString) {
      setToken(tokenString);
    }
    setIsLoading(false);
  };

  const getProfile = () => {
    setIsLoading(true);
    httpRequest
      .get<IHttpResponse<ICustomer>>('/customers/me', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((resultUser) => {
        setUser(resultUser.data.payload);
        setIsLoading(false);
      })
      .catch((err) => {
        // message.error(getErrorMessage(err));
        console.error('failed get customer profile', err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getMembershipDiscount = () => {
    setIsLoading(true);
    httpRequest
      .get<IHttpResponse<IUser>>('/users/me', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((res) => {
        setMembershipDiscount(
          res.data.payload.discountUser
            ? res.data.payload.discountUser.discounts
            : undefined
        );
        setIsLoading(false);
      })
      .catch((err) => {
        // message.error(getErrorMessage(err));
        setIsLoading(false);
      });
  };

  const saveProfile = async (data: ICustomer) => {
    setIsLoading(true);
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if ((data as any)[key]) {
        formData.append(key, (data as any)[key]);
      }
    });

    try {
      const resultUpdate = await httpRequest.patch<IHttpResponse<ICustomer>>(
        '/customers/' + user.customerId,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      setUser(resultUpdate.data.payload);
      setIsLoading(false);
      message.success('Update profile successfully');
    } catch (err) {
      message.error(getErrorMessage(err));
      setIsLoading(false);
    }
  };

  const login = (data: IAuthData) => {
    saveToken(data.token);
    setToken(data.token);

    setUser(data.user);
  };

  const logout = async () => {
    try {
      await httpRequest.post('/auth/signout');
    } catch (error) {
      console.error('failed to logout', error);
    } finally {
      removeToken();
      setToken('');
      setUser({ ...initialCustomer });
    }
  };

  return {
    isLoading,
    isLoggedIn: token ? true : false,
    token,
    user,
    membershipDiscount,
    getProfile,
    login,
    logout,
    saveProfile,
    getMembershipDiscount,
  };
}
