import { message, Modal } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import { useEffect, useState } from 'react';
import { convertHtmlToText } from '../helpers/text';
import useCart, { CartItem } from '../hooks/useCart';
import useOrder, { EOrderType } from '../hooks/useOrder';
import useWindowSize from '../hooks/useWindowSize';
import { IMenu, initialVariant } from '../types/menu.type';
import AppButton from './AppButton';
import MenuCardItem from './MenuCardItem';

interface Props {
  scheduleAt: string;
}

const ModalMealPacks: React.FC<Props> = (props) => {
  const {
    isShowModalMealPacks,
    mealPackMenu,
    setMealPackMenu,
    setIsShowModalMealPacks,
  } = useOrder();
  const { addTmpCartItem, setLocalCarts, removeTmpCartItem, localCarts } =
    useCart();
  const { orderType, currSelectedLocation } = useOrder();
  const { isMobile } = useWindowSize();
  const [currQty, setCurrQty] = useState<number>(0);

  function handleOnClickMenu(menu: IMenu) {}

  function handleAddMealPackQty(menu: IMenu) {
    const newMealPackMenuItems = mealPackMenu.items?.map((item) => {
      const found = item.menuId === menu.menuId;

      if (found) {
        setCurrQty(currQty + 1);
        return {
          ...item,
          currQty: item.currQty! + 1,
        };
      } else {
        return { ...item };
      }
    });

    setMealPackMenu({ ...mealPackMenu, items: newMealPackMenuItems });
  }

  function handleRemoveMealPackQty(menu: IMenu) {
    const newMealPackMenuItems = mealPackMenu.items?.map((item) => {
      const found = item.menuId === menu.menuId;

      if (found) {
        setCurrQty(currQty - 1);
        return {
          ...item,
          currQty: item.currQty! - 1,
        };
      } else {
        return { ...item };
      }
    });

    setMealPackMenu({ ...mealPackMenu, items: newMealPackMenuItems });
  }

  function handleAddMealPack() {
    if (localCarts) {
      const foundCurr = localCarts.find(
        (item) => item.productId === mealPackMenu.menuId
      );
      if (foundCurr) {
        const datas: CartItem[] = [];
        for (const local of localCarts) {
          if (local.productId === mealPackMenu.menuId) {
            const newItems = mealPackMenu?.items
              ?.filter((item) => item.currQty && item.currQty > 0)
              .map((item) => ({
                menuId: item.menuId,
                menuName: item.name,
                qty: item.currQty || 0,
                images: item.images,
              }));
            datas.push({
              ...local,
              meta: {
                ...local.meta,
                menuItems: newItems,
              },
            });
          } else {
            datas.push(local);
          }
        }

        if (datas) {
          localStorage.setItem('carts', JSON.stringify(datas));
          setLocalCarts(datas);

          setIsShowModalMealPacks(false);
          message.success(`Success add ${mealPackMenu.name} to your cart`);
        }
      } else {
        addMealpacktoCart();
      }
    } else {
      addMealpacktoCart();
    }
  }

  function addMealpacktoCart() {
    addTmpCartItem({
      quantity: currQty,
      productId: mealPackMenu.menuId,
      productDetail: {
        name: mealPackMenu.name,
        variant: {
          ...initialVariant,
          label: '',
          price: mealPackMenu.price || 0,
        },
        variants: [],
        images: mealPackMenu.images || [],
      },
      meta: {
        pickupFromLocationId:
          orderType === EOrderType.pickup && currSelectedLocation
            ? currSelectedLocation.locationId
            : '',
        pickupScheduledAt:
          orderType === EOrderType.pickup ? props.scheduleAt : '',
        pickupTime: {
          startAt:
            orderType === EOrderType.pickup &&
            currSelectedLocation &&
            currSelectedLocation.timeframe &&
            currSelectedLocation.timeframe.split('-').length > 0
              ? currSelectedLocation.timeframe.split('-')[0]
              : currSelectedLocation
              ? currSelectedLocation.timeframe
              : '',
          endAt:
            orderType === EOrderType.pickup &&
            currSelectedLocation &&
            currSelectedLocation.timeframe &&
            currSelectedLocation.timeframe.split('-').length > 0
              ? currSelectedLocation.timeframe.split('-')[1]
              : currSelectedLocation
              ? currSelectedLocation.timeframe
              : '',
        },
        deliveryScheduleAt:
          orderType === EOrderType.delivery ? props.scheduleAt : '',
        menuItems: mealPackMenu.items
          ? mealPackMenu.items
              .filter((item) => item.currQty && item.currQty > 0)
              .map((item) => ({
                menuId: item.menuId,
                menuName: item.name,
                qty: item.currQty || 0,
                images: item.images,
              }))
          : [],
      },
    });
    setIsShowModalMealPacks(false);
    message.success(`Success add ${mealPackMenu.name} to your cart`);
  }

  useEffect(() => {
    setCurrQty(0);
  }, [isShowModalMealPacks]);

  useEffect(() => {
    if (localCarts) {
      let found = localCarts.find(
        (item) => item.productId === mealPackMenu.menuId
      );
      if (found && found.meta.menuItems) {
        let currTotal = 0;
        const newMenuMealpack = {
          ...mealPackMenu,
          items: mealPackMenu.items
            ? mealPackMenu.items.map((item) => {
                const a = found?.meta.menuItems?.find(
                  (i) => i.menuId === item.menuId
                );
                return {
                  ...item,
                  currQty: a ? a.qty : item.currQty,
                };
              })
            : [],
        };
        for (const item of newMenuMealpack.items) {
          currTotal = currTotal + (item.currQty || 0);
        }
        setCurrQty(currTotal);
        setMealPackMenu(newMenuMealpack);
      }
    }
  }, [isShowModalMealPacks, localCarts]);

  const [elipsisDescription, setElipsisDescription] = useState<
    number | undefined
  >(5);

  useEffect(() => {
    if (isMobile) {
      setElipsisDescription(2);
    }
  }, [isMobile]);

  function handleElipsisDescription() {
    if (typeof elipsisDescription === 'undefined') {
      setElipsisDescription(isMobile ? 2 : 5);
    } else {
      setElipsisDescription(undefined);
    }
  }

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState<string | undefined>();

  const showModal = (image: string) => {
    setCurrentImage(image);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        visible={isShowModalMealPacks}
        // closable={true}
        onCancel={() => setIsShowModalMealPacks(false)}
        footer={null}
        width={900}
        bodyStyle={isMobile ? { padding: 15 } : {}}
      >
        <div
          className='meal-pack-top'
          style={{ marginBottom: 0, paddingBottom: 10, gap: 2 }}
        >
          <div className='meal-pack-info'>
            <div className='meal-pack-highlight'>{mealPackMenu.name}</div>
            <Paragraph
              style={{
                fontSize: 13,
                color: '#1D2B36',
                fontWeight: 400,
                margin: 0,
                marginTop: 5,
              }}
              ellipsis={{
                rows: elipsisDescription,
              }}
            >
              {convertHtmlToText(mealPackMenu.description)}
            </Paragraph>
          </div>

          {isMobile && (
            <div
              onClick={handleElipsisDescription}
              style={{
                color: '#FF4A50',
                fontSize: 12,
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              {typeof elipsisDescription === 'undefined'
                ? 'Show less'
                : 'Show more'}
            </div>
          )}
        </div>

        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 120px',
            marginBottom: 10,
            paddingBottom: 5,
            paddingTop: 5,
            borderBottom: '1px solid #f1f3f5',
          }}
        >
          <div
            className='meal-pack-qty'
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <div style={{ marginRight: 10 }}>Qty</div>
            <div className='meal-pack-highlight'>
              {currQty}/{mealPackMenu && mealPackMenu.maxQty}
            </div>
          </div>
          <div
            className='meal-pack-price'
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <div style={{ marginRight: 10 }}>Price</div>
            <div className='meal-pack-highlight'>${mealPackMenu.price}</div>
          </div>
          <AppButton
            onClick={() => handleAddMealPack()}
            disabled={currQty !== mealPackMenu.maxQty}
            type='primary'
          >
            Add Meal Pack
          </AppButton>
        </div>

        {/* <div className='meal-pack-dietaries'>
        <span>Dietaries</span>
      </div> */}

        <div
          style={{
            display: 'grid',
            flexWrap: 'wrap',
            gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr',
            alignItems: 'flex-start',
            gap: 15,
          }}
        >
          {mealPackMenu &&
            mealPackMenu.items &&
            mealPackMenu.items.length > 0 &&
            mealPackMenu.items.map((menu, idx) => (
              <MenuCardItem
                imageHeight={380}
                key={menu.menuId}
                menu={menu}
                isMealPack
                isDisabled={currQty === mealPackMenu.maxQty}
                handleOnClickMenu={(data) => handleOnClickMenu(data)}
                currMealPackQty={menu.currQty || 0}
                addMealPackQty={(menu) => handleAddMealPackQty(menu)}
                removeMealPackQty={(menu) => handleRemoveMealPackQty(menu)}
                showModalImage={(image) => showModal(image)}
              />
            ))}
        </div>
      </Modal>
      <Modal
        className='modal-image'
        width={900}
        visible={isModalOpen}
        footer={null}
        onCancel={handleCancel}
      >
        <img src={currentImage} />
      </Modal>
    </>
  );
};

export default ModalMealPacks;
