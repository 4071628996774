export interface ICustomer {
  customerId: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  status: boolean;

  createdAt?: Date | string;
  updatedAt?: Date | string;

  image?: File;
  imageUrl?: string;
}

export const initialCustomer: ICustomer = {
  customerId: "",
  fullName: "",
  email: "",
  phoneNumber: "",
  status: true,
  imageUrl: "",

  createdAt: "",
  updatedAt: "",
};

export type IChangePassword = {
  oldPassword: "";
  newPassword: "";
  retypePassword: "";
};

export type CheckCustomerDiscount = {
  isNewCustomer: boolean;
  discounts: any[];
  currentHighestDiscount: {
    discountName: "";
    discountNominal: number;
  };
};

export interface IUser {
  userId: string;
  userType: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  discountUser?: IUserDiscount;
}

export interface IUserDiscount {
  id: string;
  discountId: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
  discounts: IMembershipDiscount;
}

export interface IMembershipDiscount {
  discountId: string;
  discountName: string;
  description: string;
  amountType: string;
  amount: number;
  isPublished: boolean;
  createdAt: string;
  updatedAt: string;
}
