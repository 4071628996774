/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import AppLayout from '../layout/AppLayout';
import { Card, message, Typography } from 'antd';
import { CheckCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import AppButton from '../components/AppButton';
import { getErrorMessage } from '../helpers/errorHandler';
import { useHistory, useParams } from 'react-router-dom';
import { IHttpResponse } from '../helpers/pagination';
import axios from 'axios';
import useModalAuth from '../hooks/useModalAuth';
import useAuth from '../hooks/useAuth';

const { Title, Text } = Typography;

interface URLProps {
  sessionId: string;
}

const VerifyEmail: React.FC = () => {
  const { sessionId } = useParams<URLProps>();

  const [isShowSuccessMessage, setIsShowSuccessMessage] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    check();
  }, []);

  const check = async () => {
    try {
      setIsLoading(true);

      await axios.post<IHttpResponse<any>>('/auth/verify-email', { sessionId });

      setIsShowSuccessMessage(true);
      setIsLoading(false);
    } catch (err) {
      message.error(getErrorMessage(err));
      setIsLoading(false);
    }
  };

  return (
    <AppLayout>
      {isLoading ? (
        <div style={{ textAlign: 'center' }}>
          <LoadingOutlined style={{ fontSize: 50 }} />
        </div>
      ) : isShowSuccessMessage ? (
        <VerifyEmailSuccess />
      ) : (
        <div style={{ textAlign: 'center' }}>Invalid Session</div>
      )}
    </AppLayout>
  );
};

const VerifyEmailSuccess = () => {
  const { setIsShowModalLogin } = useModalAuth();
  const { isLoggedIn } = useAuth();
  const history = useHistory();

  return (
    <div style={{ textAlign: '-webkit-center' as any }}>
      <Card
        style={{
          width: 500,
          textAlign: 'center',
          border: '1px solid lightgray',
        }}
      >
        <div
          style={{
            margin: '0 auto',
            background: 'lightgreen',
            width: 50,
            height: 50,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 5,
            marginBottom: 20,
          }}
        >
          <CheckCircleOutlined style={{ fontSize: 20, color: 'green' }} />
        </div>

        <Title level={4}>Email has been verified</Title>
        <Text>
          {isLoggedIn
            ? 'Thank you for verifying your email. Now let order some meals!'
            : 'Your email has been successfully verified, now you can sign in and use full features.'}
        </Text>

        <div style={{ marginTop: 50 }}>
          <AppButton
            size='large'
            type='primary'
            htmlType='submit'
            style={{ width: '100%' }}
            onClick={() => {
              isLoggedIn ? history.push('/menu') : setIsShowModalLogin(true);
            }}
          >
            {isLoggedIn ? 'Order Now' : 'Sign In Now'}
          </AppButton>
        </div>
      </Card>
    </div>
  );
};

export default VerifyEmail;
