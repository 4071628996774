import React from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import Slider, { Settings } from 'react-slick';
import HeaderSection from './HeaderSection';
import useOrder from '../hooks/useOrder';
import useWindowSize from '../hooks/useWindowSize';
import HTMLPreview from './HTMLPreview';
import { ILocation } from '../types/location.type';
import Paragraph from 'antd/lib/typography/Paragraph';
import { convertHtmlToText } from '../helpers/text';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Divider, Tag, Typography } from 'antd';
import AppButton from './AppButton';
import { PlusOutlined } from '@ant-design/icons';
import { EOrderType } from '../hooks/useOrder';

const { Text } = Typography;

interface DataTopPick {
  menuId: string;
  title: string;
  description: string;
  price: string;
  image: string;
  variants?: any;
  onClick: (menuId: string) => void;
  isAvailable: boolean;
  schedule: Date | string;
}

type Props = {
  data: DataTopPick[];
  locations: ILocation[];
};

const TopPick: React.FC<Props> = ({ data }) => {
  const { width, isMobile } = useWindowSize();
  const carouselRef = React.useRef<any>(null);

  const settings: Settings = {
    className: 'center',
    infinite: true,
    speed: 500,
    rows: 1,
    slidesPerRow: 1,
    arrows: false,
  };

  function next() {
    carouselRef.current.slickNext();
  }

  function prev() {
    carouselRef.current.slickPrev();
  }

  let newData = [...data];

  const slidesToShow = () => {
    let res = 1;
    if (width < 767) {
      res = 1;
    } else if (newData.length < 4) {
      res = newData.length;
    } else {
      res = 4;
    }
    return res;
  };

  return (
    <div className='homepage-section homepage-section-top-pick'>
      <div className='section-odd'>
        <HeaderSection
          title='Best Seller'
          // rightAction={width > 767 && <RightAction />}
        />
      </div>
      {!isMobile && (
        <div className='slider-actions'>
          <button onClick={prev}>
            <ChevronLeft size={24} color='black' />
          </button>
          <button onClick={next}>
            <ChevronRight size={24} color='black' />
          </button>
        </div>
      )}
      <Slider
        ref={carouselRef}
        {...settings}
        slidesToShow={slidesToShow()}
        centerMode={newData.length > 4 || isMobile}
      >
        {newData.map((item, idx) => (
          <TopPickCard
            key={idx}
            menuId={item.menuId}
            title={item.title}
            description={item.description}
            price={item.price}
            image={item.image}
            variants={item.variants}
            onClick={item.onClick}
            isAvailable={item.isAvailable}
            schedule={item.schedule}
          />
        ))}
      </Slider>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <Link to='menu'>
          <AppButton
            size={'middle'}
            style={{
              borderRadius: 6,
              backgroundColor: '#FFEFEF',
              height: '38px',
              fontSize: '12px',
              fontWeight: 'bold',
              marginBottom: '20px',
            }}
          >
            <div style={{ fontWeight: 'bolder', color: '#FF4A50' }}>
              {'See All Menu'}
            </div>
          </AppButton>
        </Link>
      </div>
    </div>
  );
};

const TopPickCard: React.FC<DataTopPick> = ({
  menuId,
  title,
  description,
  price,
  image,
  variants,
  onClick,
  isAvailable,
  schedule,
}) => {
  const { orderType, scheduleDeliveries, deliveryPostcode } = useOrder();

  const isTopPickAvailable =
    orderType === EOrderType.delivery
      ? scheduleDeliveries.find((item) => item.scheduleAt === schedule)
          ?.deliveryCoverage?.postalCode[deliveryPostcode]
      : true;

  return (
    <div
      className='top-pick-card'
      onClick={() => (!isAvailable ? null : onClick(menuId))}
      style={{ cursor: !isAvailable ? 'default' : 'pointer' }}
    >
      {/* <span>${price}</span> */}
      <img alt={title} src={image} />
      <h4>{title}</h4>
      <Paragraph
        style={{ color: 'black', opacity: 0.7 }}
        type='secondary'
        ellipsis={{
          rows: 3,
        }}
      >
        {convertHtmlToText(description)}
      </Paragraph>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: 10,
          flexWrap: 'wrap',
        }}
      >
        <div>
          <Text style={{ fontSize: 14, fontWeight: 600 }}>
            ${variants[0].price.toFixed(2)}
          </Text>
          {/* {variants.length > 1 && (
            <>
              <Divider type="vertical" />
              <Text type="secondary" style={{ fontSize: "12px" }}>
                {variants.length} Variants
              </Text>
            </>
          )} */}
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {isTopPickAvailable && (
            <AppButton
              disabled={!isAvailable ? true : false}
              size={'middle'}
              style={{
                borderRadius: 6,
                backgroundColor: !isAvailable ? '#F1F3F5' : 'white',
                border: '1px',
                borderStyle: 'solid',
                borderColor: !isAvailable ? '#B7C2CC' : '#ff4a50',
                color: !isAvailable ? '#748494' : '#ff4a50',
                height: '38px',
                fontSize: '12px',
                fontWeight: 600,
              }}
              icon={<PlusOutlined color='#000000' />}
            >
              {'Add Menu'}
            </AppButton>
          )}
        </div>
        {!isAvailable ? (
          <Tag
            color='orange'
            style={{
              marginTop: '8px',
              width: '100%',
              fontSize: '11px',
              textAlign: 'center',
            }}
          >
            Not available for {orderType}{' '}
            {moment(schedule).format('dddd').substring(0, 3) +
              ', ' +
              moment(schedule).format('DD MMM YYYY')}
          </Tag>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default TopPick;
