import moment from "moment";

export function calculateAddDayForFirstOrder() {
  let addDay = 1;
  const now = new Date();
  if (now.getHours() > 12) {
    addDay = 2;
  } else if (now.getHours() === 12) {
    if (now.getMinutes() > 0) {
      addDay = 2;
    }
  }
  return addDay;
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function enumerateDaysBetweenDates(
  startDate: Date | string,
  endDate: Date | string
) {
  let now = moment(startDate).clone(),
    dates = [];

  while (now.isSameOrBefore(endDate)) {
    dates.push({
      dateFormat: now.format("dddd, MMM DD YYYY"),
      dateRaw: now.format("YYYY-MM-DD"),
    });
    now.add(1, "days");
  }

  return dates;
}

export const calculateDiscount = (totalPay: number, discount: string) => {
  let newTotalPay = totalPay;

  if (discount.includes("+")) {
    let rawDiscounts = discount.split("+");
    for (let i = 0; i < rawDiscounts.length; i++) {
      if (rawDiscounts[i].includes("%")) {
        const value = Number(rawDiscounts[i].slice(0, -1));
        const totalOne = (newTotalPay * value) / 100;
        newTotalPay = newTotalPay - totalOne;
      } else if (rawDiscounts[i]) {
        const value = Number(rawDiscounts[i]);
        newTotalPay = newTotalPay - value;
      }
    }
  } else if (discount.includes("%")) {
    const value = Number(discount.slice(0, -1));
    newTotalPay = newTotalPay - (newTotalPay * value) / 100;
  } else {
    const value = Number(discount);
    newTotalPay = newTotalPay - value;
  }

  return totalPay - newTotalPay;
};
