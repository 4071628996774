import { Checkbox, Form, FormInstance, Input, Row, message } from 'antd';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import useOrder from '../hooks/useOrder';
import useWindowSize from '../hooks/useWindowSize';
import AppButton from './AppButton';
import FormInputCustom from './FormInputCustom';
import axios from 'axios';
import { IHttpResponse } from '../helpers/pagination';
import { IAuthResponsePayload } from '../types/auth.type';
import { ICustomer } from '../types/customer.type';
import useAuth from '../hooks/useAuth';
import { getErrorMessage } from '../helpers/errorHandler';
import { generateFormRules } from '../helpers/formRules';
import { LockOutlined, PhoneOutlined } from '@ant-design/icons';
import useCart from '../hooks/useCart';

const CheckoutAuth: React.FC = (props) => {
  const { isMobile } = useWindowSize();
  const { setCheckoutAsGuest } = useOrder();
  const [isRegister, setIsRegister] = useState<boolean>(false);
  const [isLoadingAuth, setIsLoadingAuth] = useState(false);
  const { isLoggedIn, login, user, logout, getProfile, getMembershipDiscount } =
    useAuth();
  const [isAgreeSignUp, setIsAgreeSignUp] = React.useState(false);
  const { cartGroup } = useCart();

  function handleCheckoutAsGuest() {
    setCheckoutAsGuest(true);
  }

  const formRef =
    React.useRef<FormInstance<{ email: string; password: string }>>(null);
  const formRefRegister = React.useRef<
    FormInstance<{
      fullName: string;
      phoneNumber: string;
      email: string;
      password: string;
    }>
  >(null);

  const onSubmitLogin = async (values: { email: string; password: string }) => {
    try {
      setIsLoadingAuth(true);
      const res = await axios.post<IHttpResponse<IAuthResponsePayload>>(
        'auth/signin',
        { email: values.email, password: values.password }
      );

      const resProfile = await axios.get<IHttpResponse<ICustomer>>(
        'customers/me',
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + res.data.payload.token,
          },
        }
      );

      setIsLoadingAuth(false);

      login({
        token: res.data.payload.token,
        user: resProfile.data.payload,
      });
    } catch (err) {
      message.error('Login failed. ' + getErrorMessage(err));
      setIsLoadingAuth(false);
    } finally {
      // formRef.current?.resetFields();
    }
  };

  const onSubmitRegister = (values: {
    fullName: string;
    phoneNumber: string;
    email: string;
    password: string;
  }) => {
    setIsLoadingAuth(true);
    axios
      .post('customers/register', {
        fullName: values.fullName,
        phoneNumber: values.phoneNumber,
        email: values.email,
        password: values.password,
      })
      .then(async (res) => {
        console.info('res', res.data);
        setIsLoadingAuth(false);

        // message.success('Register successfully');

        await onSubmitLogin({
          email: values.email,
          password: values.password,
        });
      })
      .catch((err) => {
        message.error(getErrorMessage(err));
        setIsLoadingAuth(false);
      })
      .finally(() => {
        formRefRegister.current?.resetFields();
      });
  };

  const actionDisabled = useMemo(() => {
    if (!cartGroup.length) {
      return true;
    }

    return false;
  }, [cartGroup]);

  return (
    <div className='checkout-auth'>
      <div className='checkout-auth-title'>
        <h3>Member Authentication</h3>
        <p style={{ fontSize: 12 }}>
          You can choose whether continue as our member or as guest
        </p>
      </div>

      <div style={{ width: isMobile ? '100%' : '70%', margin: '0 auto' }}>
        {!isRegister ? (
          <Form
            style={{
              width: '100%',
            }}
            ref={formRef}
            layout='vertical'
            name='basic'
            initialValues={{
              email: '',
              password: '',
            }}
            onFinish={onSubmitLogin}
          >
            <Form.Item name='email' label='Email Address'>
              <Input size='large' placeholder='Enter your email address' />
            </Form.Item>
            <Form.Item name='password' label='Password'>
              <Input.Password size='large' placeholder='Enter your password' />
            </Form.Item>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
                marginBottom: 10,
              }}
            >
              <Link to='forgot-password' style={{ fontSize: 12 }}>
                Forgot Password
              </Link>
            </div>
            <AppButton
              block
              disabled={actionDisabled}
              type='primary'
              htmlType='submit'
              style={{ marginTop: 20, marginBottom: 10 }}
            >
              Sign In
            </AppButton>
            <Row align='middle' justify='center'>
              <span style={{ fontSize: 12 }}>Didn't have an account?</span>
              <span
                style={{ fontSize: 12 }}
                onClick={() => setIsRegister(true)}
                className='sign'
              >
                Sign Up Here
              </span>
            </Row>
          </Form>
        ) : (
          <Form
            style={{
              width: '100%',
            }}
            ref={formRefRegister}
            layout='vertical'
            name='basic'
            initialValues={{
              fullName: '',
              phoneNumber: '',
              email: '',
              password: '',
            }}
            onFinish={onSubmitRegister}
          >
            <Form.Item
              label='Full Name'
              name='fullName'
              rules={generateFormRules('Full Name', ['required'])}
            >
              <Input size='large' placeholder='Enter your email address' />
            </Form.Item>
            <Form.Item
              label='Phone Number'
              name='phoneNumber'
              rules={generateFormRules('Phone Number', [
                'required',
                'phoneNumber',
              ])}
            >
              <Input
                size='large'
                type='phoneNumber'
                prefix={<PhoneOutlined />}
                placeholder='Phone Number'
              />
            </Form.Item>
            <Form.Item
              label='Email Address'
              name='email'
              rules={generateFormRules('Email address', ['required', 'email'])}
            >
              <Input size='large' placeholder='Enter your email address' />
            </Form.Item>
            <Form.Item
              label='Password'
              name='password'
              rules={generateFormRules('Password', ['required', 'password'])}
            >
              <Input.Password
                size='large'
                prefix={<LockOutlined />}
                placeholder='Enter your password'
                type='password'
              />
            </Form.Item>

            <Form.Item>
              <Checkbox
                onChange={() => setIsAgreeSignUp(!isAgreeSignUp)}
                style={{ fontSize: 12 }}
              >
                I agree to Spoonful{' '}
                <Link
                  target='_blank'
                  style={{
                    color: '#FF4A50',
                    fontWeight: 'bold',
                    textDecoration: 'none',
                  }}
                  to='/terms-and-conditions'
                >
                  Terms and conditions
                </Link>{' '}
                &{' '}
                <Link
                  target='_blank'
                  style={{
                    color: '#FF4A50',
                    fontWeight: 'bold',
                    textDecoration: 'none',
                  }}
                  to='/privacy-policy'
                >
                  Privacy Policy
                </Link>
              </Checkbox>
            </Form.Item>

            <AppButton
              disabled={!isAgreeSignUp || actionDisabled}
              htmlType='submit'
              block
              type='primary'
              style={{ marginTop: 20, marginBottom: 10 }}
            >
              Sign Up
            </AppButton>
            <Row align='middle' justify='center'>
              <span>Already have an account?</span>
              <span onClick={() => setIsRegister(false)} className='sign'>
                Sign In Here
              </span>
            </Row>
          </Form>
        )}

        <div className='separator'>
          <span />
          <p>or</p>
          <span />
        </div>
        <AppButton
          disabled={actionDisabled}
          onClick={handleCheckoutAsGuest}
          block
          type='primary'
          style={{ marginTop: 20, background: '#14B5B0' }}
        >
          Continue as Guest
        </AppButton>
      </div>
    </div>
  );
};

export default CheckoutAuth;
