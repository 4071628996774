import React, { useEffect, useState } from 'react';
import {
  Col,
  Divider,
  Row,
  Typography,
  Form,
  Input,
  FormInstance,
  Card,
  message,
  Alert,
  Modal,
  Tag,
} from 'antd';
import { IChangePassword, ICustomer } from '../../types/customer.type';
import AppLayout from '../../layout/AppLayout';
import { generateFormRules } from '../../helpers/formRules';
import AppButton from '../../components/AppButton';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import useAuth from '../../hooks/useAuth';
import { ExclamationCircleOutlined, WarningOutlined } from '@ant-design/icons';
import FormInputCustom from '../../components/FormInputCustom';
import { httpRequest } from '../../helpers/api';
import { BaseResponsePaginationProps } from '../../types/config.type';
import { CustomerAddressProperties } from '../../types/address.type';

const { Title, Text } = Typography;

const { confirm } = Modal;

const MyAddress: React.FC = () => {
  const formRef = React.useRef<FormInstance<IChangePassword>>(null);
  const history = useHistory();
  const { user } = useAuth();
  const [addresses, setAddresses] = useState<CustomerAddressProperties[]>([]);

  const fetchMyAddress = async () => {
    const res = await httpRequest.get<
      BaseResponsePaginationProps<CustomerAddressProperties>
    >('customer-addresses');
    if (res.data && res.data.payload && res.data.payload.results) {
      setAddresses(res.data.payload.results);
    }
  };

  const removeAddress = async (id: string) => {
    try {
      await httpRequest.delete('customer-addresses/' + id);
      await fetchMyAddress();
    } catch (error) {}
  };

  const handleRemoveAddress = (id: string) => {
    confirm({
      title: 'Do you want to delete these address?',
      icon: <ExclamationCircleOutlined />,
      content:
        'When clicked the OK button, this dialog will be closed after 1 second',
      onOk: async () => {
        await removeAddress(id);
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    fetchMyAddress();
  }, []);

  return (
    <AppLayout hasSidebar isMyAccount>
      <Card>
        <Row justify={'space-between'} align={'middle'}>
          <Col>
            <Title style={{ marginBottom: 0 }} level={3}>
              My Address
            </Title>
          </Col>
          <Col>
            <AppButton
              onClick={() => history.push('/address/add')}
              block
              type='primary'
            >
              Add New Address
            </AppButton>
          </Col>
        </Row>
        <Divider />

        <div>
          {addresses && addresses.length > 0 ? (
            addresses.map((item, key) => (
              <div
                key={key}
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  padding: '15px 0',
                  borderBottom: '1px solid #ddd',
                  width: '100%',
                }}
              >
                <div style={{ flex: 1 }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h2 style={{ fontWeight: 600, margin: 0, marginRight: 10 }}>
                      {item.label}
                    </h2>
                    {item.isMainAddress ? <Tag>Main Address</Tag> : false}
                  </div>
                  <div>
                    {item.address} {item.state} {item.postCode} {item.suburb}
                  </div>
                  <div style={{ opacity: 0.6, marginTop: 15 }}>
                    Address Note
                  </div>
                  <div>{item.note}</div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <AppButton
                    style={{ marginRight: 15 }}
                    type='ghost'
                    size='small'
                    onClick={() =>
                      history.push('/address/edit/' + item.customerAddressId)
                    }
                  >
                    Edit
                  </AppButton>
                  <AppButton
                    size='small'
                    type='primary'
                    onClick={() => handleRemoveAddress(item.customerAddressId)}
                  >
                    Delete
                  </AppButton>
                </div>
              </div>
            ))
          ) : (
            <div
              style={{
                opacity: 0.5,
                textAlign: 'center',
              }}
            >
              Address Empty
            </div>
          )}
        </div>
      </Card>
    </AppLayout>
  );
};

export default MyAddress;
