import { Button, Form, Input, Radio } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { ArrowLeft } from 'react-feather';
import { useDebounce } from 'use-debounce/lib';
import useOrder from '../hooks/useOrder';
import { ILocation } from '../types/location.type';
import EmptyData from './EmptyData';

type Props = {
  isContent?: boolean;
  tmpSelectLocation?: ILocation;
  onClickBtnContent?: () => void;
  onSelectLocation: (loc: ILocation) => void;
};

interface IScheduleGroup {
  suburb: string;
  locations: IBaseGroupSchedule[];
}

interface IBaseGroupSchedule extends ILocation {
  scheduleAt: string;
  checked: boolean;
}

const ContentChoosePickupLocation: React.FC<Props> = (props) => {
  const { rawSchedules } = useOrder();
  const [search, setSearch] = useState<string>('');
  const [scheduleGroup, setScheduleGroup] = useState<IScheduleGroup[]>([]);

  useEffect(() => {
    if (rawSchedules && rawSchedules.length > 0) {
      const currScheduleGroup = rawSchedules
        .map((item) => ({
          scheduleAt: item.scheduleAt,
          ...item.location,
          checked:
            props.tmpSelectLocation &&
            props.tmpSelectLocation.locationId === item.locationId
              ? true
              : false,
        }))
        .reduce((acc: IScheduleGroup[], curr: IBaseGroupSchedule) => {
          const findIndex = acc.findIndex(
            (item) => item.suburb === curr.suburb
          );

          if (findIndex > -1) {
            const findLocationId = acc[findIndex].locations.findIndex(
              (item) => item.locationId === curr.locationId
            );
            if (findLocationId < 0) {
              acc[findIndex].locations.push(curr);
            }
          } else {
            acc.push({
              suburb: curr.suburb,
              locations: [{ ...curr }],
            });
          }

          return acc;
        }, [] as IScheduleGroup[]);

      setScheduleGroup(_.orderBy(currScheduleGroup, ['suburb', 'asc']));
    }
  }, [rawSchedules]);

  function handleChangeRadio(selectedLoc: IBaseGroupSchedule) {
    if (props.tmpSelectLocation?.locationId !== selectedLoc.locationId) {
      const mapScheduleGroup = scheduleGroup.map((item) => ({
        ...item,
        locations: item.locations.map((loc) =>
          loc.locationId === selectedLoc.locationId
            ? { ...loc, checked: !loc.checked }
            : { ...loc, checked: false }
        ),
      }));
      props.onSelectLocation(selectedLoc);
      setScheduleGroup(mapScheduleGroup);
    }
  }

  const _renderAddress = (loc: IBaseGroupSchedule) => {
    const address = [
      loc.locationAddress,
      loc.suburb || undefined,
      loc.city || undefined,
      loc.postalCode || undefined,
      loc.state || undefined,
    ];
    const res = address
      .filter((item) => item && item !== '' && item !== null)
      .join(', ');
    return res;
  };

  return (
    <>
      {props.isContent && (
        <Button onClick={props.onClickBtnContent} icon={<ArrowLeft />} />
      )}

      <div style={{ marginBottom: 40 }}>
        <div className='modal-title'>Pickup location</div>
        <div className='modal-subtitle'>
          Choose pickup location for your meals
        </div>
      </div>

      {scheduleGroup && scheduleGroup.length > 0 ? (
        scheduleGroup.map((item, idx) => (
          <div key={idx}>
            <div
              style={{
                fontWeight: 600,
                color: 'red',
                margin: '15px 0',
                marginLeft: 0,
              }}
            >
              {item.suburb}
            </div>
            {item.locations.map((loc) => (
              <div
                onClick={() => handleChangeRadio(loc)}
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  paddingBottom: 15,
                  marginBottom: 15,
                  borderBottom: '1px solid rgba(0,0,0,0.05)',
                  gap: 15,
                }}
                key={loc.locationId}
              >
                <img
                  src={
                    loc.checked
                      ? '/images/radio-check.svg'
                      : '/images/radio-default.svg'
                  }
                />
                <div style={{ flex: 1 }}>
                  <div style={{ fontWeight: 600, marginBottom: 5 }}>
                    {loc.locationName}
                  </div>
                  <div style={{ fontSize: 12, marginBottom: 5 }}>
                    {_renderAddress(loc)}
                  </div>
                  <div style={{ fontSize: 12, opacity: 0.8 }}>
                    <span>Pickup Timeframe</span>
                    <span style={{ margin: '0 15px', opacity: 0.4 }}>|</span>
                    <span>{loc.timeframe}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))
      ) : (
        <div
          style={{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div style={{ opacity: 0.5, textAlign: 'center' }}>Data Empty</div>
        </div>
      )}
    </>
  );
};

export default ContentChoosePickupLocation;
