import React from "react";
import { Modal, Row, Button, Typography, Alert } from "antd";
import AppMap from "./AppMap";
import useGlobalConfig from "../hooks/useGlobalConfig";
import { BottomSheet, BottomSheetRef } from "react-spring-bottom-sheet";
import useWindowSize from "../hooks/useWindowSize";
import useGlobalFilter from "../hooks/useGlobalFilter";
import useCart from "../hooks/useCart";
import { ILocation, initialLocation } from "../types/location.type";
import Title from "antd/lib/typography/Title";
import Select, { components, OptionProps } from "react-select";

export interface LocationOptionProps {
  readonly value: string;
  readonly label: string;
  readonly subtitle: string;
}

const { Text } = Typography;
// const { Option } = Select;

type Props = {
  onChange?: (newItem: ILocation) => void;
};
export default function ModalSelectLocation(props: Props) {
  const { locationIdInCart, clearCart } = useCart();
  const sheetRef: any = React.useRef<BottomSheetRef>();
  const { isMobile } = useWindowSize();
  const { locations } = useGlobalConfig();
  const {
    isShowModalSelectLocation,
    setIsShowModalSelectLocation,
    setSelectedLocation,
  } = useGlobalFilter();

  const [tmpSelectedLocation, setTmpSelectedLocation] = React.useState({
    ...initialLocation,
  });
  const [isBlocking, setIsBlocking] = React.useState(true);

  React.useEffect(() => {
    if (
      locations.length > 0 &&
      (!tmpSelectedLocation || !tmpSelectedLocation?.locationGeoloc?.lat)
    ) {
      setTmpSelectedLocation(locations[0]);
    }
  }, [locations]);

  React.useEffect(() => {
    if (locationIdInCart) {
      const findOne = locations.find(
        (loc) => loc.locationId === locationIdInCart
      );
      if (findOne) {
        setTmpSelectedLocation(findOne);
      }
    }
  }, [locationIdInCart]);

  const locationList: readonly LocationOptionProps[] = locations.map(
    (location) => {
      return {
        value: location.locationId,
        label: location.locationName,
        subtitle: location.locationAddress,
      };
    }
  );

  const Option = (props: OptionProps<LocationOptionProps>) => {
    return (
      <components.Option {...props}>
        <div>{props.label}</div>
        <div>{props.data.subtitle}</div>
      </components.Option>
    );
  };

  const handleConfirmLocations = (newLoc: ILocation) => {
    if (locationIdInCart && locationIdInCart !== newLoc.locationId) {
      // const locationInCart = locations.find(item => item.locationId === locationIdInCart);
      Modal.confirm({
        title: "Confirmation",
        content: (
          <div>
            {/* <Alert
              type="warning"
              message={
                <div>
                  <Text>Location in Your Cart: </Text>
                  <Text style={{ fontWeight: 'bold' }}>
                    {locationInCart?.locationName}
                  </Text>
                </div>
              }
            />

            <Alert
              type="warning"
              message={
                <div>
                  <Text>Will change to: </Text>
                  <Text style={{ fontWeight: 'bold' }}>
                    {newLoc?.locationName}
                  </Text>
                </div>
              }
            /> */}

            <div style={{ marginTop: 10 }}>
              <div>
                <Text>Your existing location in cart is different.</Text>
              </div>
              <Text>
                Are you sure want to change pickup location? Your existing cart
                will be removed.
              </Text>
            </div>
          </div>
        ),
        onOk: () => {
          clearCart();
          setSelectedLocation(newLoc);
          setIsShowModalSelectLocation(false);
          if (props.onChange) {
            props.onChange(newLoc);
          }
        },
        okText: `Yes, I'm sure`,
        okType: "primary",
      });
    } else {
      setSelectedLocation(newLoc);
      setIsShowModalSelectLocation(false);

      if (props.onChange) {
        props.onChange(newLoc);
      }
    }
  };

  const _renderContent = () => (
    <>
      {isMobile && <Title level={3}>Pickup Location</Title>}
      <Row>
        <AppMap
          height={250}
          coords={locations.map((item, idx) => ({
            onClick: () => {
              setTmpSelectedLocation(item);
            },
            isSelected: tmpSelectedLocation?.locationId === item.locationId,
            isMain: item.locationType === "main_kitchen",
            lat: Number(item?.locationGeoloc?.lat) || 5,
            lng: Number(item?.locationGeoloc?.lng) || 5,
          }))}
          center={
            tmpSelectedLocation?.locationGeoloc &&
            tmpSelectedLocation?.locationGeoloc.lat
              ? {
                  lat: Number(tmpSelectedLocation?.locationGeoloc.lat),
                  lng: Number(tmpSelectedLocation?.locationGeoloc.lng),
                }
              : undefined
          }
        />
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Text
          style={{
            fontSize: "12px",
          }}
        >
          Pickup Point
        </Text>
      </Row>

      <Row>
        <Select
          closeMenuOnSelect={false}
          components={{ Option }}
          styles={{
            option: (base) => ({
              ...base,
              height: "100%",
              width: "100%",
            }),
            container: (base) => ({
              ...base,
              height: "100%",
              width: "100%",
            }),
          }}
          defaultValue={locationList.find(
            (opt) => tmpSelectedLocation.locationId === opt.value
          )}
          options={locationList}
          onChange={(value) => {
            const findOne = locations.find(
              (item) => item.locationId === (value as any).value
            );
            if (findOne) {
              setTmpSelectedLocation(findOne);
            }
          }}
          menuPlacement={"auto"}
        />
        {/* <Select
          size="large"
          allowClear
          style={{ width: '100%', borderRadius: '100px' }}
          placeholder="Pickup Location"
          onFocus={() => {
            setIsBlocking(false);
          }}
          onBlur={() => {
            setIsBlocking(true);
          }}
          onChange={(value) => {
            const findOne = locations.find((item) => item.locationId === value);
            if (findOne) {
              setTmpSelectedLocation(findOne);
            }
            setIsBlocking(true)
          }}
          defaultValue={tmpSelectedLocation.locationId}
          value={tmpSelectedLocation?.locationId}
        >
          {locations.map((item) => {
            return (
              <Option value={item.locationId} key={item.locationId}>
                <Row>
                  <Text strong style={{ fontWeight: 600, fontSize: '12px' }}>
                    {item.locationName}
                  </Text>
                </Row>
                <Row>
                  <Text style={{ fontSize: '12px' }}>
                    {item.locationAddress}
                  </Text>
                </Row>
              </Option>
            );
          })}
        </Select> */}

        <Row>
          <Text
            style={{
              color: "#93A1B0",
              fontSize: "12px",
              marginTop: "2%",
            }}
          >
            Select pin from map or select from pickup point list
          </Text>
        </Row>
      </Row>

      <Button
        disabled={!tmpSelectedLocation}
        style={{
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: "16px",
          height: "44px",
          backgroundColor: "#FF4A50",
          borderRadius: "100px",
          width: "100%",
          color: "white",
          marginTop: "5%",
        }}
        onClick={() => {
          if (tmpSelectedLocation) {
            handleConfirmLocations(tmpSelectedLocation);
          }
        }}
      >
        Confirm Pickup Location
      </Button>
    </>
  );

  if (!isShowModalSelectLocation) {
    return <></>;
  }

  if (isMobile) {
    return (
      <BottomSheet
        expandOnContentDrag
        blocking={false}
        open={isShowModalSelectLocation}
        ref={sheetRef}
        onDismiss={() => {
          setIsShowModalSelectLocation(false);
        }}
      >
        <div style={{ padding: "10px 20px" }}>{_renderContent()}</div>
      </BottomSheet>
    );
  } else {
    return (
      <Modal
        title={`Pickup Location`}
        visible={isShowModalSelectLocation}
        footer={null}
        onCancel={() => {
          setIsShowModalSelectLocation(false);
        }}
        width={624}
      >
        {_renderContent()}
      </Modal>
    );
  }
}
